var cityArrr = [
    {
        "value": "北京",
        "id": "110000",
        "childs": [
            {
                "value": "北京市",
                "id": "110000",
                "childs": [
                    {
                        "value": "东城区",
                        "id": "110101"
                    },
                    {
                        "value": "西城区",
                        "id": "110102"
                    },
                    {
                        "value": "朝阳区",
                        "id": "110105"
                    },
                    {
                        "value": "丰台区",
                        "id": "110106"
                    },
                    {
                        "value": "石景山区",
                        "id": "110107"
                    },
                    {
                        "value": "海淀区",
                        "id": "110108"
                    },
                    {
                        "value": "门头沟区",
                        "id": "110109"
                    },
                    {
                        "value": "房山区",
                        "id": "110111"
                    },
                    {
                        "value": "通州区",
                        "id": "110112"
                    },
                    {
                        "value": "顺义区",
                        "id": "110113"
                    },
                    {
                        "value": "昌平区",
                        "id": "110114"
                    },
                    {
                        "value": "大兴区",
                        "id": "110115"
                    },
                    {
                        "value": "怀柔区",
                        "id": "110116"
                    },
                    {
                        "value": "平谷区",
                        "id": "110117"
                    },
                    {
                        "value": "密云县",
                        "id": "110228"
                    },
                    {
                        "value": "延庆县",
                        "id": "110229"
                    }
                ]
            }
        ]
    },
    {
        "value": "天津",
        "id": "120000",
        "childs": [
            {
                "value": "天津市",
                "id": "120000",
                "childs": [
                    {
                        "value": "和平区",
                        "id": "120101"
                    },
                    {
                        "value": "河东区",
                        "id": "120102"
                    },
                    {
                        "value": "河西区",
                        "id": "120103"
                    },
                    {
                        "value": "南开区",
                        "id": "120104"
                    },
                    {
                        "value": "河北区",
                        "id": "120105"
                    },
                    {
                        "value": "红桥区",
                        "id": "120106"
                    },
                    {
                        "value": "东丽区",
                        "id": "120110"
                    },
                    {
                        "value": "西青区",
                        "id": "120111"
                    },
                    {
                        "value": "津南区",
                        "id": "120112"
                    },
                    {
                        "value": "北辰区",
                        "id": "120113"
                    },
                    {
                        "value": "武清区",
                        "id": "120114"
                    },
                    {
                        "value": "宝坻区",
                        "id": "120115"
                    },
                    {
                        "value": "滨海新区",
                        "id": "120116"
                    },
                    {
                        "value": "宁河县",
                        "id": "120221"
                    },
                    {
                        "value": "静海县",
                        "id": "120223"
                    },
                    {
                        "value": "蓟县",
                        "id": "120225"
                    }
                ]
            }
        ]
    },
    {
        "value": "河北省",
        "id": "130000",
        "childs": [
            {
                "value": "石家庄市",
                "id": "130100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "130101"
                    },
                    {
                        "value": "长安区",
                        "id": "130102"
                    },
                    {
                        "value": "桥西区",
                        "id": "130104"
                    },
                    {
                        "value": "新华区",
                        "id": "130105"
                    },
                    {
                        "value": "井陉矿区",
                        "id": "130107"
                    },
                    {
                        "value": "裕华区",
                        "id": "130108"
                    },
                    {
                        "value": "藁城区",
                        "id": "130109"
                    },
                    {
                        "value": "鹿泉区",
                        "id": "130110"
                    },
                    {
                        "value": "栾城区",
                        "id": "130111"
                    },
                    {
                        "value": "井陉县",
                        "id": "130121"
                    },
                    {
                        "value": "正定县",
                        "id": "130123"
                    },
                    {
                        "value": "行唐县",
                        "id": "130125"
                    },
                    {
                        "value": "灵寿县",
                        "id": "130126"
                    },
                    {
                        "value": "高邑县",
                        "id": "130127"
                    },
                    {
                        "value": "深泽县",
                        "id": "130128"
                    },
                    {
                        "value": "赞皇县",
                        "id": "130129"
                    },
                    {
                        "value": "无极县",
                        "id": "130130"
                    },
                    {
                        "value": "平山县",
                        "id": "130131"
                    },
                    {
                        "value": "元氏县",
                        "id": "130132"
                    },
                    {
                        "value": "赵县",
                        "id": "130133"
                    },
                    {
                        "value": "辛集市",
                        "id": "130181"
                    },
                    {
                        "value": "晋州市",
                        "id": "130183"
                    },
                    {
                        "value": "新乐市",
                        "id": "130184"
                    }
                ]
            },
            {
                "value": "唐山市",
                "id": "130200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "130201"
                    },
                    {
                        "value": "路南区",
                        "id": "130202"
                    },
                    {
                        "value": "路北区",
                        "id": "130203"
                    },
                    {
                        "value": "古冶区",
                        "id": "130204"
                    },
                    {
                        "value": "开平区",
                        "id": "130205"
                    },
                    {
                        "value": "丰南区",
                        "id": "130207"
                    },
                    {
                        "value": "丰润区",
                        "id": "130208"
                    },
                    {
                        "value": "曹妃甸区",
                        "id": "130209"
                    },
                    {
                        "value": "滦县",
                        "id": "130223"
                    },
                    {
                        "value": "滦南县",
                        "id": "130224"
                    },
                    {
                        "value": "乐亭县",
                        "id": "130225"
                    },
                    {
                        "value": "迁西县",
                        "id": "130227"
                    },
                    {
                        "value": "玉田县",
                        "id": "130229"
                    },
                    {
                        "value": "遵化市",
                        "id": "130281"
                    },
                    {
                        "value": "迁安市",
                        "id": "130283"
                    }
                ]
            },
            {
                "value": "秦皇岛市",
                "id": "130300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "130301"
                    },
                    {
                        "value": "海港区",
                        "id": "130302"
                    },
                    {
                        "value": "山海关区",
                        "id": "130303"
                    },
                    {
                        "value": "北戴河区",
                        "id": "130304"
                    },
                    {
                        "value": "青龙满族自治县",
                        "id": "130321"
                    },
                    {
                        "value": "昌黎县",
                        "id": "130322"
                    },
                    {
                        "value": "抚宁县",
                        "id": "130323"
                    },
                    {
                        "value": "卢龙县",
                        "id": "130324"
                    }
                ]
            },
            {
                "value": "邯郸市",
                "id": "130400",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "130401"
                    },
                    {
                        "value": "邯山区",
                        "id": "130402"
                    },
                    {
                        "value": "丛台区",
                        "id": "130403"
                    },
                    {
                        "value": "复兴区",
                        "id": "130404"
                    },
                    {
                        "value": "峰峰矿区",
                        "id": "130406"
                    },
                    {
                        "value": "邯郸县",
                        "id": "130421"
                    },
                    {
                        "value": "临漳县",
                        "id": "130423"
                    },
                    {
                        "value": "成安县",
                        "id": "130424"
                    },
                    {
                        "value": "大名县",
                        "id": "130425"
                    },
                    {
                        "value": "涉县",
                        "id": "130426"
                    },
                    {
                        "value": "磁县",
                        "id": "130427"
                    },
                    {
                        "value": "肥乡县",
                        "id": "130428"
                    },
                    {
                        "value": "永年县",
                        "id": "130429"
                    },
                    {
                        "value": "邱县",
                        "id": "130430"
                    },
                    {
                        "value": "鸡泽县",
                        "id": "130431"
                    },
                    {
                        "value": "广平县",
                        "id": "130432"
                    },
                    {
                        "value": "馆陶县",
                        "id": "130433"
                    },
                    {
                        "value": "魏县",
                        "id": "130434"
                    },
                    {
                        "value": "曲周县",
                        "id": "130435"
                    },
                    {
                        "value": "武安市",
                        "id": "130481"
                    }
                ]
            },
            {
                "value": "邢台市",
                "id": "130500",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "130501"
                    },
                    {
                        "value": "桥东区",
                        "id": "130502"
                    },
                    {
                        "value": "桥西区",
                        "id": "130503"
                    },
                    {
                        "value": "邢台县",
                        "id": "130521"
                    },
                    {
                        "value": "临城县",
                        "id": "130522"
                    },
                    {
                        "value": "内丘县",
                        "id": "130523"
                    },
                    {
                        "value": "柏乡县",
                        "id": "130524"
                    },
                    {
                        "value": "隆尧县",
                        "id": "130525"
                    },
                    {
                        "value": "任县",
                        "id": "130526"
                    },
                    {
                        "value": "南和县",
                        "id": "130527"
                    },
                    {
                        "value": "宁晋县",
                        "id": "130528"
                    },
                    {
                        "value": "巨鹿县",
                        "id": "130529"
                    },
                    {
                        "value": "新河县",
                        "id": "130530"
                    },
                    {
                        "value": "广宗县",
                        "id": "130531"
                    },
                    {
                        "value": "平乡县",
                        "id": "130532"
                    },
                    {
                        "value": "威县",
                        "id": "130533"
                    },
                    {
                        "value": "清河县",
                        "id": "130534"
                    },
                    {
                        "value": "临西县",
                        "id": "130535"
                    },
                    {
                        "value": "南宫市",
                        "id": "130581"
                    },
                    {
                        "value": "沙河市",
                        "id": "130582"
                    }
                ]
            },
            {
                "value": "保定市",
                "id": "130600",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "130601"
                    },
                    {
                        "value": "新市区",
                        "id": "130602"
                    },
                    {
                        "value": "北市区",
                        "id": "130603"
                    },
                    {
                        "value": "南市区",
                        "id": "130604"
                    },
                    {
                        "value": "满城县",
                        "id": "130621"
                    },
                    {
                        "value": "清苑县",
                        "id": "130622"
                    },
                    {
                        "value": "涞水县",
                        "id": "130623"
                    },
                    {
                        "value": "阜平县",
                        "id": "130624"
                    },
                    {
                        "value": "徐水县",
                        "id": "130625"
                    },
                    {
                        "value": "定兴县",
                        "id": "130626"
                    },
                    {
                        "value": "唐县",
                        "id": "130627"
                    },
                    {
                        "value": "高阳县",
                        "id": "130628"
                    },
                    {
                        "value": "容城县",
                        "id": "130629"
                    },
                    {
                        "value": "涞源县",
                        "id": "130630"
                    },
                    {
                        "value": "望都县",
                        "id": "130631"
                    },
                    {
                        "value": "安新县",
                        "id": "130632"
                    },
                    {
                        "value": "易县",
                        "id": "130633"
                    },
                    {
                        "value": "曲阳县",
                        "id": "130634"
                    },
                    {
                        "value": "蠡县",
                        "id": "130635"
                    },
                    {
                        "value": "顺平县",
                        "id": "130636"
                    },
                    {
                        "value": "博野县",
                        "id": "130637"
                    },
                    {
                        "value": "雄县",
                        "id": "130638"
                    },
                    {
                        "value": "涿州市",
                        "id": "130681"
                    },
                    {
                        "value": "定州市",
                        "id": "130682"
                    },
                    {
                        "value": "安国市",
                        "id": "130683"
                    },
                    {
                        "value": "高碑店市",
                        "id": "130684"
                    }
                ]
            },
            {
                "value": "张家口市",
                "id": "130700",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "130701"
                    },
                    {
                        "value": "桥东区",
                        "id": "130702"
                    },
                    {
                        "value": "桥西区",
                        "id": "130703"
                    },
                    {
                        "value": "宣化区",
                        "id": "130705"
                    },
                    {
                        "value": "下花园区",
                        "id": "130706"
                    },
                    {
                        "value": "宣化县",
                        "id": "130721"
                    },
                    {
                        "value": "张北县",
                        "id": "130722"
                    },
                    {
                        "value": "康保县",
                        "id": "130723"
                    },
                    {
                        "value": "沽源县",
                        "id": "130724"
                    },
                    {
                        "value": "尚义县",
                        "id": "130725"
                    },
                    {
                        "value": "蔚县",
                        "id": "130726"
                    },
                    {
                        "value": "阳原县",
                        "id": "130727"
                    },
                    {
                        "value": "怀安县",
                        "id": "130728"
                    },
                    {
                        "value": "万全县",
                        "id": "130729"
                    },
                    {
                        "value": "怀来县",
                        "id": "130730"
                    },
                    {
                        "value": "涿鹿县",
                        "id": "130731"
                    },
                    {
                        "value": "赤城县",
                        "id": "130732"
                    },
                    {
                        "value": "崇礼县",
                        "id": "130733"
                    }
                ]
            },
            {
                "value": "承德市",
                "id": "130800",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "130801"
                    },
                    {
                        "value": "双桥区",
                        "id": "130802"
                    },
                    {
                        "value": "双滦区",
                        "id": "130803"
                    },
                    {
                        "value": "鹰手营子矿区",
                        "id": "130804"
                    },
                    {
                        "value": "承德县",
                        "id": "130821"
                    },
                    {
                        "value": "兴隆县",
                        "id": "130822"
                    },
                    {
                        "value": "平泉县",
                        "id": "130823"
                    },
                    {
                        "value": "滦平县",
                        "id": "130824"
                    },
                    {
                        "value": "隆化县",
                        "id": "130825"
                    },
                    {
                        "value": "丰宁满族自治县",
                        "id": "130826"
                    },
                    {
                        "value": "宽城满族自治县",
                        "id": "130827"
                    },
                    {
                        "value": "围场满族蒙古族自治县",
                        "id": "130828"
                    }
                ]
            },
            {
                "value": "沧州市",
                "id": "130900",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "130901"
                    },
                    {
                        "value": "新华区",
                        "id": "130902"
                    },
                    {
                        "value": "运河区",
                        "id": "130903"
                    },
                    {
                        "value": "沧县",
                        "id": "130921"
                    },
                    {
                        "value": "青县",
                        "id": "130922"
                    },
                    {
                        "value": "东光县",
                        "id": "130923"
                    },
                    {
                        "value": "海兴县",
                        "id": "130924"
                    },
                    {
                        "value": "盐山县",
                        "id": "130925"
                    },
                    {
                        "value": "肃宁县",
                        "id": "130926"
                    },
                    {
                        "value": "南皮县",
                        "id": "130927"
                    },
                    {
                        "value": "吴桥县",
                        "id": "130928"
                    },
                    {
                        "value": "献县",
                        "id": "130929"
                    },
                    {
                        "value": "孟村回族自治县",
                        "id": "130930"
                    },
                    {
                        "value": "泊头市",
                        "id": "130981"
                    },
                    {
                        "value": "任丘市",
                        "id": "130982"
                    },
                    {
                        "value": "黄骅市",
                        "id": "130983"
                    },
                    {
                        "value": "河间市",
                        "id": "130984"
                    }
                ]
            },
            {
                "value": "廊坊市",
                "id": "131000",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "131001"
                    },
                    {
                        "value": "安次区",
                        "id": "131002"
                    },
                    {
                        "value": "广阳区",
                        "id": "131003"
                    },
                    {
                        "value": "固安县",
                        "id": "131022"
                    },
                    {
                        "value": "永清县",
                        "id": "131023"
                    },
                    {
                        "value": "香河县",
                        "id": "131024"
                    },
                    {
                        "value": "大城县",
                        "id": "131025"
                    },
                    {
                        "value": "文安县",
                        "id": "131026"
                    },
                    {
                        "value": "大厂回族自治县",
                        "id": "131028"
                    },
                    {
                        "value": "霸州市",
                        "id": "131081"
                    },
                    {
                        "value": "三河市",
                        "id": "131082"
                    }
                ]
            },
            {
                "value": "衡水市",
                "id": "131100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "131101"
                    },
                    {
                        "value": "桃城区",
                        "id": "131102"
                    },
                    {
                        "value": "枣强县",
                        "id": "131121"
                    },
                    {
                        "value": "武邑县",
                        "id": "131122"
                    },
                    {
                        "value": "武强县",
                        "id": "131123"
                    },
                    {
                        "value": "饶阳县",
                        "id": "131124"
                    },
                    {
                        "value": "安平县",
                        "id": "131125"
                    },
                    {
                        "value": "故城县",
                        "id": "131126"
                    },
                    {
                        "value": "景县",
                        "id": "131127"
                    },
                    {
                        "value": "阜城县",
                        "id": "131128"
                    },
                    {
                        "value": "冀州市",
                        "id": "131181"
                    },
                    {
                        "value": "深州市",
                        "id": "131182"
                    }
                ]
            }
        ]
    },
    {
        "value": "山西省",
        "id": "140000",
        "childs": [
            {
                "value": "太原市",
                "id": "140100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "140101"
                    },
                    {
                        "value": "小店区",
                        "id": "140105"
                    },
                    {
                        "value": "迎泽区",
                        "id": "140106"
                    },
                    {
                        "value": "杏花岭区",
                        "id": "140107"
                    },
                    {
                        "value": "尖草坪区",
                        "id": "140108"
                    },
                    {
                        "value": "万柏林区",
                        "id": "140109"
                    },
                    {
                        "value": "晋源区",
                        "id": "140110"
                    },
                    {
                        "value": "清徐县",
                        "id": "140121"
                    },
                    {
                        "value": "阳曲县",
                        "id": "140122"
                    },
                    {
                        "value": "娄烦县",
                        "id": "140123"
                    },
                    {
                        "value": "古交市",
                        "id": "140181"
                    }
                ]
            },
            {
                "value": "大同市",
                "id": "140200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "140201"
                    },
                    {
                        "value": "城区",
                        "id": "140202"
                    },
                    {
                        "value": "矿区",
                        "id": "140203"
                    },
                    {
                        "value": "南郊区",
                        "id": "140211"
                    },
                    {
                        "value": "新荣区",
                        "id": "140212"
                    },
                    {
                        "value": "阳高县",
                        "id": "140221"
                    },
                    {
                        "value": "天镇县",
                        "id": "140222"
                    },
                    {
                        "value": "广灵县",
                        "id": "140223"
                    },
                    {
                        "value": "灵丘县",
                        "id": "140224"
                    },
                    {
                        "value": "浑源县",
                        "id": "140225"
                    },
                    {
                        "value": "左云县",
                        "id": "140226"
                    },
                    {
                        "value": "大同县",
                        "id": "140227"
                    }
                ]
            },
            {
                "value": "阳泉市",
                "id": "140300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "140301"
                    },
                    {
                        "value": "城区",
                        "id": "140302"
                    },
                    {
                        "value": "矿区",
                        "id": "140303"
                    },
                    {
                        "value": "郊区",
                        "id": "140311"
                    },
                    {
                        "value": "平定县",
                        "id": "140321"
                    },
                    {
                        "value": "盂县",
                        "id": "140322"
                    }
                ]
            },
            {
                "value": "长治市",
                "id": "140400",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "140401"
                    },
                    {
                        "value": "城区",
                        "id": "140402"
                    },
                    {
                        "value": "郊区",
                        "id": "140411"
                    },
                    {
                        "value": "长治县",
                        "id": "140421"
                    },
                    {
                        "value": "襄垣县",
                        "id": "140423"
                    },
                    {
                        "value": "屯留县",
                        "id": "140424"
                    },
                    {
                        "value": "平顺县",
                        "id": "140425"
                    },
                    {
                        "value": "黎城县",
                        "id": "140426"
                    },
                    {
                        "value": "壶关县",
                        "id": "140427"
                    },
                    {
                        "value": "长子县",
                        "id": "140428"
                    },
                    {
                        "value": "武乡县",
                        "id": "140429"
                    },
                    {
                        "value": "沁县",
                        "id": "140430"
                    },
                    {
                        "value": "沁源县",
                        "id": "140431"
                    },
                    {
                        "value": "潞城市",
                        "id": "140481"
                    }
                ]
            },
            {
                "value": "晋城市",
                "id": "140500",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "140501"
                    },
                    {
                        "value": "城区",
                        "id": "140502"
                    },
                    {
                        "value": "沁水县",
                        "id": "140521"
                    },
                    {
                        "value": "阳城县",
                        "id": "140522"
                    },
                    {
                        "value": "陵川县",
                        "id": "140524"
                    },
                    {
                        "value": "泽州县",
                        "id": "140525"
                    },
                    {
                        "value": "高平市",
                        "id": "140581"
                    }
                ]
            },
            {
                "value": "朔州市",
                "id": "140600",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "140601"
                    },
                    {
                        "value": "朔城区",
                        "id": "140602"
                    },
                    {
                        "value": "平鲁区",
                        "id": "140603"
                    },
                    {
                        "value": "山阴县",
                        "id": "140621"
                    },
                    {
                        "value": "应县",
                        "id": "140622"
                    },
                    {
                        "value": "右玉县",
                        "id": "140623"
                    },
                    {
                        "value": "怀仁县",
                        "id": "140624"
                    }
                ]
            },
            {
                "value": "晋中市",
                "id": "140700",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "140701"
                    },
                    {
                        "value": "榆次区",
                        "id": "140702"
                    },
                    {
                        "value": "榆社县",
                        "id": "140721"
                    },
                    {
                        "value": "左权县",
                        "id": "140722"
                    },
                    {
                        "value": "和顺县",
                        "id": "140723"
                    },
                    {
                        "value": "昔阳县",
                        "id": "140724"
                    },
                    {
                        "value": "寿阳县",
                        "id": "140725"
                    },
                    {
                        "value": "太谷县",
                        "id": "140726"
                    },
                    {
                        "value": "祁县",
                        "id": "140727"
                    },
                    {
                        "value": "平遥县",
                        "id": "140728"
                    },
                    {
                        "value": "灵石县",
                        "id": "140729"
                    },
                    {
                        "value": "介休市",
                        "id": "140781"
                    }
                ]
            },
            {
                "value": "运城市",
                "id": "140800",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "140801"
                    },
                    {
                        "value": "盐湖区",
                        "id": "140802"
                    },
                    {
                        "value": "临猗县",
                        "id": "140821"
                    },
                    {
                        "value": "万荣县",
                        "id": "140822"
                    },
                    {
                        "value": "闻喜县",
                        "id": "140823"
                    },
                    {
                        "value": "稷山县",
                        "id": "140824"
                    },
                    {
                        "value": "新绛县",
                        "id": "140825"
                    },
                    {
                        "value": "绛县",
                        "id": "140826"
                    },
                    {
                        "value": "垣曲县",
                        "id": "140827"
                    },
                    {
                        "value": "夏县",
                        "id": "140828"
                    },
                    {
                        "value": "平陆县",
                        "id": "140829"
                    },
                    {
                        "value": "芮城县",
                        "id": "140830"
                    },
                    {
                        "value": "永济市",
                        "id": "140881"
                    },
                    {
                        "value": "河津市",
                        "id": "140882"
                    }
                ]
            },
            {
                "value": "忻州市",
                "id": "140900",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "140901"
                    },
                    {
                        "value": "忻府区",
                        "id": "140902"
                    },
                    {
                        "value": "定襄县",
                        "id": "140921"
                    },
                    {
                        "value": "五台县",
                        "id": "140922"
                    },
                    {
                        "value": "代县",
                        "id": "140923"
                    },
                    {
                        "value": "繁峙县",
                        "id": "140924"
                    },
                    {
                        "value": "宁武县",
                        "id": "140925"
                    },
                    {
                        "value": "静乐县",
                        "id": "140926"
                    },
                    {
                        "value": "神池县",
                        "id": "140927"
                    },
                    {
                        "value": "五寨县",
                        "id": "140928"
                    },
                    {
                        "value": "岢岚县",
                        "id": "140929"
                    },
                    {
                        "value": "河曲县",
                        "id": "140930"
                    },
                    {
                        "value": "保德县",
                        "id": "140931"
                    },
                    {
                        "value": "偏关县",
                        "id": "140932"
                    },
                    {
                        "value": "原平市",
                        "id": "140981"
                    }
                ]
            },
            {
                "value": "临汾市",
                "id": "141000",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "141001"
                    },
                    {
                        "value": "尧都区",
                        "id": "141002"
                    },
                    {
                        "value": "曲沃县",
                        "id": "141021"
                    },
                    {
                        "value": "翼城县",
                        "id": "141022"
                    },
                    {
                        "value": "襄汾县",
                        "id": "141023"
                    },
                    {
                        "value": "洪洞县",
                        "id": "141024"
                    },
                    {
                        "value": "古县",
                        "id": "141025"
                    },
                    {
                        "value": "安泽县",
                        "id": "141026"
                    },
                    {
                        "value": "浮山县",
                        "id": "141027"
                    },
                    {
                        "value": "吉县",
                        "id": "141028"
                    },
                    {
                        "value": "乡宁县",
                        "id": "141029"
                    },
                    {
                        "value": "大宁县",
                        "id": "141030"
                    },
                    {
                        "value": "隰县",
                        "id": "141031"
                    },
                    {
                        "value": "永和县",
                        "id": "141032"
                    },
                    {
                        "value": "蒲县",
                        "id": "141033"
                    },
                    {
                        "value": "汾西县",
                        "id": "141034"
                    },
                    {
                        "value": "侯马市",
                        "id": "141081"
                    },
                    {
                        "value": "霍州市",
                        "id": "141082"
                    }
                ]
            },
            {
                "value": "吕梁市",
                "id": "141100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "141101"
                    },
                    {
                        "value": "离石区",
                        "id": "141102"
                    },
                    {
                        "value": "文水县",
                        "id": "141121"
                    },
                    {
                        "value": "交城县",
                        "id": "141122"
                    },
                    {
                        "value": "兴县",
                        "id": "141123"
                    },
                    {
                        "value": "临县",
                        "id": "141124"
                    },
                    {
                        "value": "柳林县",
                        "id": "141125"
                    },
                    {
                        "value": "石楼县",
                        "id": "141126"
                    },
                    {
                        "value": "岚县",
                        "id": "141127"
                    },
                    {
                        "value": "方山县",
                        "id": "141128"
                    },
                    {
                        "value": "中阳县",
                        "id": "141129"
                    },
                    {
                        "value": "交口县",
                        "id": "141130"
                    },
                    {
                        "value": "孝义市",
                        "id": "141181"
                    },
                    {
                        "value": "汾阳市",
                        "id": "141182"
                    }
                ]
            }
        ]
    },
    {
        "value": "内蒙古自治区",
        "id": "150000",
        "childs": [
            {
                "value": "呼和浩特市",
                "id": "150100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "150101"
                    },
                    {
                        "value": "新城区",
                        "id": "150102"
                    },
                    {
                        "value": "回民区",
                        "id": "150103"
                    },
                    {
                        "value": "玉泉区",
                        "id": "150104"
                    },
                    {
                        "value": "赛罕区",
                        "id": "150105"
                    },
                    {
                        "value": "土默特左旗",
                        "id": "150121"
                    },
                    {
                        "value": "托克托县",
                        "id": "150122"
                    },
                    {
                        "value": "和林格尔县",
                        "id": "150123"
                    },
                    {
                        "value": "清水河县",
                        "id": "150124"
                    },
                    {
                        "value": "武川县",
                        "id": "150125"
                    }
                ]
            },
            {
                "value": "包头市",
                "id": "150200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "150201"
                    },
                    {
                        "value": "东河区",
                        "id": "150202"
                    },
                    {
                        "value": "昆都仑区",
                        "id": "150203"
                    },
                    {
                        "value": "青山区",
                        "id": "150204"
                    },
                    {
                        "value": "石拐区",
                        "id": "150205"
                    },
                    {
                        "value": "白云鄂博矿区",
                        "id": "150206"
                    },
                    {
                        "value": "九原区",
                        "id": "150207"
                    },
                    {
                        "value": "土默特右旗",
                        "id": "150221"
                    },
                    {
                        "value": "固阳县",
                        "id": "150222"
                    },
                    {
                        "value": "达尔罕茂明安联合旗",
                        "id": "150223"
                    }
                ]
            },
            {
                "value": "乌海市",
                "id": "150300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "150301"
                    },
                    {
                        "value": "海勃湾区",
                        "id": "150302"
                    },
                    {
                        "value": "海南区",
                        "id": "150303"
                    },
                    {
                        "value": "乌达区",
                        "id": "150304"
                    }
                ]
            },
            {
                "value": "赤峰市",
                "id": "150400",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "150401"
                    },
                    {
                        "value": "红山区",
                        "id": "150402"
                    },
                    {
                        "value": "元宝山区",
                        "id": "150403"
                    },
                    {
                        "value": "松山区",
                        "id": "150404"
                    },
                    {
                        "value": "阿鲁科尔沁旗",
                        "id": "150421"
                    },
                    {
                        "value": "巴林左旗",
                        "id": "150422"
                    },
                    {
                        "value": "巴林右旗",
                        "id": "150423"
                    },
                    {
                        "value": "林西县",
                        "id": "150424"
                    },
                    {
                        "value": "克什克腾旗",
                        "id": "150425"
                    },
                    {
                        "value": "翁牛特旗",
                        "id": "150426"
                    },
                    {
                        "value": "喀喇沁旗",
                        "id": "150428"
                    },
                    {
                        "value": "宁城县",
                        "id": "150429"
                    },
                    {
                        "value": "敖汉旗",
                        "id": "150430"
                    }
                ]
            },
            {
                "value": "通辽市",
                "id": "150500",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "150501"
                    },
                    {
                        "value": "科尔沁区",
                        "id": "150502"
                    },
                    {
                        "value": "科尔沁左翼中旗",
                        "id": "150521"
                    },
                    {
                        "value": "科尔沁左翼后旗",
                        "id": "150522"
                    },
                    {
                        "value": "开鲁县",
                        "id": "150523"
                    },
                    {
                        "value": "库伦旗",
                        "id": "150524"
                    },
                    {
                        "value": "奈曼旗",
                        "id": "150525"
                    },
                    {
                        "value": "扎鲁特旗",
                        "id": "150526"
                    },
                    {
                        "value": "霍林郭勒市",
                        "id": "150581"
                    }
                ]
            },
            {
                "value": "鄂尔多斯市",
                "id": "150600",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "150601"
                    },
                    {
                        "value": "东胜区",
                        "id": "150602"
                    },
                    {
                        "value": "达拉特旗",
                        "id": "150621"
                    },
                    {
                        "value": "准格尔旗",
                        "id": "150622"
                    },
                    {
                        "value": "鄂托克前旗",
                        "id": "150623"
                    },
                    {
                        "value": "鄂托克旗",
                        "id": "150624"
                    },
                    {
                        "value": "杭锦旗",
                        "id": "150625"
                    },
                    {
                        "value": "乌审旗",
                        "id": "150626"
                    },
                    {
                        "value": "伊金霍洛旗",
                        "id": "150627"
                    }
                ]
            },
            {
                "value": "呼伦贝尔市",
                "id": "150700",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "150701"
                    },
                    {
                        "value": "海拉尔区",
                        "id": "150702"
                    },
                    {
                        "value": "扎赉诺尔区",
                        "id": "150703"
                    },
                    {
                        "value": "阿荣旗",
                        "id": "150721"
                    },
                    {
                        "value": "莫力达瓦达斡尔族自治旗",
                        "id": "150722"
                    },
                    {
                        "value": "鄂伦春自治旗",
                        "id": "150723"
                    },
                    {
                        "value": "鄂温克族自治旗",
                        "id": "150724"
                    },
                    {
                        "value": "陈巴尔虎旗",
                        "id": "150725"
                    },
                    {
                        "value": "新巴尔虎左旗",
                        "id": "150726"
                    },
                    {
                        "value": "新巴尔虎右旗",
                        "id": "150727"
                    },
                    {
                        "value": "满洲里市",
                        "id": "150781"
                    },
                    {
                        "value": "牙克石市",
                        "id": "150782"
                    },
                    {
                        "value": "扎兰屯市",
                        "id": "150783"
                    },
                    {
                        "value": "额尔古纳市",
                        "id": "150784"
                    },
                    {
                        "value": "根河市",
                        "id": "150785"
                    }
                ]
            },
            {
                "value": "巴彦淖尔市",
                "id": "150800",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "150801"
                    },
                    {
                        "value": "临河区",
                        "id": "150802"
                    },
                    {
                        "value": "五原县",
                        "id": "150821"
                    },
                    {
                        "value": "磴口县",
                        "id": "150822"
                    },
                    {
                        "value": "乌拉特前旗",
                        "id": "150823"
                    },
                    {
                        "value": "乌拉特中旗",
                        "id": "150824"
                    },
                    {
                        "value": "乌拉特后旗",
                        "id": "150825"
                    },
                    {
                        "value": "杭锦后旗",
                        "id": "150826"
                    }
                ]
            },
            {
                "value": "乌兰察布市",
                "id": "150900",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "150901"
                    },
                    {
                        "value": "集宁区",
                        "id": "150902"
                    },
                    {
                        "value": "卓资县",
                        "id": "150921"
                    },
                    {
                        "value": "化德县",
                        "id": "150922"
                    },
                    {
                        "value": "商都县",
                        "id": "150923"
                    },
                    {
                        "value": "兴和县",
                        "id": "150924"
                    },
                    {
                        "value": "凉城县",
                        "id": "150925"
                    },
                    {
                        "value": "察哈尔右翼前旗",
                        "id": "150926"
                    },
                    {
                        "value": "察哈尔右翼中旗",
                        "id": "150927"
                    },
                    {
                        "value": "察哈尔右翼后旗",
                        "id": "150928"
                    },
                    {
                        "value": "四子王旗",
                        "id": "150929"
                    },
                    {
                        "value": "丰镇市",
                        "id": "150981"
                    }
                ]
            },
            {
                "value": "兴安盟",
                "id": "152200",
                "childs": [
                    {
                        "value": "乌兰浩特市",
                        "id": "152201"
                    },
                    {
                        "value": "阿尔山市",
                        "id": "152202"
                    },
                    {
                        "value": "科尔沁右翼前旗",
                        "id": "152221"
                    },
                    {
                        "value": "科尔沁右翼中旗",
                        "id": "152222"
                    },
                    {
                        "value": "扎赉特旗",
                        "id": "152223"
                    },
                    {
                        "value": "突泉县",
                        "id": "152224"
                    }
                ]
            },
            {
                "value": "锡林郭勒盟",
                "id": "152500",
                "childs": [
                    {
                        "value": "二连浩特市",
                        "id": "152501"
                    },
                    {
                        "value": "锡林浩特市",
                        "id": "152502"
                    },
                    {
                        "value": "阿巴嘎旗",
                        "id": "152522"
                    },
                    {
                        "value": "苏尼特左旗",
                        "id": "152523"
                    },
                    {
                        "value": "苏尼特右旗",
                        "id": "152524"
                    },
                    {
                        "value": "东乌珠穆沁旗",
                        "id": "152525"
                    },
                    {
                        "value": "西乌珠穆沁旗",
                        "id": "152526"
                    },
                    {
                        "value": "太仆寺旗",
                        "id": "152527"
                    },
                    {
                        "value": "镶黄旗",
                        "id": "152528"
                    },
                    {
                        "value": "正镶白旗",
                        "id": "152529"
                    },
                    {
                        "value": "正蓝旗",
                        "id": "152530"
                    },
                    {
                        "value": "多伦县",
                        "id": "152531"
                    }
                ]
            },
            {
                "value": "阿拉善盟",
                "id": "152900",
                "childs": [
                    {
                        "value": "阿拉善左旗",
                        "id": "152921"
                    },
                    {
                        "value": "阿拉善右旗",
                        "id": "152922"
                    },
                    {
                        "value": "额济纳旗",
                        "id": "152923"
                    }
                ]
            }
        ]
    },
    {
        "value": "辽宁省",
        "id": "210000",
        "childs": [
            {
                "value": "沈阳市",
                "id": "210100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "210101"
                    },
                    {
                        "value": "和平区",
                        "id": "210102"
                    },
                    {
                        "value": "沈河区",
                        "id": "210103"
                    },
                    {
                        "value": "大东区",
                        "id": "210104"
                    },
                    {
                        "value": "皇姑区",
                        "id": "210105"
                    },
                    {
                        "value": "铁西区",
                        "id": "210106"
                    },
                    {
                        "value": "苏家屯区",
                        "id": "210111"
                    },
                    {
                        "value": "浑南区",
                        "id": "210112"
                    },
                    {
                        "value": "沈北新区",
                        "id": "210113"
                    },
                    {
                        "value": "于洪区",
                        "id": "210114"
                    },
                    {
                        "value": "辽中县",
                        "id": "210122"
                    },
                    {
                        "value": "康平县",
                        "id": "210123"
                    },
                    {
                        "value": "法库县",
                        "id": "210124"
                    },
                    {
                        "value": "新民市",
                        "id": "210181"
                    }
                ]
            },
            {
                "value": "大连市",
                "id": "210200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "210201"
                    },
                    {
                        "value": "中山区",
                        "id": "210202"
                    },
                    {
                        "value": "西岗区",
                        "id": "210203"
                    },
                    {
                        "value": "沙河口区",
                        "id": "210204"
                    },
                    {
                        "value": "甘井子区",
                        "id": "210211"
                    },
                    {
                        "value": "旅顺口区",
                        "id": "210212"
                    },
                    {
                        "value": "金州区",
                        "id": "210213"
                    },
                    {
                        "value": "长海县",
                        "id": "210224"
                    },
                    {
                        "value": "瓦房店市",
                        "id": "210281"
                    },
                    {
                        "value": "普兰店市",
                        "id": "210282"
                    },
                    {
                        "value": "庄河市",
                        "id": "210283"
                    }
                ]
            },
            {
                "value": "鞍山市",
                "id": "210300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "210301"
                    },
                    {
                        "value": "铁东区",
                        "id": "210302"
                    },
                    {
                        "value": "铁西区",
                        "id": "210303"
                    },
                    {
                        "value": "立山区",
                        "id": "210304"
                    },
                    {
                        "value": "千山区",
                        "id": "210311"
                    },
                    {
                        "value": "台安县",
                        "id": "210321"
                    },
                    {
                        "value": "岫岩满族自治县",
                        "id": "210323"
                    },
                    {
                        "value": "海城市",
                        "id": "210381"
                    }
                ]
            },
            {
                "value": "抚顺市",
                "id": "210400",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "210401"
                    },
                    {
                        "value": "新抚区",
                        "id": "210402"
                    },
                    {
                        "value": "东洲区",
                        "id": "210403"
                    },
                    {
                        "value": "望花区",
                        "id": "210404"
                    },
                    {
                        "value": "顺城区",
                        "id": "210411"
                    },
                    {
                        "value": "抚顺县",
                        "id": "210421"
                    },
                    {
                        "value": "新宾满族自治县",
                        "id": "210422"
                    },
                    {
                        "value": "清原满族自治县",
                        "id": "210423"
                    }
                ]
            },
            {
                "value": "本溪市",
                "id": "210500",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "210501"
                    },
                    {
                        "value": "平山区",
                        "id": "210502"
                    },
                    {
                        "value": "溪湖区",
                        "id": "210503"
                    },
                    {
                        "value": "明山区",
                        "id": "210504"
                    },
                    {
                        "value": "南芬区",
                        "id": "210505"
                    },
                    {
                        "value": "本溪满族自治县",
                        "id": "210521"
                    },
                    {
                        "value": "桓仁满族自治县",
                        "id": "210522"
                    }
                ]
            },
            {
                "value": "丹东市",
                "id": "210600",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "210601"
                    },
                    {
                        "value": "元宝区",
                        "id": "210602"
                    },
                    {
                        "value": "振兴区",
                        "id": "210603"
                    },
                    {
                        "value": "振安区",
                        "id": "210604"
                    },
                    {
                        "value": "宽甸满族自治县",
                        "id": "210624"
                    },
                    {
                        "value": "东港市",
                        "id": "210681"
                    },
                    {
                        "value": "凤城市",
                        "id": "210682"
                    }
                ]
            },
            {
                "value": "锦州市",
                "id": "210700",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "210701"
                    },
                    {
                        "value": "古塔区",
                        "id": "210702"
                    },
                    {
                        "value": "凌河区",
                        "id": "210703"
                    },
                    {
                        "value": "太和区",
                        "id": "210711"
                    },
                    {
                        "value": "黑山县",
                        "id": "210726"
                    },
                    {
                        "value": "义县",
                        "id": "210727"
                    },
                    {
                        "value": "凌海市",
                        "id": "210781"
                    },
                    {
                        "value": "北镇市",
                        "id": "210782"
                    }
                ]
            },
            {
                "value": "营口市",
                "id": "210800",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "210801"
                    },
                    {
                        "value": "站前区",
                        "id": "210802"
                    },
                    {
                        "value": "西市区",
                        "id": "210803"
                    },
                    {
                        "value": "鲅鱼圈区",
                        "id": "210804"
                    },
                    {
                        "value": "老边区",
                        "id": "210811"
                    },
                    {
                        "value": "盖州市",
                        "id": "210881"
                    },
                    {
                        "value": "大石桥市",
                        "id": "210882"
                    }
                ]
            },
            {
                "value": "阜新市",
                "id": "210900",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "210901"
                    },
                    {
                        "value": "海州区",
                        "id": "210902"
                    },
                    {
                        "value": "新邱区",
                        "id": "210903"
                    },
                    {
                        "value": "太平区",
                        "id": "210904"
                    },
                    {
                        "value": "清河门区",
                        "id": "210905"
                    },
                    {
                        "value": "细河区",
                        "id": "210911"
                    },
                    {
                        "value": "阜新蒙古族自治县",
                        "id": "210921"
                    },
                    {
                        "value": "彰武县",
                        "id": "210922"
                    }
                ]
            },
            {
                "value": "辽阳市",
                "id": "211000",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "211001"
                    },
                    {
                        "value": "白塔区",
                        "id": "211002"
                    },
                    {
                        "value": "文圣区",
                        "id": "211003"
                    },
                    {
                        "value": "宏伟区",
                        "id": "211004"
                    },
                    {
                        "value": "弓长岭区",
                        "id": "211005"
                    },
                    {
                        "value": "太子河区",
                        "id": "211011"
                    },
                    {
                        "value": "辽阳县",
                        "id": "211021"
                    },
                    {
                        "value": "灯塔市",
                        "id": "211081"
                    }
                ]
            },
            {
                "value": "盘锦市",
                "id": "211100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "211101"
                    },
                    {
                        "value": "双台子区",
                        "id": "211102"
                    },
                    {
                        "value": "兴隆台区",
                        "id": "211103"
                    },
                    {
                        "value": "大洼县",
                        "id": "211121"
                    },
                    {
                        "value": "盘山县",
                        "id": "211122"
                    }
                ]
            },
            {
                "value": "铁岭市",
                "id": "211200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "211201"
                    },
                    {
                        "value": "银州区",
                        "id": "211202"
                    },
                    {
                        "value": "清河区",
                        "id": "211204"
                    },
                    {
                        "value": "铁岭县",
                        "id": "211221"
                    },
                    {
                        "value": "西丰县",
                        "id": "211223"
                    },
                    {
                        "value": "昌图县",
                        "id": "211224"
                    },
                    {
                        "value": "调兵山市",
                        "id": "211281"
                    },
                    {
                        "value": "开原市",
                        "id": "211282"
                    }
                ]
            },
            {
                "value": "朝阳市",
                "id": "211300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "211301"
                    },
                    {
                        "value": "双塔区",
                        "id": "211302"
                    },
                    {
                        "value": "龙城区",
                        "id": "211303"
                    },
                    {
                        "value": "朝阳县",
                        "id": "211321"
                    },
                    {
                        "value": "建平县",
                        "id": "211322"
                    },
                    {
                        "value": "喀喇沁左翼蒙古族自治县",
                        "id": "211324"
                    },
                    {
                        "value": "北票市",
                        "id": "211381"
                    },
                    {
                        "value": "凌源市",
                        "id": "211382"
                    }
                ]
            },
            {
                "value": "葫芦岛市",
                "id": "211400",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "211401"
                    },
                    {
                        "value": "连山区",
                        "id": "211402"
                    },
                    {
                        "value": "龙港区",
                        "id": "211403"
                    },
                    {
                        "value": "南票区",
                        "id": "211404"
                    },
                    {
                        "value": "绥中县",
                        "id": "211421"
                    },
                    {
                        "value": "建昌县",
                        "id": "211422"
                    },
                    {
                        "value": "兴城市",
                        "id": "211481"
                    }
                ]
            }
        ]
    },
    {
        "value": "吉林省",
        "id": "220000",
        "childs": [
            {
                "value": "长春市",
                "id": "220100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "220101"
                    },
                    {
                        "value": "南关区",
                        "id": "220102"
                    },
                    {
                        "value": "宽城区",
                        "id": "220103"
                    },
                    {
                        "value": "朝阳区",
                        "id": "220104"
                    },
                    {
                        "value": "二道区",
                        "id": "220105"
                    },
                    {
                        "value": "绿园区",
                        "id": "220106"
                    },
                    {
                        "value": "双阳区",
                        "id": "220112"
                    },
                    {
                        "value": "九台区",
                        "id": "220113"
                    },
                    {
                        "value": "农安县",
                        "id": "220122"
                    },
                    {
                        "value": "榆树市",
                        "id": "220182"
                    },
                    {
                        "value": "德惠市",
                        "id": "220183"
                    }
                ]
            },
            {
                "value": "吉林市",
                "id": "220200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "220201"
                    },
                    {
                        "value": "昌邑区",
                        "id": "220202"
                    },
                    {
                        "value": "龙潭区",
                        "id": "220203"
                    },
                    {
                        "value": "船营区",
                        "id": "220204"
                    },
                    {
                        "value": "丰满区",
                        "id": "220211"
                    },
                    {
                        "value": "永吉县",
                        "id": "220221"
                    },
                    {
                        "value": "蛟河市",
                        "id": "220281"
                    },
                    {
                        "value": "桦甸市",
                        "id": "220282"
                    },
                    {
                        "value": "舒兰市",
                        "id": "220283"
                    },
                    {
                        "value": "磐石市",
                        "id": "220284"
                    }
                ]
            },
            {
                "value": "四平市",
                "id": "220300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "220301"
                    },
                    {
                        "value": "铁西区",
                        "id": "220302"
                    },
                    {
                        "value": "铁东区",
                        "id": "220303"
                    },
                    {
                        "value": "梨树县",
                        "id": "220322"
                    },
                    {
                        "value": "伊通满族自治县",
                        "id": "220323"
                    },
                    {
                        "value": "公主岭市",
                        "id": "220381"
                    },
                    {
                        "value": "双辽市",
                        "id": "220382"
                    }
                ]
            },
            {
                "value": "辽源市",
                "id": "220400",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "220401"
                    },
                    {
                        "value": "龙山区",
                        "id": "220402"
                    },
                    {
                        "value": "西安区",
                        "id": "220403"
                    },
                    {
                        "value": "东丰县",
                        "id": "220421"
                    },
                    {
                        "value": "东辽县",
                        "id": "220422"
                    }
                ]
            },
            {
                "value": "通化市",
                "id": "220500",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "220501"
                    },
                    {
                        "value": "东昌区",
                        "id": "220502"
                    },
                    {
                        "value": "二道江区",
                        "id": "220503"
                    },
                    {
                        "value": "通化县",
                        "id": "220521"
                    },
                    {
                        "value": "辉南县",
                        "id": "220523"
                    },
                    {
                        "value": "柳河县",
                        "id": "220524"
                    },
                    {
                        "value": "梅河口市",
                        "id": "220581"
                    },
                    {
                        "value": "集安市",
                        "id": "220582"
                    }
                ]
            },
            {
                "value": "白山市",
                "id": "220600",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "220601"
                    },
                    {
                        "value": "浑江区",
                        "id": "220602"
                    },
                    {
                        "value": "江源区",
                        "id": "220605"
                    },
                    {
                        "value": "抚松县",
                        "id": "220621"
                    },
                    {
                        "value": "靖宇县",
                        "id": "220622"
                    },
                    {
                        "value": "长白朝鲜族自治县",
                        "id": "220623"
                    },
                    {
                        "value": "临江市",
                        "id": "220681"
                    }
                ]
            },
            {
                "value": "松原市",
                "id": "220700",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "220701"
                    },
                    {
                        "value": "宁江区",
                        "id": "220702"
                    },
                    {
                        "value": "前郭尔罗斯蒙古族自治县",
                        "id": "220721"
                    },
                    {
                        "value": "长岭县",
                        "id": "220722"
                    },
                    {
                        "value": "乾安县",
                        "id": "220723"
                    },
                    {
                        "value": "扶余市",
                        "id": "220781"
                    }
                ]
            },
            {
                "value": "白城市",
                "id": "220800",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "220801"
                    },
                    {
                        "value": "洮北区",
                        "id": "220802"
                    },
                    {
                        "value": "镇赉县",
                        "id": "220821"
                    },
                    {
                        "value": "通榆县",
                        "id": "220822"
                    },
                    {
                        "value": "洮南市",
                        "id": "220881"
                    },
                    {
                        "value": "大安市",
                        "id": "220882"
                    }
                ]
            },
            {
                "value": "延边朝鲜族自治州",
                "id": "222400",
                "childs": [
                    {
                        "value": "延吉市",
                        "id": "222401"
                    },
                    {
                        "value": "图们市",
                        "id": "222402"
                    },
                    {
                        "value": "敦化市",
                        "id": "222403"
                    },
                    {
                        "value": "珲春市",
                        "id": "222404"
                    },
                    {
                        "value": "龙井市",
                        "id": "222405"
                    },
                    {
                        "value": "和龙市",
                        "id": "222406"
                    },
                    {
                        "value": "汪清县",
                        "id": "222424"
                    },
                    {
                        "value": "安图县",
                        "id": "222426"
                    }
                ]
            }
        ]
    },
    {
        "value": "黑龙江省",
        "id": "230000",
        "childs": [
            {
                "value": "哈尔滨市",
                "id": "230100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "230101"
                    },
                    {
                        "value": "道里区",
                        "id": "230102"
                    },
                    {
                        "value": "南岗区",
                        "id": "230103"
                    },
                    {
                        "value": "道外区",
                        "id": "230104"
                    },
                    {
                        "value": "平房区",
                        "id": "230108"
                    },
                    {
                        "value": "松北区",
                        "id": "230109"
                    },
                    {
                        "value": "香坊区",
                        "id": "230110"
                    },
                    {
                        "value": "呼兰区",
                        "id": "230111"
                    },
                    {
                        "value": "阿城区",
                        "id": "230112"
                    },
                    {
                        "value": "双城区",
                        "id": "230113"
                    },
                    {
                        "value": "依兰县",
                        "id": "230123"
                    },
                    {
                        "value": "方正县",
                        "id": "230124"
                    },
                    {
                        "value": "宾县",
                        "id": "230125"
                    },
                    {
                        "value": "巴彦县",
                        "id": "230126"
                    },
                    {
                        "value": "木兰县",
                        "id": "230127"
                    },
                    {
                        "value": "通河县",
                        "id": "230128"
                    },
                    {
                        "value": "延寿县",
                        "id": "230129"
                    },
                    {
                        "value": "尚志市",
                        "id": "230183"
                    },
                    {
                        "value": "五常市",
                        "id": "230184"
                    }
                ]
            },
            {
                "value": "齐齐哈尔市",
                "id": "230200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "230201"
                    },
                    {
                        "value": "龙沙区",
                        "id": "230202"
                    },
                    {
                        "value": "建华区",
                        "id": "230203"
                    },
                    {
                        "value": "铁锋区",
                        "id": "230204"
                    },
                    {
                        "value": "昂昂溪区",
                        "id": "230205"
                    },
                    {
                        "value": "富拉尔基区",
                        "id": "230206"
                    },
                    {
                        "value": "碾子山区",
                        "id": "230207"
                    },
                    {
                        "value": "梅里斯达斡尔族区",
                        "id": "230208"
                    },
                    {
                        "value": "龙江县",
                        "id": "230221"
                    },
                    {
                        "value": "依安县",
                        "id": "230223"
                    },
                    {
                        "value": "泰来县",
                        "id": "230224"
                    },
                    {
                        "value": "甘南县",
                        "id": "230225"
                    },
                    {
                        "value": "富裕县",
                        "id": "230227"
                    },
                    {
                        "value": "克山县",
                        "id": "230229"
                    },
                    {
                        "value": "克东县",
                        "id": "230230"
                    },
                    {
                        "value": "拜泉县",
                        "id": "230231"
                    },
                    {
                        "value": "讷河市",
                        "id": "230281"
                    }
                ]
            },
            {
                "value": "鸡西市",
                "id": "230300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "230301"
                    },
                    {
                        "value": "鸡冠区",
                        "id": "230302"
                    },
                    {
                        "value": "恒山区",
                        "id": "230303"
                    },
                    {
                        "value": "滴道区",
                        "id": "230304"
                    },
                    {
                        "value": "梨树区",
                        "id": "230305"
                    },
                    {
                        "value": "城子河区",
                        "id": "230306"
                    },
                    {
                        "value": "麻山区",
                        "id": "230307"
                    },
                    {
                        "value": "鸡东县",
                        "id": "230321"
                    },
                    {
                        "value": "虎林市",
                        "id": "230381"
                    },
                    {
                        "value": "密山市",
                        "id": "230382"
                    }
                ]
            },
            {
                "value": "鹤岗市",
                "id": "230400",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "230401"
                    },
                    {
                        "value": "向阳区",
                        "id": "230402"
                    },
                    {
                        "value": "工农区",
                        "id": "230403"
                    },
                    {
                        "value": "南山区",
                        "id": "230404"
                    },
                    {
                        "value": "兴安区",
                        "id": "230405"
                    },
                    {
                        "value": "东山区",
                        "id": "230406"
                    },
                    {
                        "value": "兴山区",
                        "id": "230407"
                    },
                    {
                        "value": "萝北县",
                        "id": "230421"
                    },
                    {
                        "value": "绥滨县",
                        "id": "230422"
                    }
                ]
            },
            {
                "value": "双鸭山市",
                "id": "230500",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "230501"
                    },
                    {
                        "value": "尖山区",
                        "id": "230502"
                    },
                    {
                        "value": "岭东区",
                        "id": "230503"
                    },
                    {
                        "value": "四方台区",
                        "id": "230505"
                    },
                    {
                        "value": "宝山区",
                        "id": "230506"
                    },
                    {
                        "value": "集贤县",
                        "id": "230521"
                    },
                    {
                        "value": "友谊县",
                        "id": "230522"
                    },
                    {
                        "value": "宝清县",
                        "id": "230523"
                    },
                    {
                        "value": "饶河县",
                        "id": "230524"
                    }
                ]
            },
            {
                "value": "大庆市",
                "id": "230600",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "230601"
                    },
                    {
                        "value": "萨尔图区",
                        "id": "230602"
                    },
                    {
                        "value": "龙凤区",
                        "id": "230603"
                    },
                    {
                        "value": "让胡路区",
                        "id": "230604"
                    },
                    {
                        "value": "红岗区",
                        "id": "230605"
                    },
                    {
                        "value": "大同区",
                        "id": "230606"
                    },
                    {
                        "value": "肇州县",
                        "id": "230621"
                    },
                    {
                        "value": "肇源县",
                        "id": "230622"
                    },
                    {
                        "value": "林甸县",
                        "id": "230623"
                    },
                    {
                        "value": "杜尔伯特蒙古族自治县",
                        "id": "230624"
                    }
                ]
            },
            {
                "value": "伊春市",
                "id": "230700",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "230701"
                    },
                    {
                        "value": "伊春区",
                        "id": "230702"
                    },
                    {
                        "value": "南岔区",
                        "id": "230703"
                    },
                    {
                        "value": "友好区",
                        "id": "230704"
                    },
                    {
                        "value": "西林区",
                        "id": "230705"
                    },
                    {
                        "value": "翠峦区",
                        "id": "230706"
                    },
                    {
                        "value": "新青区",
                        "id": "230707"
                    },
                    {
                        "value": "美溪区",
                        "id": "230708"
                    },
                    {
                        "value": "金山屯区",
                        "id": "230709"
                    },
                    {
                        "value": "五营区",
                        "id": "230710"
                    },
                    {
                        "value": "乌马河区",
                        "id": "230711"
                    },
                    {
                        "value": "汤旺河区",
                        "id": "230712"
                    },
                    {
                        "value": "带岭区",
                        "id": "230713"
                    },
                    {
                        "value": "乌伊岭区",
                        "id": "230714"
                    },
                    {
                        "value": "红星区",
                        "id": "230715"
                    },
                    {
                        "value": "上甘岭区",
                        "id": "230716"
                    },
                    {
                        "value": "嘉荫县",
                        "id": "230722"
                    },
                    {
                        "value": "铁力市",
                        "id": "230781"
                    }
                ]
            },
            {
                "value": "佳木斯市",
                "id": "230800",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "230801"
                    },
                    {
                        "value": "向阳区",
                        "id": "230803"
                    },
                    {
                        "value": "前进区",
                        "id": "230804"
                    },
                    {
                        "value": "东风区",
                        "id": "230805"
                    },
                    {
                        "value": "郊区",
                        "id": "230811"
                    },
                    {
                        "value": "桦南县",
                        "id": "230822"
                    },
                    {
                        "value": "桦川县",
                        "id": "230826"
                    },
                    {
                        "value": "汤原县",
                        "id": "230828"
                    },
                    {
                        "value": "抚远县",
                        "id": "230833"
                    },
                    {
                        "value": "同江市",
                        "id": "230881"
                    },
                    {
                        "value": "富锦市",
                        "id": "230882"
                    }
                ]
            },
            {
                "value": "七台河市",
                "id": "230900",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "230901"
                    },
                    {
                        "value": "新兴区",
                        "id": "230902"
                    },
                    {
                        "value": "桃山区",
                        "id": "230903"
                    },
                    {
                        "value": "茄子河区",
                        "id": "230904"
                    },
                    {
                        "value": "勃利县",
                        "id": "230921"
                    }
                ]
            },
            {
                "value": "牡丹江市",
                "id": "231000",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "231001"
                    },
                    {
                        "value": "东安区",
                        "id": "231002"
                    },
                    {
                        "value": "阳明区",
                        "id": "231003"
                    },
                    {
                        "value": "爱民区",
                        "id": "231004"
                    },
                    {
                        "value": "西安区",
                        "id": "231005"
                    },
                    {
                        "value": "东宁县",
                        "id": "231024"
                    },
                    {
                        "value": "林口县",
                        "id": "231025"
                    },
                    {
                        "value": "绥芬河市",
                        "id": "231081"
                    },
                    {
                        "value": "海林市",
                        "id": "231083"
                    },
                    {
                        "value": "宁安市",
                        "id": "231084"
                    },
                    {
                        "value": "穆棱市",
                        "id": "231085"
                    }
                ]
            },
            {
                "value": "黑河市",
                "id": "231100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "231101"
                    },
                    {
                        "value": "爱辉区",
                        "id": "231102"
                    },
                    {
                        "value": "嫩江县",
                        "id": "231121"
                    },
                    {
                        "value": "逊克县",
                        "id": "231123"
                    },
                    {
                        "value": "孙吴县",
                        "id": "231124"
                    },
                    {
                        "value": "北安市",
                        "id": "231181"
                    },
                    {
                        "value": "五大连池市",
                        "id": "231182"
                    }
                ]
            },
            {
                "value": "绥化市",
                "id": "231200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "231201"
                    },
                    {
                        "value": "北林区",
                        "id": "231202"
                    },
                    {
                        "value": "望奎县",
                        "id": "231221"
                    },
                    {
                        "value": "兰西县",
                        "id": "231222"
                    },
                    {
                        "value": "青冈县",
                        "id": "231223"
                    },
                    {
                        "value": "庆安县",
                        "id": "231224"
                    },
                    {
                        "value": "明水县",
                        "id": "231225"
                    },
                    {
                        "value": "绥棱县",
                        "id": "231226"
                    },
                    {
                        "value": "安达市",
                        "id": "231281"
                    },
                    {
                        "value": "肇东市",
                        "id": "231282"
                    },
                    {
                        "value": "海伦市",
                        "id": "231283"
                    }
                ]
            },
            {
                "value": "大兴安岭地区",
                "id": "232700",
                "childs": [
                    {
                        "value": "呼玛县",
                        "id": "232721"
                    },
                    {
                        "value": "塔河县",
                        "id": "232722"
                    },
                    {
                        "value": "漠河县",
                        "id": "232723"
                    }
                ]
            }
        ]
    },
    {
        "value": "上海",
        "id": "310000",
        "childs": [
            {
                "value": "上海市",
                "id": "310000",
                "childs": [
                    {
                        "value": "黄浦区",
                        "id": "310101"
                    },
                    {
                        "value": "徐汇区",
                        "id": "310104"
                    },
                    {
                        "value": "长宁区",
                        "id": "310105"
                    },
                    {
                        "value": "静安区",
                        "id": "310106"
                    },
                    {
                        "value": "普陀区",
                        "id": "310107"
                    },
                    {
                        "value": "闸北区",
                        "id": "310108"
                    },
                    {
                        "value": "虹口区",
                        "id": "310109"
                    },
                    {
                        "value": "杨浦区",
                        "id": "310110"
                    },
                    {
                        "value": "闵行区",
                        "id": "310112"
                    },
                    {
                        "value": "宝山区",
                        "id": "310113"
                    },
                    {
                        "value": "嘉定区",
                        "id": "310114"
                    },
                    {
                        "value": "浦东新区",
                        "id": "310115"
                    },
                    {
                        "value": "金山区",
                        "id": "310116"
                    },
                    {
                        "value": "松江区",
                        "id": "310117"
                    },
                    {
                        "value": "青浦区",
                        "id": "310118"
                    },
                    {
                        "value": "奉贤区",
                        "id": "310120"
                    },
                    {
                        "value": "崇明县",
                        "id": "310230"
                    }
                ]
            }
        ]
    },
    {
        "value": "江苏省",
        "id": "320000",
        "childs": [
            {
                "value": "南京市",
                "id": "320100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "320101"
                    },
                    {
                        "value": "玄武区",
                        "id": "320102"
                    },
                    {
                        "value": "秦淮区",
                        "id": "320104"
                    },
                    {
                        "value": "建邺区",
                        "id": "320105"
                    },
                    {
                        "value": "鼓楼区",
                        "id": "320106"
                    },
                    {
                        "value": "浦口区",
                        "id": "320111"
                    },
                    {
                        "value": "栖霞区",
                        "id": "320113"
                    },
                    {
                        "value": "雨花台区",
                        "id": "320114"
                    },
                    {
                        "value": "江宁区",
                        "id": "320115"
                    },
                    {
                        "value": "六合区",
                        "id": "320116"
                    },
                    {
                        "value": "溧水区",
                        "id": "320117"
                    },
                    {
                        "value": "高淳区",
                        "id": "320118"
                    }
                ]
            },
            {
                "value": "无锡市",
                "id": "320200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "320201"
                    },
                    {
                        "value": "崇安区",
                        "id": "320202"
                    },
                    {
                        "value": "南长区",
                        "id": "320203"
                    },
                    {
                        "value": "北塘区",
                        "id": "320204"
                    },
                    {
                        "value": "锡山区",
                        "id": "320205"
                    },
                    {
                        "value": "惠山区",
                        "id": "320206"
                    },
                    {
                        "value": "滨湖区",
                        "id": "320211"
                    },
                    {
                        "value": "江阴市",
                        "id": "320281"
                    },
                    {
                        "value": "宜兴市",
                        "id": "320282"
                    }
                ]
            },
            {
                "value": "徐州市",
                "id": "320300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "320301"
                    },
                    {
                        "value": "鼓楼区",
                        "id": "320302"
                    },
                    {
                        "value": "云龙区",
                        "id": "320303"
                    },
                    {
                        "value": "贾汪区",
                        "id": "320305"
                    },
                    {
                        "value": "泉山区",
                        "id": "320311"
                    },
                    {
                        "value": "铜山区",
                        "id": "320312"
                    },
                    {
                        "value": "丰县",
                        "id": "320321"
                    },
                    {
                        "value": "沛县",
                        "id": "320322"
                    },
                    {
                        "value": "睢宁县",
                        "id": "320324"
                    },
                    {
                        "value": "新沂市",
                        "id": "320381"
                    },
                    {
                        "value": "邳州市",
                        "id": "320382"
                    }
                ]
            },
            {
                "value": "常州市",
                "id": "320400",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "320401"
                    },
                    {
                        "value": "天宁区",
                        "id": "320402"
                    },
                    {
                        "value": "钟楼区",
                        "id": "320404"
                    },
                    {
                        "value": "戚墅堰区",
                        "id": "320405"
                    },
                    {
                        "value": "新北区",
                        "id": "320411"
                    },
                    {
                        "value": "武进区",
                        "id": "320412"
                    },
                    {
                        "value": "溧阳市",
                        "id": "320481"
                    },
                    {
                        "value": "金坛市",
                        "id": "320482"
                    }
                ]
            },
            {
                "value": "苏州市",
                "id": "320500",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "320501"
                    },
                    {
                        "value": "虎丘区",
                        "id": "320505"
                    },
                    {
                        "value": "吴中区",
                        "id": "320506"
                    },
                    {
                        "value": "相城区",
                        "id": "320507"
                    },
                    {
                        "value": "姑苏区",
                        "id": "320508"
                    },
                    {
                        "value": "吴江区",
                        "id": "320509"
                    },
                    {
                        "value": "常熟市",
                        "id": "320581"
                    },
                    {
                        "value": "张家港市",
                        "id": "320582"
                    },
                    {
                        "value": "昆山市",
                        "id": "320583"
                    },
                    {
                        "value": "太仓市",
                        "id": "320585"
                    }
                ]
            },
            {
                "value": "南通市",
                "id": "320600",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "320601"
                    },
                    {
                        "value": "崇川区",
                        "id": "320602"
                    },
                    {
                        "value": "港闸区",
                        "id": "320611"
                    },
                    {
                        "value": "通州区",
                        "id": "320612"
                    },
                    {
                        "value": "海安县",
                        "id": "320621"
                    },
                    {
                        "value": "如东县",
                        "id": "320623"
                    },
                    {
                        "value": "启东市",
                        "id": "320681"
                    },
                    {
                        "value": "如皋市",
                        "id": "320682"
                    },
                    {
                        "value": "海门市",
                        "id": "320684"
                    }
                ]
            },
            {
                "value": "连云港市",
                "id": "320700",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "320701"
                    },
                    {
                        "value": "连云区",
                        "id": "320703"
                    },
                    {
                        "value": "海州区",
                        "id": "320706"
                    },
                    {
                        "value": "赣榆区",
                        "id": "320707"
                    },
                    {
                        "value": "东海县",
                        "id": "320722"
                    },
                    {
                        "value": "灌云县",
                        "id": "320723"
                    },
                    {
                        "value": "灌南县",
                        "id": "320724"
                    }
                ]
            },
            {
                "value": "淮安市",
                "id": "320800",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "320801"
                    },
                    {
                        "value": "清河区",
                        "id": "320802"
                    },
                    {
                        "value": "淮安区",
                        "id": "320803"
                    },
                    {
                        "value": "淮阴区",
                        "id": "320804"
                    },
                    {
                        "value": "清浦区",
                        "id": "320811"
                    },
                    {
                        "value": "涟水县",
                        "id": "320826"
                    },
                    {
                        "value": "洪泽县",
                        "id": "320829"
                    },
                    {
                        "value": "盱眙县",
                        "id": "320830"
                    },
                    {
                        "value": "金湖县",
                        "id": "320831"
                    }
                ]
            },
            {
                "value": "盐城市",
                "id": "320900",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "320901"
                    },
                    {
                        "value": "亭湖区",
                        "id": "320902"
                    },
                    {
                        "value": "盐都区",
                        "id": "320903"
                    },
                    {
                        "value": "响水县",
                        "id": "320921"
                    },
                    {
                        "value": "滨海县",
                        "id": "320922"
                    },
                    {
                        "value": "阜宁县",
                        "id": "320923"
                    },
                    {
                        "value": "射阳县",
                        "id": "320924"
                    },
                    {
                        "value": "建湖县",
                        "id": "320925"
                    },
                    {
                        "value": "东台市",
                        "id": "320981"
                    },
                    {
                        "value": "大丰市",
                        "id": "320982"
                    }
                ]
            },
            {
                "value": "扬州市",
                "id": "321000",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "321001"
                    },
                    {
                        "value": "广陵区",
                        "id": "321002"
                    },
                    {
                        "value": "邗江区",
                        "id": "321003"
                    },
                    {
                        "value": "江都区",
                        "id": "321012"
                    },
                    {
                        "value": "宝应县",
                        "id": "321023"
                    },
                    {
                        "value": "仪征市",
                        "id": "321081"
                    },
                    {
                        "value": "高邮市",
                        "id": "321084"
                    }
                ]
            },
            {
                "value": "镇江市",
                "id": "321100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "321101"
                    },
                    {
                        "value": "京口区",
                        "id": "321102"
                    },
                    {
                        "value": "润州区",
                        "id": "321111"
                    },
                    {
                        "value": "丹徒区",
                        "id": "321112"
                    },
                    {
                        "value": "丹阳市",
                        "id": "321181"
                    },
                    {
                        "value": "扬中市",
                        "id": "321182"
                    },
                    {
                        "value": "句容市",
                        "id": "321183"
                    }
                ]
            },
            {
                "value": "泰州市",
                "id": "321200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "321201"
                    },
                    {
                        "value": "海陵区",
                        "id": "321202"
                    },
                    {
                        "value": "高港区",
                        "id": "321203"
                    },
                    {
                        "value": "姜堰区",
                        "id": "321204"
                    },
                    {
                        "value": "兴化市",
                        "id": "321281"
                    },
                    {
                        "value": "靖江市",
                        "id": "321282"
                    },
                    {
                        "value": "泰兴市",
                        "id": "321283"
                    }
                ]
            },
            {
                "value": "宿迁市",
                "id": "321300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "321301"
                    },
                    {
                        "value": "宿城区",
                        "id": "321302"
                    },
                    {
                        "value": "宿豫区",
                        "id": "321311"
                    },
                    {
                        "value": "沭阳县",
                        "id": "321322"
                    },
                    {
                        "value": "泗阳县",
                        "id": "321323"
                    },
                    {
                        "value": "泗洪县",
                        "id": "321324"
                    }
                ]
            }
        ]
    },
    {
        "value": "浙江省",
        "id": "330000",
        "childs": [
            {
                "value": "杭州市",
                "id": "330100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "330101"
                    },
                    {
                        "value": "上城区",
                        "id": "330102"
                    },
                    {
                        "value": "下城区",
                        "id": "330103"
                    },
                    {
                        "value": "江干区",
                        "id": "330104"
                    },
                    {
                        "value": "拱墅区",
                        "id": "330105"
                    },
                    {
                        "value": "西湖区",
                        "id": "330106"
                    },
                    {
                        "value": "滨江区",
                        "id": "330108"
                    },
                    {
                        "value": "萧山区",
                        "id": "330109"
                    },
                    {
                        "value": "余杭区",
                        "id": "330110"
                    },
                    {
                        "value": "富阳区",
                        "id": "330111"
                    },
                    {
                        "value": "桐庐县",
                        "id": "330122"
                    },
                    {
                        "value": "淳安县",
                        "id": "330127"
                    },
                    {
                        "value": "建德市",
                        "id": "330182"
                    },
                    {
                        "value": "临安市",
                        "id": "330185"
                    }
                ]
            },
            {
                "value": "宁波市",
                "id": "330200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "330201"
                    },
                    {
                        "value": "海曙区",
                        "id": "330203"
                    },
                    {
                        "value": "江东区",
                        "id": "330204"
                    },
                    {
                        "value": "江北区",
                        "id": "330205"
                    },
                    {
                        "value": "北仑区",
                        "id": "330206"
                    },
                    {
                        "value": "镇海区",
                        "id": "330211"
                    },
                    {
                        "value": "鄞州区",
                        "id": "330212"
                    },
                    {
                        "value": "象山县",
                        "id": "330225"
                    },
                    {
                        "value": "宁海县",
                        "id": "330226"
                    },
                    {
                        "value": "余姚市",
                        "id": "330281"
                    },
                    {
                        "value": "慈溪市",
                        "id": "330282"
                    },
                    {
                        "value": "奉化市",
                        "id": "330283"
                    }
                ]
            },
            {
                "value": "温州市",
                "id": "330300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "330301"
                    },
                    {
                        "value": "鹿城区",
                        "id": "330302"
                    },
                    {
                        "value": "龙湾区",
                        "id": "330303"
                    },
                    {
                        "value": "瓯海区",
                        "id": "330304"
                    },
                    {
                        "value": "洞头县",
                        "id": "330322"
                    },
                    {
                        "value": "永嘉县",
                        "id": "330324"
                    },
                    {
                        "value": "平阳县",
                        "id": "330326"
                    },
                    {
                        "value": "苍南县",
                        "id": "330327"
                    },
                    {
                        "value": "文成县",
                        "id": "330328"
                    },
                    {
                        "value": "泰顺县",
                        "id": "330329"
                    },
                    {
                        "value": "瑞安市",
                        "id": "330381"
                    },
                    {
                        "value": "乐清市",
                        "id": "330382"
                    }
                ]
            },
            {
                "value": "嘉兴市",
                "id": "330400",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "330401"
                    },
                    {
                        "value": "南湖区",
                        "id": "330402"
                    },
                    {
                        "value": "秀洲区",
                        "id": "330411"
                    },
                    {
                        "value": "嘉善县",
                        "id": "330421"
                    },
                    {
                        "value": "海盐县",
                        "id": "330424"
                    },
                    {
                        "value": "海宁市",
                        "id": "330481"
                    },
                    {
                        "value": "平湖市",
                        "id": "330482"
                    },
                    {
                        "value": "桐乡市",
                        "id": "330483"
                    }
                ]
            },
            {
                "value": "湖州市",
                "id": "330500",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "330501"
                    },
                    {
                        "value": "吴兴区",
                        "id": "330502"
                    },
                    {
                        "value": "南浔区",
                        "id": "330503"
                    },
                    {
                        "value": "德清县",
                        "id": "330521"
                    },
                    {
                        "value": "长兴县",
                        "id": "330522"
                    },
                    {
                        "value": "安吉县",
                        "id": "330523"
                    }
                ]
            },
            {
                "value": "绍兴市",
                "id": "330600",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "330601"
                    },
                    {
                        "value": "越城区",
                        "id": "330602"
                    },
                    {
                        "value": "柯桥区",
                        "id": "330603"
                    },
                    {
                        "value": "上虞区",
                        "id": "330604"
                    },
                    {
                        "value": "新昌县",
                        "id": "330624"
                    },
                    {
                        "value": "诸暨市",
                        "id": "330681"
                    },
                    {
                        "value": "嵊州市",
                        "id": "330683"
                    }
                ]
            },
            {
                "value": "金华市",
                "id": "330700",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "330701"
                    },
                    {
                        "value": "婺城区",
                        "id": "330702"
                    },
                    {
                        "value": "金东区",
                        "id": "330703"
                    },
                    {
                        "value": "武义县",
                        "id": "330723"
                    },
                    {
                        "value": "浦江县",
                        "id": "330726"
                    },
                    {
                        "value": "磐安县",
                        "id": "330727"
                    },
                    {
                        "value": "兰溪市",
                        "id": "330781"
                    },
                    {
                        "value": "义乌市",
                        "id": "330782"
                    },
                    {
                        "value": "东阳市",
                        "id": "330783"
                    },
                    {
                        "value": "永康市",
                        "id": "330784"
                    }
                ]
            },
            {
                "value": "衢州市",
                "id": "330800",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "330801"
                    },
                    {
                        "value": "柯城区",
                        "id": "330802"
                    },
                    {
                        "value": "衢江区",
                        "id": "330803"
                    },
                    {
                        "value": "常山县",
                        "id": "330822"
                    },
                    {
                        "value": "开化县",
                        "id": "330824"
                    },
                    {
                        "value": "龙游县",
                        "id": "330825"
                    },
                    {
                        "value": "江山市",
                        "id": "330881"
                    }
                ]
            },
            {
                "value": "舟山市",
                "id": "330900",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "330901"
                    },
                    {
                        "value": "定海区",
                        "id": "330902"
                    },
                    {
                        "value": "普陀区",
                        "id": "330903"
                    },
                    {
                        "value": "岱山县",
                        "id": "330921"
                    },
                    {
                        "value": "嵊泗县",
                        "id": "330922"
                    }
                ]
            },
            {
                "value": "台州市",
                "id": "331000",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "331001"
                    },
                    {
                        "value": "椒江区",
                        "id": "331002"
                    },
                    {
                        "value": "黄岩区",
                        "id": "331003"
                    },
                    {
                        "value": "路桥区",
                        "id": "331004"
                    },
                    {
                        "value": "玉环县",
                        "id": "331021"
                    },
                    {
                        "value": "三门县",
                        "id": "331022"
                    },
                    {
                        "value": "天台县",
                        "id": "331023"
                    },
                    {
                        "value": "仙居县",
                        "id": "331024"
                    },
                    {
                        "value": "温岭市",
                        "id": "331081"
                    },
                    {
                        "value": "临海市",
                        "id": "331082"
                    }
                ]
            },
            {
                "value": "丽水市",
                "id": "331100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "331101"
                    },
                    {
                        "value": "莲都区",
                        "id": "331102"
                    },
                    {
                        "value": "青田县",
                        "id": "331121"
                    },
                    {
                        "value": "缙云县",
                        "id": "331122"
                    },
                    {
                        "value": "遂昌县",
                        "id": "331123"
                    },
                    {
                        "value": "松阳县",
                        "id": "331124"
                    },
                    {
                        "value": "云和县",
                        "id": "331125"
                    },
                    {
                        "value": "庆元县",
                        "id": "331126"
                    },
                    {
                        "value": "景宁畲族自治县",
                        "id": "331127"
                    },
                    {
                        "value": "龙泉市",
                        "id": "331181"
                    }
                ]
            }
        ]
    },
    {
        "value": "安徽省",
        "id": "340000",
        "childs": [
            {
                "value": "合肥市",
                "id": "340100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "340101"
                    },
                    {
                        "value": "瑶海区",
                        "id": "340102"
                    },
                    {
                        "value": "庐阳区",
                        "id": "340103"
                    },
                    {
                        "value": "蜀山区",
                        "id": "340104"
                    },
                    {
                        "value": "包河区",
                        "id": "340111"
                    },
                    {
                        "value": "长丰县",
                        "id": "340121"
                    },
                    {
                        "value": "肥东县",
                        "id": "340122"
                    },
                    {
                        "value": "肥西县",
                        "id": "340123"
                    },
                    {
                        "value": "庐江县",
                        "id": "340124"
                    },
                    {
                        "value": "巢湖市",
                        "id": "340181"
                    }
                ]
            },
            {
                "value": "芜湖市",
                "id": "340200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "340201"
                    },
                    {
                        "value": "镜湖区",
                        "id": "340202"
                    },
                    {
                        "value": "弋江区",
                        "id": "340203"
                    },
                    {
                        "value": "鸠江区",
                        "id": "340207"
                    },
                    {
                        "value": "三山区",
                        "id": "340208"
                    },
                    {
                        "value": "芜湖县",
                        "id": "340221"
                    },
                    {
                        "value": "繁昌县",
                        "id": "340222"
                    },
                    {
                        "value": "南陵县",
                        "id": "340223"
                    },
                    {
                        "value": "无为县",
                        "id": "340225"
                    }
                ]
            },
            {
                "value": "蚌埠市",
                "id": "340300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "340301"
                    },
                    {
                        "value": "龙子湖区",
                        "id": "340302"
                    },
                    {
                        "value": "蚌山区",
                        "id": "340303"
                    },
                    {
                        "value": "禹会区",
                        "id": "340304"
                    },
                    {
                        "value": "淮上区",
                        "id": "340311"
                    },
                    {
                        "value": "怀远县",
                        "id": "340321"
                    },
                    {
                        "value": "五河县",
                        "id": "340322"
                    },
                    {
                        "value": "固镇县",
                        "id": "340323"
                    }
                ]
            },
            {
                "value": "淮南市",
                "id": "340400",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "340401"
                    },
                    {
                        "value": "大通区",
                        "id": "340402"
                    },
                    {
                        "value": "田家庵区",
                        "id": "340403"
                    },
                    {
                        "value": "谢家集区",
                        "id": "340404"
                    },
                    {
                        "value": "八公山区",
                        "id": "340405"
                    },
                    {
                        "value": "潘集区",
                        "id": "340406"
                    },
                    {
                        "value": "凤台县",
                        "id": "340421"
                    }
                ]
            },
            {
                "value": "马鞍山市",
                "id": "340500",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "340501"
                    },
                    {
                        "value": "花山区",
                        "id": "340503"
                    },
                    {
                        "value": "雨山区",
                        "id": "340504"
                    },
                    {
                        "value": "博望区",
                        "id": "340506"
                    },
                    {
                        "value": "当涂县",
                        "id": "340521"
                    },
                    {
                        "value": "含山县",
                        "id": "340522"
                    },
                    {
                        "value": "和县",
                        "id": "340523"
                    }
                ]
            },
            {
                "value": "淮北市",
                "id": "340600",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "340601"
                    },
                    {
                        "value": "杜集区",
                        "id": "340602"
                    },
                    {
                        "value": "相山区",
                        "id": "340603"
                    },
                    {
                        "value": "烈山区",
                        "id": "340604"
                    },
                    {
                        "value": "濉溪县",
                        "id": "340621"
                    }
                ]
            },
            {
                "value": "铜陵市",
                "id": "340700",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "340701"
                    },
                    {
                        "value": "铜官山区",
                        "id": "340702"
                    },
                    {
                        "value": "狮子山区",
                        "id": "340703"
                    },
                    {
                        "value": "郊区",
                        "id": "340711"
                    },
                    {
                        "value": "铜陵县",
                        "id": "340721"
                    }
                ]
            },
            {
                "value": "安庆市",
                "id": "340800",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "340801"
                    },
                    {
                        "value": "迎江区",
                        "id": "340802"
                    },
                    {
                        "value": "大观区",
                        "id": "340803"
                    },
                    {
                        "value": "宜秀区",
                        "id": "340811"
                    },
                    {
                        "value": "怀宁县",
                        "id": "340822"
                    },
                    {
                        "value": "枞阳县",
                        "id": "340823"
                    },
                    {
                        "value": "潜山县",
                        "id": "340824"
                    },
                    {
                        "value": "太湖县",
                        "id": "340825"
                    },
                    {
                        "value": "宿松县",
                        "id": "340826"
                    },
                    {
                        "value": "望江县",
                        "id": "340827"
                    },
                    {
                        "value": "岳西县",
                        "id": "340828"
                    },
                    {
                        "value": "桐城市",
                        "id": "340881"
                    }
                ]
            },
            {
                "value": "黄山市",
                "id": "341000",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "341001"
                    },
                    {
                        "value": "屯溪区",
                        "id": "341002"
                    },
                    {
                        "value": "黄山区",
                        "id": "341003"
                    },
                    {
                        "value": "徽州区",
                        "id": "341004"
                    },
                    {
                        "value": "歙县",
                        "id": "341021"
                    },
                    {
                        "value": "休宁县",
                        "id": "341022"
                    },
                    {
                        "value": "黟县",
                        "id": "341023"
                    },
                    {
                        "value": "祁门县",
                        "id": "341024"
                    }
                ]
            },
            {
                "value": "滁州市",
                "id": "341100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "341101"
                    },
                    {
                        "value": "琅琊区",
                        "id": "341102"
                    },
                    {
                        "value": "南谯区",
                        "id": "341103"
                    },
                    {
                        "value": "来安县",
                        "id": "341122"
                    },
                    {
                        "value": "全椒县",
                        "id": "341124"
                    },
                    {
                        "value": "定远县",
                        "id": "341125"
                    },
                    {
                        "value": "凤阳县",
                        "id": "341126"
                    },
                    {
                        "value": "天长市",
                        "id": "341181"
                    },
                    {
                        "value": "明光市",
                        "id": "341182"
                    }
                ]
            },
            {
                "value": "阜阳市",
                "id": "341200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "341201"
                    },
                    {
                        "value": "颍州区",
                        "id": "341202"
                    },
                    {
                        "value": "颍东区",
                        "id": "341203"
                    },
                    {
                        "value": "颍泉区",
                        "id": "341204"
                    },
                    {
                        "value": "临泉县",
                        "id": "341221"
                    },
                    {
                        "value": "太和县",
                        "id": "341222"
                    },
                    {
                        "value": "阜南县",
                        "id": "341225"
                    },
                    {
                        "value": "颍上县",
                        "id": "341226"
                    },
                    {
                        "value": "界首市",
                        "id": "341282"
                    }
                ]
            },
            {
                "value": "宿州市",
                "id": "341300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "341301"
                    },
                    {
                        "value": "埇桥区",
                        "id": "341302"
                    },
                    {
                        "value": "砀山县",
                        "id": "341321"
                    },
                    {
                        "value": "萧县",
                        "id": "341322"
                    },
                    {
                        "value": "灵璧县",
                        "id": "341323"
                    },
                    {
                        "value": "泗县",
                        "id": "341324"
                    }
                ]
            },
            {
                "value": "六安市",
                "id": "341500",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "341501"
                    },
                    {
                        "value": "金安区",
                        "id": "341502"
                    },
                    {
                        "value": "裕安区",
                        "id": "341503"
                    },
                    {
                        "value": "寿县",
                        "id": "341521"
                    },
                    {
                        "value": "霍邱县",
                        "id": "341522"
                    },
                    {
                        "value": "舒城县",
                        "id": "341523"
                    },
                    {
                        "value": "金寨县",
                        "id": "341524"
                    },
                    {
                        "value": "霍山县",
                        "id": "341525"
                    }
                ]
            },
            {
                "value": "亳州市",
                "id": "341600",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "341601"
                    },
                    {
                        "value": "谯城区",
                        "id": "341602"
                    },
                    {
                        "value": "涡阳县",
                        "id": "341621"
                    },
                    {
                        "value": "蒙城县",
                        "id": "341622"
                    },
                    {
                        "value": "利辛县",
                        "id": "341623"
                    }
                ]
            },
            {
                "value": "池州市",
                "id": "341700",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "341701"
                    },
                    {
                        "value": "贵池区",
                        "id": "341702"
                    },
                    {
                        "value": "东至县",
                        "id": "341721"
                    },
                    {
                        "value": "石台县",
                        "id": "341722"
                    },
                    {
                        "value": "青阳县",
                        "id": "341723"
                    }
                ]
            },
            {
                "value": "宣城市",
                "id": "341800",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "341801"
                    },
                    {
                        "value": "宣州区",
                        "id": "341802"
                    },
                    {
                        "value": "郎溪县",
                        "id": "341821"
                    },
                    {
                        "value": "广德县",
                        "id": "341822"
                    },
                    {
                        "value": "泾县",
                        "id": "341823"
                    },
                    {
                        "value": "绩溪县",
                        "id": "341824"
                    },
                    {
                        "value": "旌德县",
                        "id": "341825"
                    },
                    {
                        "value": "宁国市",
                        "id": "341881"
                    }
                ]
            }
        ]
    },
    {
        "value": "福建省",
        "id": "350000",
        "childs": [
            {
                "value": "福州市",
                "id": "350100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "350101"
                    },
                    {
                        "value": "鼓楼区",
                        "id": "350102"
                    },
                    {
                        "value": "台江区",
                        "id": "350103"
                    },
                    {
                        "value": "仓山区",
                        "id": "350104"
                    },
                    {
                        "value": "马尾区",
                        "id": "350105"
                    },
                    {
                        "value": "晋安区",
                        "id": "350111"
                    },
                    {
                        "value": "闽侯县",
                        "id": "350121"
                    },
                    {
                        "value": "连江县",
                        "id": "350122"
                    },
                    {
                        "value": "罗源县",
                        "id": "350123"
                    },
                    {
                        "value": "闽清县",
                        "id": "350124"
                    },
                    {
                        "value": "永泰县",
                        "id": "350125"
                    },
                    {
                        "value": "平潭县",
                        "id": "350128"
                    },
                    {
                        "value": "福清市",
                        "id": "350181"
                    },
                    {
                        "value": "长乐市",
                        "id": "350182"
                    }
                ]
            },
            {
                "value": "厦门市",
                "id": "350200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "350201"
                    },
                    {
                        "value": "思明区",
                        "id": "350203"
                    },
                    {
                        "value": "海沧区",
                        "id": "350205"
                    },
                    {
                        "value": "湖里区",
                        "id": "350206"
                    },
                    {
                        "value": "集美区",
                        "id": "350211"
                    },
                    {
                        "value": "同安区",
                        "id": "350212"
                    },
                    {
                        "value": "翔安区",
                        "id": "350213"
                    }
                ]
            },
            {
                "value": "莆田市",
                "id": "350300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "350301"
                    },
                    {
                        "value": "城厢区",
                        "id": "350302"
                    },
                    {
                        "value": "涵江区",
                        "id": "350303"
                    },
                    {
                        "value": "荔城区",
                        "id": "350304"
                    },
                    {
                        "value": "秀屿区",
                        "id": "350305"
                    },
                    {
                        "value": "仙游县",
                        "id": "350322"
                    }
                ]
            },
            {
                "value": "三明市",
                "id": "350400",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "350401"
                    },
                    {
                        "value": "梅列区",
                        "id": "350402"
                    },
                    {
                        "value": "三元区",
                        "id": "350403"
                    },
                    {
                        "value": "明溪县",
                        "id": "350421"
                    },
                    {
                        "value": "清流县",
                        "id": "350423"
                    },
                    {
                        "value": "宁化县",
                        "id": "350424"
                    },
                    {
                        "value": "大田县",
                        "id": "350425"
                    },
                    {
                        "value": "尤溪县",
                        "id": "350426"
                    },
                    {
                        "value": "沙县",
                        "id": "350427"
                    },
                    {
                        "value": "将乐县",
                        "id": "350428"
                    },
                    {
                        "value": "泰宁县",
                        "id": "350429"
                    },
                    {
                        "value": "建宁县",
                        "id": "350430"
                    },
                    {
                        "value": "永安市",
                        "id": "350481"
                    }
                ]
            },
            {
                "value": "泉州市",
                "id": "350500",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "350501"
                    },
                    {
                        "value": "鲤城区",
                        "id": "350502"
                    },
                    {
                        "value": "丰泽区",
                        "id": "350503"
                    },
                    {
                        "value": "洛江区",
                        "id": "350504"
                    },
                    {
                        "value": "泉港区",
                        "id": "350505"
                    },
                    {
                        "value": "惠安县",
                        "id": "350521"
                    },
                    {
                        "value": "安溪县",
                        "id": "350524"
                    },
                    {
                        "value": "永春县",
                        "id": "350525"
                    },
                    {
                        "value": "德化县",
                        "id": "350526"
                    },
                    {
                        "value": "金门县",
                        "id": "350527"
                    },
                    {
                        "value": "石狮市",
                        "id": "350581"
                    },
                    {
                        "value": "晋江市",
                        "id": "350582"
                    },
                    {
                        "value": "南安市",
                        "id": "350583"
                    }
                ]
            },
            {
                "value": "漳州市",
                "id": "350600",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "350601"
                    },
                    {
                        "value": "芗城区",
                        "id": "350602"
                    },
                    {
                        "value": "龙文区",
                        "id": "350603"
                    },
                    {
                        "value": "云霄县",
                        "id": "350622"
                    },
                    {
                        "value": "漳浦县",
                        "id": "350623"
                    },
                    {
                        "value": "诏安县",
                        "id": "350624"
                    },
                    {
                        "value": "长泰县",
                        "id": "350625"
                    },
                    {
                        "value": "东山县",
                        "id": "350626"
                    },
                    {
                        "value": "南靖县",
                        "id": "350627"
                    },
                    {
                        "value": "平和县",
                        "id": "350628"
                    },
                    {
                        "value": "华安县",
                        "id": "350629"
                    },
                    {
                        "value": "龙海市",
                        "id": "350681"
                    }
                ]
            },
            {
                "value": "南平市",
                "id": "350700",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "350701"
                    },
                    {
                        "value": "延平区",
                        "id": "350702"
                    },
                    {
                        "value": "建阳区",
                        "id": "350703"
                    },
                    {
                        "value": "顺昌县",
                        "id": "350721"
                    },
                    {
                        "value": "浦城县",
                        "id": "350722"
                    },
                    {
                        "value": "光泽县",
                        "id": "350723"
                    },
                    {
                        "value": "松溪县",
                        "id": "350724"
                    },
                    {
                        "value": "政和县",
                        "id": "350725"
                    },
                    {
                        "value": "邵武市",
                        "id": "350781"
                    },
                    {
                        "value": "武夷山市",
                        "id": "350782"
                    },
                    {
                        "value": "建瓯市",
                        "id": "350783"
                    }
                ]
            },
            {
                "value": "龙岩市",
                "id": "350800",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "350801"
                    },
                    {
                        "value": "新罗区",
                        "id": "350802"
                    },
                    {
                        "value": "永定区",
                        "id": "350803"
                    },
                    {
                        "value": "长汀县",
                        "id": "350821"
                    },
                    {
                        "value": "上杭县",
                        "id": "350823"
                    },
                    {
                        "value": "武平县",
                        "id": "350824"
                    },
                    {
                        "value": "连城县",
                        "id": "350825"
                    },
                    {
                        "value": "漳平市",
                        "id": "350881"
                    }
                ]
            },
            {
                "value": "宁德市",
                "id": "350900",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "350901"
                    },
                    {
                        "value": "蕉城区",
                        "id": "350902"
                    },
                    {
                        "value": "霞浦县",
                        "id": "350921"
                    },
                    {
                        "value": "古田县",
                        "id": "350922"
                    },
                    {
                        "value": "屏南县",
                        "id": "350923"
                    },
                    {
                        "value": "寿宁县",
                        "id": "350924"
                    },
                    {
                        "value": "周宁县",
                        "id": "350925"
                    },
                    {
                        "value": "柘荣县",
                        "id": "350926"
                    },
                    {
                        "value": "福安市",
                        "id": "350981"
                    },
                    {
                        "value": "福鼎市",
                        "id": "350982"
                    }
                ]
            }
        ]
    },
    {
        "value": "江西省",
        "id": "360000",
        "childs": [
            {
                "value": "南昌市",
                "id": "360100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "360101"
                    },
                    {
                        "value": "东湖区",
                        "id": "360102"
                    },
                    {
                        "value": "西湖区",
                        "id": "360103"
                    },
                    {
                        "value": "青云谱区",
                        "id": "360104"
                    },
                    {
                        "value": "湾里区",
                        "id": "360105"
                    },
                    {
                        "value": "青山湖区",
                        "id": "360111"
                    },
                    {
                        "value": "南昌县",
                        "id": "360121"
                    },
                    {
                        "value": "新建县",
                        "id": "360122"
                    },
                    {
                        "value": "安义县",
                        "id": "360123"
                    },
                    {
                        "value": "进贤县",
                        "id": "360124"
                    }
                ]
            },
            {
                "value": "景德镇市",
                "id": "360200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "360201"
                    },
                    {
                        "value": "昌江区",
                        "id": "360202"
                    },
                    {
                        "value": "珠山区",
                        "id": "360203"
                    },
                    {
                        "value": "浮梁县",
                        "id": "360222"
                    },
                    {
                        "value": "乐平市",
                        "id": "360281"
                    }
                ]
            },
            {
                "value": "萍乡市",
                "id": "360300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "360301"
                    },
                    {
                        "value": "安源区",
                        "id": "360302"
                    },
                    {
                        "value": "湘东区",
                        "id": "360313"
                    },
                    {
                        "value": "莲花县",
                        "id": "360321"
                    },
                    {
                        "value": "上栗县",
                        "id": "360322"
                    },
                    {
                        "value": "芦溪县",
                        "id": "360323"
                    }
                ]
            },
            {
                "value": "九江市",
                "id": "360400",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "360401"
                    },
                    {
                        "value": "庐山区",
                        "id": "360402"
                    },
                    {
                        "value": "浔阳区",
                        "id": "360403"
                    },
                    {
                        "value": "九江县",
                        "id": "360421"
                    },
                    {
                        "value": "武宁县",
                        "id": "360423"
                    },
                    {
                        "value": "修水县",
                        "id": "360424"
                    },
                    {
                        "value": "永修县",
                        "id": "360425"
                    },
                    {
                        "value": "德安县",
                        "id": "360426"
                    },
                    {
                        "value": "星子县",
                        "id": "360427"
                    },
                    {
                        "value": "都昌县",
                        "id": "360428"
                    },
                    {
                        "value": "湖口县",
                        "id": "360429"
                    },
                    {
                        "value": "彭泽县",
                        "id": "360430"
                    },
                    {
                        "value": "瑞昌市",
                        "id": "360481"
                    },
                    {
                        "value": "共青城市",
                        "id": "360482"
                    }
                ]
            },
            {
                "value": "新余市",
                "id": "360500",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "360501"
                    },
                    {
                        "value": "渝水区",
                        "id": "360502"
                    },
                    {
                        "value": "分宜县",
                        "id": "360521"
                    }
                ]
            },
            {
                "value": "鹰潭市",
                "id": "360600",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "360601"
                    },
                    {
                        "value": "月湖区",
                        "id": "360602"
                    },
                    {
                        "value": "余江县",
                        "id": "360622"
                    },
                    {
                        "value": "贵溪市",
                        "id": "360681"
                    }
                ]
            },
            {
                "value": "赣州市",
                "id": "360700",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "360701"
                    },
                    {
                        "value": "章贡区",
                        "id": "360702"
                    },
                    {
                        "value": "南康区",
                        "id": "360703"
                    },
                    {
                        "value": "赣县",
                        "id": "360721"
                    },
                    {
                        "value": "信丰县",
                        "id": "360722"
                    },
                    {
                        "value": "大余县",
                        "id": "360723"
                    },
                    {
                        "value": "上犹县",
                        "id": "360724"
                    },
                    {
                        "value": "崇义县",
                        "id": "360725"
                    },
                    {
                        "value": "安远县",
                        "id": "360726"
                    },
                    {
                        "value": "龙南县",
                        "id": "360727"
                    },
                    {
                        "value": "定南县",
                        "id": "360728"
                    },
                    {
                        "value": "全南县",
                        "id": "360729"
                    },
                    {
                        "value": "宁都县",
                        "id": "360730"
                    },
                    {
                        "value": "于都县",
                        "id": "360731"
                    },
                    {
                        "value": "兴国县",
                        "id": "360732"
                    },
                    {
                        "value": "会昌县",
                        "id": "360733"
                    },
                    {
                        "value": "寻乌县",
                        "id": "360734"
                    },
                    {
                        "value": "石城县",
                        "id": "360735"
                    },
                    {
                        "value": "瑞金市",
                        "id": "360781"
                    }
                ]
            },
            {
                "value": "吉安市",
                "id": "360800",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "360801"
                    },
                    {
                        "value": "吉州区",
                        "id": "360802"
                    },
                    {
                        "value": "青原区",
                        "id": "360803"
                    },
                    {
                        "value": "吉安县",
                        "id": "360821"
                    },
                    {
                        "value": "吉水县",
                        "id": "360822"
                    },
                    {
                        "value": "峡江县",
                        "id": "360823"
                    },
                    {
                        "value": "新干县",
                        "id": "360824"
                    },
                    {
                        "value": "永丰县",
                        "id": "360825"
                    },
                    {
                        "value": "泰和县",
                        "id": "360826"
                    },
                    {
                        "value": "遂川县",
                        "id": "360827"
                    },
                    {
                        "value": "万安县",
                        "id": "360828"
                    },
                    {
                        "value": "安福县",
                        "id": "360829"
                    },
                    {
                        "value": "永新县",
                        "id": "360830"
                    },
                    {
                        "value": "井冈山市",
                        "id": "360881"
                    }
                ]
            },
            {
                "value": "宜春市",
                "id": "360900",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "360901"
                    },
                    {
                        "value": "袁州区",
                        "id": "360902"
                    },
                    {
                        "value": "奉新县",
                        "id": "360921"
                    },
                    {
                        "value": "万载县",
                        "id": "360922"
                    },
                    {
                        "value": "上高县",
                        "id": "360923"
                    },
                    {
                        "value": "宜丰县",
                        "id": "360924"
                    },
                    {
                        "value": "靖安县",
                        "id": "360925"
                    },
                    {
                        "value": "铜鼓县",
                        "id": "360926"
                    },
                    {
                        "value": "丰城市",
                        "id": "360981"
                    },
                    {
                        "value": "樟树市",
                        "id": "360982"
                    },
                    {
                        "value": "高安市",
                        "id": "360983"
                    }
                ]
            },
            {
                "value": "抚州市",
                "id": "361000",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "361001"
                    },
                    {
                        "value": "临川区",
                        "id": "361002"
                    },
                    {
                        "value": "南城县",
                        "id": "361021"
                    },
                    {
                        "value": "黎川县",
                        "id": "361022"
                    },
                    {
                        "value": "南丰县",
                        "id": "361023"
                    },
                    {
                        "value": "崇仁县",
                        "id": "361024"
                    },
                    {
                        "value": "乐安县",
                        "id": "361025"
                    },
                    {
                        "value": "宜黄县",
                        "id": "361026"
                    },
                    {
                        "value": "金溪县",
                        "id": "361027"
                    },
                    {
                        "value": "资溪县",
                        "id": "361028"
                    },
                    {
                        "value": "东乡县",
                        "id": "361029"
                    },
                    {
                        "value": "广昌县",
                        "id": "361030"
                    }
                ]
            },
            {
                "value": "上饶市",
                "id": "361100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "361101"
                    },
                    {
                        "value": "信州区",
                        "id": "361102"
                    },
                    {
                        "value": "上饶县",
                        "id": "361121"
                    },
                    {
                        "value": "广丰县",
                        "id": "361122"
                    },
                    {
                        "value": "玉山县",
                        "id": "361123"
                    },
                    {
                        "value": "铅山县",
                        "id": "361124"
                    },
                    {
                        "value": "横峰县",
                        "id": "361125"
                    },
                    {
                        "value": "弋阳县",
                        "id": "361126"
                    },
                    {
                        "value": "余干县",
                        "id": "361127"
                    },
                    {
                        "value": "鄱阳县",
                        "id": "361128"
                    },
                    {
                        "value": "万年县",
                        "id": "361129"
                    },
                    {
                        "value": "婺源县",
                        "id": "361130"
                    },
                    {
                        "value": "德兴市",
                        "id": "361181"
                    }
                ]
            }
        ]
    },
    {
        "value": "山东省",
        "id": "370000",
        "childs": [
            {
                "value": "济南市",
                "id": "370100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "370101"
                    },
                    {
                        "value": "历下区",
                        "id": "370102"
                    },
                    {
                        "value": "市中区",
                        "id": "370103"
                    },
                    {
                        "value": "槐荫区",
                        "id": "370104"
                    },
                    {
                        "value": "天桥区",
                        "id": "370105"
                    },
                    {
                        "value": "历城区",
                        "id": "370112"
                    },
                    {
                        "value": "长清区",
                        "id": "370113"
                    },
                    {
                        "value": "平阴县",
                        "id": "370124"
                    },
                    {
                        "value": "济阳县",
                        "id": "370125"
                    },
                    {
                        "value": "商河县",
                        "id": "370126"
                    },
                    {
                        "value": "章丘市",
                        "id": "370181"
                    }
                ]
            },
            {
                "value": "青岛市",
                "id": "370200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "370201"
                    },
                    {
                        "value": "市南区",
                        "id": "370202"
                    },
                    {
                        "value": "市北区",
                        "id": "370203"
                    },
                    {
                        "value": "黄岛区",
                        "id": "370211"
                    },
                    {
                        "value": "崂山区",
                        "id": "370212"
                    },
                    {
                        "value": "李沧区",
                        "id": "370213"
                    },
                    {
                        "value": "城阳区",
                        "id": "370214"
                    },
                    {
                        "value": "胶州市",
                        "id": "370281"
                    },
                    {
                        "value": "即墨市",
                        "id": "370282"
                    },
                    {
                        "value": "平度市",
                        "id": "370283"
                    },
                    {
                        "value": "莱西市",
                        "id": "370285"
                    }
                ]
            },
            {
                "value": "淄博市",
                "id": "370300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "370301"
                    },
                    {
                        "value": "淄川区",
                        "id": "370302"
                    },
                    {
                        "value": "张店区",
                        "id": "370303"
                    },
                    {
                        "value": "博山区",
                        "id": "370304"
                    },
                    {
                        "value": "临淄区",
                        "id": "370305"
                    },
                    {
                        "value": "周村区",
                        "id": "370306"
                    },
                    {
                        "value": "桓台县",
                        "id": "370321"
                    },
                    {
                        "value": "高青县",
                        "id": "370322"
                    },
                    {
                        "value": "沂源县",
                        "id": "370323"
                    }
                ]
            },
            {
                "value": "枣庄市",
                "id": "370400",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "370401"
                    },
                    {
                        "value": "市中区",
                        "id": "370402"
                    },
                    {
                        "value": "薛城区",
                        "id": "370403"
                    },
                    {
                        "value": "峄城区",
                        "id": "370404"
                    },
                    {
                        "value": "台儿庄区",
                        "id": "370405"
                    },
                    {
                        "value": "山亭区",
                        "id": "370406"
                    },
                    {
                        "value": "滕州市",
                        "id": "370481"
                    }
                ]
            },
            {
                "value": "东营市",
                "id": "370500",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "370501"
                    },
                    {
                        "value": "东营区",
                        "id": "370502"
                    },
                    {
                        "value": "河口区",
                        "id": "370503"
                    },
                    {
                        "value": "垦利县",
                        "id": "370521"
                    },
                    {
                        "value": "利津县",
                        "id": "370522"
                    },
                    {
                        "value": "广饶县",
                        "id": "370523"
                    }
                ]
            },
            {
                "value": "烟台市",
                "id": "370600",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "370601"
                    },
                    {
                        "value": "芝罘区",
                        "id": "370602"
                    },
                    {
                        "value": "福山区",
                        "id": "370611"
                    },
                    {
                        "value": "牟平区",
                        "id": "370612"
                    },
                    {
                        "value": "莱山区",
                        "id": "370613"
                    },
                    {
                        "value": "长岛县",
                        "id": "370634"
                    },
                    {
                        "value": "龙口市",
                        "id": "370681"
                    },
                    {
                        "value": "莱阳市",
                        "id": "370682"
                    },
                    {
                        "value": "莱州市",
                        "id": "370683"
                    },
                    {
                        "value": "蓬莱市",
                        "id": "370684"
                    },
                    {
                        "value": "招远市",
                        "id": "370685"
                    },
                    {
                        "value": "栖霞市",
                        "id": "370686"
                    },
                    {
                        "value": "海阳市",
                        "id": "370687"
                    }
                ]
            },
            {
                "value": "潍坊市",
                "id": "370700",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "370701"
                    },
                    {
                        "value": "潍城区",
                        "id": "370702"
                    },
                    {
                        "value": "寒亭区",
                        "id": "370703"
                    },
                    {
                        "value": "坊子区",
                        "id": "370704"
                    },
                    {
                        "value": "奎文区",
                        "id": "370705"
                    },
                    {
                        "value": "临朐县",
                        "id": "370724"
                    },
                    {
                        "value": "昌乐县",
                        "id": "370725"
                    },
                    {
                        "value": "青州市",
                        "id": "370781"
                    },
                    {
                        "value": "诸城市",
                        "id": "370782"
                    },
                    {
                        "value": "寿光市",
                        "id": "370783"
                    },
                    {
                        "value": "安丘市",
                        "id": "370784"
                    },
                    {
                        "value": "高密市",
                        "id": "370785"
                    },
                    {
                        "value": "昌邑市",
                        "id": "370786"
                    }
                ]
            },
            {
                "value": "济宁市",
                "id": "370800",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "370801"
                    },
                    {
                        "value": "任城区",
                        "id": "370811"
                    },
                    {
                        "value": "兖州区",
                        "id": "370812"
                    },
                    {
                        "value": "微山县",
                        "id": "370826"
                    },
                    {
                        "value": "鱼台县",
                        "id": "370827"
                    },
                    {
                        "value": "金乡县",
                        "id": "370828"
                    },
                    {
                        "value": "嘉祥县",
                        "id": "370829"
                    },
                    {
                        "value": "汶上县",
                        "id": "370830"
                    },
                    {
                        "value": "泗水县",
                        "id": "370831"
                    },
                    {
                        "value": "梁山县",
                        "id": "370832"
                    },
                    {
                        "value": "曲阜市",
                        "id": "370881"
                    },
                    {
                        "value": "邹城市",
                        "id": "370883"
                    }
                ]
            },
            {
                "value": "泰安市",
                "id": "370900",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "370901"
                    },
                    {
                        "value": "泰山区",
                        "id": "370902"
                    },
                    {
                        "value": "岱岳区",
                        "id": "370911"
                    },
                    {
                        "value": "宁阳县",
                        "id": "370921"
                    },
                    {
                        "value": "东平县",
                        "id": "370923"
                    },
                    {
                        "value": "新泰市",
                        "id": "370982"
                    },
                    {
                        "value": "肥城市",
                        "id": "370983"
                    }
                ]
            },
            {
                "value": "威海市",
                "id": "371000",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "371001"
                    },
                    {
                        "value": "环翠区",
                        "id": "371002"
                    },
                    {
                        "value": "文登市",
                        "id": "371081"
                    },
                    {
                        "value": "荣成市",
                        "id": "371082"
                    },
                    {
                        "value": "乳山市",
                        "id": "371083"
                    }
                ]
            },
            {
                "value": "日照市",
                "id": "371100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "371101"
                    },
                    {
                        "value": "东港区",
                        "id": "371102"
                    },
                    {
                        "value": "岚山区",
                        "id": "371103"
                    },
                    {
                        "value": "五莲县",
                        "id": "371121"
                    },
                    {
                        "value": "莒县",
                        "id": "371122"
                    }
                ]
            },
            {
                "value": "莱芜市",
                "id": "371200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "371201"
                    },
                    {
                        "value": "莱城区",
                        "id": "371202"
                    },
                    {
                        "value": "钢城区",
                        "id": "371203"
                    }
                ]
            },
            {
                "value": "临沂市",
                "id": "371300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "371301"
                    },
                    {
                        "value": "兰山区",
                        "id": "371302"
                    },
                    {
                        "value": "罗庄区",
                        "id": "371311"
                    },
                    {
                        "value": "河东区",
                        "id": "371312"
                    },
                    {
                        "value": "沂南县",
                        "id": "371321"
                    },
                    {
                        "value": "郯城县",
                        "id": "371322"
                    },
                    {
                        "value": "沂水县",
                        "id": "371323"
                    },
                    {
                        "value": "兰陵县",
                        "id": "371324"
                    },
                    {
                        "value": "费县",
                        "id": "371325"
                    },
                    {
                        "value": "平邑县",
                        "id": "371326"
                    },
                    {
                        "value": "莒南县",
                        "id": "371327"
                    },
                    {
                        "value": "蒙阴县",
                        "id": "371328"
                    },
                    {
                        "value": "临沭县",
                        "id": "371329"
                    }
                ]
            },
            {
                "value": "德州市",
                "id": "371400",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "371401"
                    },
                    {
                        "value": "德城区",
                        "id": "371402"
                    },
                    {
                        "value": "陵城区",
                        "id": "371403"
                    },
                    {
                        "value": "宁津县",
                        "id": "371422"
                    },
                    {
                        "value": "庆云县",
                        "id": "371423"
                    },
                    {
                        "value": "临邑县",
                        "id": "371424"
                    },
                    {
                        "value": "齐河县",
                        "id": "371425"
                    },
                    {
                        "value": "平原县",
                        "id": "371426"
                    },
                    {
                        "value": "夏津县",
                        "id": "371427"
                    },
                    {
                        "value": "武城县",
                        "id": "371428"
                    },
                    {
                        "value": "乐陵市",
                        "id": "371481"
                    },
                    {
                        "value": "禹城市",
                        "id": "371482"
                    }
                ]
            },
            {
                "value": "聊城市",
                "id": "371500",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "371501"
                    },
                    {
                        "value": "东昌府区",
                        "id": "371502"
                    },
                    {
                        "value": "阳谷县",
                        "id": "371521"
                    },
                    {
                        "value": "莘县",
                        "id": "371522"
                    },
                    {
                        "value": "茌平县",
                        "id": "371523"
                    },
                    {
                        "value": "东阿县",
                        "id": "371524"
                    },
                    {
                        "value": "冠县",
                        "id": "371525"
                    },
                    {
                        "value": "高唐县",
                        "id": "371526"
                    },
                    {
                        "value": "临清市",
                        "id": "371581"
                    }
                ]
            },
            {
                "value": "滨州市",
                "id": "371600",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "371601"
                    },
                    {
                        "value": "滨城区",
                        "id": "371602"
                    },
                    {
                        "value": "沾化区",
                        "id": "371603"
                    },
                    {
                        "value": "惠民县",
                        "id": "371621"
                    },
                    {
                        "value": "阳信县",
                        "id": "371622"
                    },
                    {
                        "value": "无棣县",
                        "id": "371623"
                    },
                    {
                        "value": "博兴县",
                        "id": "371625"
                    },
                    {
                        "value": "邹平县",
                        "id": "371626"
                    }
                ]
            },
            {
                "value": "菏泽市",
                "id": "371700",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "371701"
                    },
                    {
                        "value": "牡丹区",
                        "id": "371702"
                    },
                    {
                        "value": "曹县",
                        "id": "371721"
                    },
                    {
                        "value": "单县",
                        "id": "371722"
                    },
                    {
                        "value": "成武县",
                        "id": "371723"
                    },
                    {
                        "value": "巨野县",
                        "id": "371724"
                    },
                    {
                        "value": "郓城县",
                        "id": "371725"
                    },
                    {
                        "value": "鄄城县",
                        "id": "371726"
                    },
                    {
                        "value": "定陶县",
                        "id": "371727"
                    },
                    {
                        "value": "东明县",
                        "id": "371728"
                    }
                ]
            }
        ]
    },
    {
        "value": "河南省",
        "id": "410000",
        "childs": [
            {
                "value": "郑州市",
                "id": "410100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "410101"
                    },
                    {
                        "value": "中原区",
                        "id": "410102"
                    },
                    {
                        "value": "二七区",
                        "id": "410103"
                    },
                    {
                        "value": "管城回族区",
                        "id": "410104"
                    },
                    {
                        "value": "金水区",
                        "id": "410105"
                    },
                    {
                        "value": "上街区",
                        "id": "410106"
                    },
                    {
                        "value": "惠济区",
                        "id": "410108"
                    },
                    {
                        "value": "中牟县",
                        "id": "410122"
                    },
                    {
                        "value": "巩义市",
                        "id": "410181"
                    },
                    {
                        "value": "荥阳市",
                        "id": "410182"
                    },
                    {
                        "value": "新密市",
                        "id": "410183"
                    },
                    {
                        "value": "新郑市",
                        "id": "410184"
                    },
                    {
                        "value": "登封市",
                        "id": "410185"
                    }
                ]
            },
            {
                "value": "开封市",
                "id": "410200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "410201"
                    },
                    {
                        "value": "龙亭区",
                        "id": "410202"
                    },
                    {
                        "value": "顺河回族区",
                        "id": "410203"
                    },
                    {
                        "value": "鼓楼区",
                        "id": "410204"
                    },
                    {
                        "value": "禹王台区",
                        "id": "410205"
                    },
                    {
                        "value": "祥符区",
                        "id": "410212"
                    },
                    {
                        "value": "杞县",
                        "id": "410221"
                    },
                    {
                        "value": "通许县",
                        "id": "410222"
                    },
                    {
                        "value": "尉氏县",
                        "id": "410223"
                    },
                    {
                        "value": "兰考县",
                        "id": "410225"
                    }
                ]
            },
            {
                "value": "洛阳市",
                "id": "410300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "410301"
                    },
                    {
                        "value": "老城区",
                        "id": "410302"
                    },
                    {
                        "value": "西工区",
                        "id": "410303"
                    },
                    {
                        "value": "瀍河回族区",
                        "id": "410304"
                    },
                    {
                        "value": "涧西区",
                        "id": "410305"
                    },
                    {
                        "value": "吉利区",
                        "id": "410306"
                    },
                    {
                        "value": "洛龙区",
                        "id": "410311"
                    },
                    {
                        "value": "孟津县",
                        "id": "410322"
                    },
                    {
                        "value": "新安县",
                        "id": "410323"
                    },
                    {
                        "value": "栾川县",
                        "id": "410324"
                    },
                    {
                        "value": "嵩县",
                        "id": "410325"
                    },
                    {
                        "value": "汝阳县",
                        "id": "410326"
                    },
                    {
                        "value": "宜阳县",
                        "id": "410327"
                    },
                    {
                        "value": "洛宁县",
                        "id": "410328"
                    },
                    {
                        "value": "伊川县",
                        "id": "410329"
                    },
                    {
                        "value": "偃师市",
                        "id": "410381"
                    }
                ]
            },
            {
                "value": "平顶山市",
                "id": "410400",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "410401"
                    },
                    {
                        "value": "新华区",
                        "id": "410402"
                    },
                    {
                        "value": "卫东区",
                        "id": "410403"
                    },
                    {
                        "value": "石龙区",
                        "id": "410404"
                    },
                    {
                        "value": "湛河区",
                        "id": "410411"
                    },
                    {
                        "value": "宝丰县",
                        "id": "410421"
                    },
                    {
                        "value": "叶县",
                        "id": "410422"
                    },
                    {
                        "value": "鲁山县",
                        "id": "410423"
                    },
                    {
                        "value": "郏县",
                        "id": "410425"
                    },
                    {
                        "value": "舞钢市",
                        "id": "410481"
                    },
                    {
                        "value": "汝州市",
                        "id": "410482"
                    }
                ]
            },
            {
                "value": "安阳市",
                "id": "410500",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "410501"
                    },
                    {
                        "value": "文峰区",
                        "id": "410502"
                    },
                    {
                        "value": "北关区",
                        "id": "410503"
                    },
                    {
                        "value": "殷都区",
                        "id": "410505"
                    },
                    {
                        "value": "龙安区",
                        "id": "410506"
                    },
                    {
                        "value": "安阳县",
                        "id": "410522"
                    },
                    {
                        "value": "汤阴县",
                        "id": "410523"
                    },
                    {
                        "value": "滑县",
                        "id": "410526"
                    },
                    {
                        "value": "内黄县",
                        "id": "410527"
                    },
                    {
                        "value": "林州市",
                        "id": "410581"
                    }
                ]
            },
            {
                "value": "鹤壁市",
                "id": "410600",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "410601"
                    },
                    {
                        "value": "鹤山区",
                        "id": "410602"
                    },
                    {
                        "value": "山城区",
                        "id": "410603"
                    },
                    {
                        "value": "淇滨区",
                        "id": "410611"
                    },
                    {
                        "value": "浚县",
                        "id": "410621"
                    },
                    {
                        "value": "淇县",
                        "id": "410622"
                    }
                ]
            },
            {
                "value": "新乡市",
                "id": "410700",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "410701"
                    },
                    {
                        "value": "红旗区",
                        "id": "410702"
                    },
                    {
                        "value": "卫滨区",
                        "id": "410703"
                    },
                    {
                        "value": "凤泉区",
                        "id": "410704"
                    },
                    {
                        "value": "牧野区",
                        "id": "410711"
                    },
                    {
                        "value": "新乡县",
                        "id": "410721"
                    },
                    {
                        "value": "获嘉县",
                        "id": "410724"
                    },
                    {
                        "value": "原阳县",
                        "id": "410725"
                    },
                    {
                        "value": "延津县",
                        "id": "410726"
                    },
                    {
                        "value": "封丘县",
                        "id": "410727"
                    },
                    {
                        "value": "长垣县",
                        "id": "410728"
                    },
                    {
                        "value": "卫辉市",
                        "id": "410781"
                    },
                    {
                        "value": "辉县市",
                        "id": "410782"
                    }
                ]
            },
            {
                "value": "焦作市",
                "id": "410800",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "410801"
                    },
                    {
                        "value": "解放区",
                        "id": "410802"
                    },
                    {
                        "value": "中站区",
                        "id": "410803"
                    },
                    {
                        "value": "马村区",
                        "id": "410804"
                    },
                    {
                        "value": "山阳区",
                        "id": "410811"
                    },
                    {
                        "value": "修武县",
                        "id": "410821"
                    },
                    {
                        "value": "博爱县",
                        "id": "410822"
                    },
                    {
                        "value": "武陟县",
                        "id": "410823"
                    },
                    {
                        "value": "温县",
                        "id": "410825"
                    },
                    {
                        "value": "沁阳市",
                        "id": "410882"
                    },
                    {
                        "value": "孟州市",
                        "id": "410883"
                    }
                ]
            },
            {
                "value": "濮阳市",
                "id": "410900",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "410901"
                    },
                    {
                        "value": "华龙区",
                        "id": "410902"
                    },
                    {
                        "value": "清丰县",
                        "id": "410922"
                    },
                    {
                        "value": "南乐县",
                        "id": "410923"
                    },
                    {
                        "value": "范县",
                        "id": "410926"
                    },
                    {
                        "value": "台前县",
                        "id": "410927"
                    },
                    {
                        "value": "濮阳县",
                        "id": "410928"
                    }
                ]
            },
            {
                "value": "许昌市",
                "id": "411000",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "411001"
                    },
                    {
                        "value": "魏都区",
                        "id": "411002"
                    },
                    {
                        "value": "许昌县",
                        "id": "411023"
                    },
                    {
                        "value": "鄢陵县",
                        "id": "411024"
                    },
                    {
                        "value": "襄城县",
                        "id": "411025"
                    },
                    {
                        "value": "禹州市",
                        "id": "411081"
                    },
                    {
                        "value": "长葛市",
                        "id": "411082"
                    }
                ]
            },
            {
                "value": "漯河市",
                "id": "411100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "411101"
                    },
                    {
                        "value": "源汇区",
                        "id": "411102"
                    },
                    {
                        "value": "郾城区",
                        "id": "411103"
                    },
                    {
                        "value": "召陵区",
                        "id": "411104"
                    },
                    {
                        "value": "舞阳县",
                        "id": "411121"
                    },
                    {
                        "value": "临颍县",
                        "id": "411122"
                    }
                ]
            },
            {
                "value": "三门峡市",
                "id": "411200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "411201"
                    },
                    {
                        "value": "湖滨区",
                        "id": "411202"
                    },
                    {
                        "value": "渑池县",
                        "id": "411221"
                    },
                    {
                        "value": "陕县",
                        "id": "411222"
                    },
                    {
                        "value": "卢氏县",
                        "id": "411224"
                    },
                    {
                        "value": "义马市",
                        "id": "411281"
                    },
                    {
                        "value": "灵宝市",
                        "id": "411282"
                    }
                ]
            },
            {
                "value": "南阳市",
                "id": "411300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "411301"
                    },
                    {
                        "value": "宛城区",
                        "id": "411302"
                    },
                    {
                        "value": "卧龙区",
                        "id": "411303"
                    },
                    {
                        "value": "南召县",
                        "id": "411321"
                    },
                    {
                        "value": "方城县",
                        "id": "411322"
                    },
                    {
                        "value": "西峡县",
                        "id": "411323"
                    },
                    {
                        "value": "镇平县",
                        "id": "411324"
                    },
                    {
                        "value": "内乡县",
                        "id": "411325"
                    },
                    {
                        "value": "淅川县",
                        "id": "411326"
                    },
                    {
                        "value": "社旗县",
                        "id": "411327"
                    },
                    {
                        "value": "唐河县",
                        "id": "411328"
                    },
                    {
                        "value": "新野县",
                        "id": "411329"
                    },
                    {
                        "value": "桐柏县",
                        "id": "411330"
                    },
                    {
                        "value": "邓州市",
                        "id": "411381"
                    }
                ]
            },
            {
                "value": "商丘市",
                "id": "411400",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "411401"
                    },
                    {
                        "value": "梁园区",
                        "id": "411402"
                    },
                    {
                        "value": "睢阳区",
                        "id": "411403"
                    },
                    {
                        "value": "民权县",
                        "id": "411421"
                    },
                    {
                        "value": "睢县",
                        "id": "411422"
                    },
                    {
                        "value": "宁陵县",
                        "id": "411423"
                    },
                    {
                        "value": "柘城县",
                        "id": "411424"
                    },
                    {
                        "value": "虞城县",
                        "id": "411425"
                    },
                    {
                        "value": "夏邑县",
                        "id": "411426"
                    },
                    {
                        "value": "永城市",
                        "id": "411481"
                    }
                ]
            },
            {
                "value": "信阳市",
                "id": "411500",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "411501"
                    },
                    {
                        "value": "浉河区",
                        "id": "411502"
                    },
                    {
                        "value": "平桥区",
                        "id": "411503"
                    },
                    {
                        "value": "罗山县",
                        "id": "411521"
                    },
                    {
                        "value": "光山县",
                        "id": "411522"
                    },
                    {
                        "value": "新县",
                        "id": "411523"
                    },
                    {
                        "value": "商城县",
                        "id": "411524"
                    },
                    {
                        "value": "固始县",
                        "id": "411525"
                    },
                    {
                        "value": "潢川县",
                        "id": "411526"
                    },
                    {
                        "value": "淮滨县",
                        "id": "411527"
                    },
                    {
                        "value": "息县",
                        "id": "411528"
                    }
                ]
            },
            {
                "value": "周口市",
                "id": "411600",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "411601"
                    },
                    {
                        "value": "川汇区",
                        "id": "411602"
                    },
                    {
                        "value": "扶沟县",
                        "id": "411621"
                    },
                    {
                        "value": "西华县",
                        "id": "411622"
                    },
                    {
                        "value": "商水县",
                        "id": "411623"
                    },
                    {
                        "value": "沈丘县",
                        "id": "411624"
                    },
                    {
                        "value": "郸城县",
                        "id": "411625"
                    },
                    {
                        "value": "淮阳县",
                        "id": "411626"
                    },
                    {
                        "value": "太康县",
                        "id": "411627"
                    },
                    {
                        "value": "鹿邑县",
                        "id": "411628"
                    },
                    {
                        "value": "项城市",
                        "id": "411681"
                    }
                ]
            },
            {
                "value": "驻马店市",
                "id": "411700",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "411701"
                    },
                    {
                        "value": "驿城区",
                        "id": "411702"
                    },
                    {
                        "value": "西平县",
                        "id": "411721"
                    },
                    {
                        "value": "上蔡县",
                        "id": "411722"
                    },
                    {
                        "value": "平舆县",
                        "id": "411723"
                    },
                    {
                        "value": "正阳县",
                        "id": "411724"
                    },
                    {
                        "value": "确山县",
                        "id": "411725"
                    },
                    {
                        "value": "泌阳县",
                        "id": "411726"
                    },
                    {
                        "value": "汝南县",
                        "id": "411727"
                    },
                    {
                        "value": "遂平县",
                        "id": "411728"
                    },
                    {
                        "value": "新蔡县",
                        "id": "411729"
                    }
                ]
            },
            {
                "value": "济源市",
                "id": "419001"
            }
        ]
    },
    {
        "value": "湖北省",
        "id": "420000",
        "childs": [
            {
                "value": "武汉市",
                "id": "420100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "420101"
                    },
                    {
                        "value": "江岸区",
                        "id": "420102"
                    },
                    {
                        "value": "江汉区",
                        "id": "420103"
                    },
                    {
                        "value": "硚口区",
                        "id": "420104"
                    },
                    {
                        "value": "汉阳区",
                        "id": "420105"
                    },
                    {
                        "value": "武昌区",
                        "id": "420106"
                    },
                    {
                        "value": "青山区",
                        "id": "420107"
                    },
                    {
                        "value": "洪山区",
                        "id": "420111"
                    },
                    {
                        "value": "东西湖区",
                        "id": "420112"
                    },
                    {
                        "value": "汉南区",
                        "id": "420113"
                    },
                    {
                        "value": "蔡甸区",
                        "id": "420114"
                    },
                    {
                        "value": "江夏区",
                        "id": "420115"
                    },
                    {
                        "value": "黄陂区",
                        "id": "420116"
                    },
                    {
                        "value": "新洲区",
                        "id": "420117"
                    }
                ]
            },
            {
                "value": "黄石市",
                "id": "420200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "420201"
                    },
                    {
                        "value": "黄石港区",
                        "id": "420202"
                    },
                    {
                        "value": "西塞山区",
                        "id": "420203"
                    },
                    {
                        "value": "下陆区",
                        "id": "420204"
                    },
                    {
                        "value": "铁山区",
                        "id": "420205"
                    },
                    {
                        "value": "阳新县",
                        "id": "420222"
                    },
                    {
                        "value": "大冶市",
                        "id": "420281"
                    }
                ]
            },
            {
                "value": "十堰市",
                "id": "420300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "420301"
                    },
                    {
                        "value": "茅箭区",
                        "id": "420302"
                    },
                    {
                        "value": "张湾区",
                        "id": "420303"
                    },
                    {
                        "value": "郧阳区",
                        "id": "420304"
                    },
                    {
                        "value": "郧西县",
                        "id": "420322"
                    },
                    {
                        "value": "竹山县",
                        "id": "420323"
                    },
                    {
                        "value": "竹溪县",
                        "id": "420324"
                    },
                    {
                        "value": "房县",
                        "id": "420325"
                    },
                    {
                        "value": "丹江口市",
                        "id": "420381"
                    }
                ]
            },
            {
                "value": "宜昌市",
                "id": "420500",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "420501"
                    },
                    {
                        "value": "西陵区",
                        "id": "420502"
                    },
                    {
                        "value": "伍家岗区",
                        "id": "420503"
                    },
                    {
                        "value": "点军区",
                        "id": "420504"
                    },
                    {
                        "value": "猇亭区",
                        "id": "420505"
                    },
                    {
                        "value": "夷陵区",
                        "id": "420506"
                    },
                    {
                        "value": "远安县",
                        "id": "420525"
                    },
                    {
                        "value": "兴山县",
                        "id": "420526"
                    },
                    {
                        "value": "秭归县",
                        "id": "420527"
                    },
                    {
                        "value": "长阳土家族自治县",
                        "id": "420528"
                    },
                    {
                        "value": "五峰土家族自治县",
                        "id": "420529"
                    },
                    {
                        "value": "宜都市",
                        "id": "420581"
                    },
                    {
                        "value": "当阳市",
                        "id": "420582"
                    },
                    {
                        "value": "枝江市",
                        "id": "420583"
                    }
                ]
            },
            {
                "value": "襄阳市",
                "id": "420600",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "420601"
                    },
                    {
                        "value": "襄城区",
                        "id": "420602"
                    },
                    {
                        "value": "樊城区",
                        "id": "420606"
                    },
                    {
                        "value": "襄州区",
                        "id": "420607"
                    },
                    {
                        "value": "南漳县",
                        "id": "420624"
                    },
                    {
                        "value": "谷城县",
                        "id": "420625"
                    },
                    {
                        "value": "保康县",
                        "id": "420626"
                    },
                    {
                        "value": "老河口市",
                        "id": "420682"
                    },
                    {
                        "value": "枣阳市",
                        "id": "420683"
                    },
                    {
                        "value": "宜城市",
                        "id": "420684"
                    }
                ]
            },
            {
                "value": "鄂州市",
                "id": "420700",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "420701"
                    },
                    {
                        "value": "梁子湖区",
                        "id": "420702"
                    },
                    {
                        "value": "华容区",
                        "id": "420703"
                    },
                    {
                        "value": "鄂城区",
                        "id": "420704"
                    }
                ]
            },
            {
                "value": "荆门市",
                "id": "420800",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "420801"
                    },
                    {
                        "value": "东宝区",
                        "id": "420802"
                    },
                    {
                        "value": "掇刀区",
                        "id": "420804"
                    },
                    {
                        "value": "京山县",
                        "id": "420821"
                    },
                    {
                        "value": "沙洋县",
                        "id": "420822"
                    },
                    {
                        "value": "钟祥市",
                        "id": "420881"
                    }
                ]
            },
            {
                "value": "孝感市",
                "id": "420900",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "420901"
                    },
                    {
                        "value": "孝南区",
                        "id": "420902"
                    },
                    {
                        "value": "孝昌县",
                        "id": "420921"
                    },
                    {
                        "value": "大悟县",
                        "id": "420922"
                    },
                    {
                        "value": "云梦县",
                        "id": "420923"
                    },
                    {
                        "value": "应城市",
                        "id": "420981"
                    },
                    {
                        "value": "安陆市",
                        "id": "420982"
                    },
                    {
                        "value": "汉川市",
                        "id": "420984"
                    }
                ]
            },
            {
                "value": "荆州市",
                "id": "421000",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "421001"
                    },
                    {
                        "value": "沙市区",
                        "id": "421002"
                    },
                    {
                        "value": "荆州区",
                        "id": "421003"
                    },
                    {
                        "value": "公安县",
                        "id": "421022"
                    },
                    {
                        "value": "监利县",
                        "id": "421023"
                    },
                    {
                        "value": "江陵县",
                        "id": "421024"
                    },
                    {
                        "value": "石首市",
                        "id": "421081"
                    },
                    {
                        "value": "洪湖市",
                        "id": "421083"
                    },
                    {
                        "value": "松滋市",
                        "id": "421087"
                    }
                ]
            },
            {
                "value": "黄冈市",
                "id": "421100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "421101"
                    },
                    {
                        "value": "黄州区",
                        "id": "421102"
                    },
                    {
                        "value": "团风县",
                        "id": "421121"
                    },
                    {
                        "value": "红安县",
                        "id": "421122"
                    },
                    {
                        "value": "罗田县",
                        "id": "421123"
                    },
                    {
                        "value": "英山县",
                        "id": "421124"
                    },
                    {
                        "value": "浠水县",
                        "id": "421125"
                    },
                    {
                        "value": "蕲春县",
                        "id": "421126"
                    },
                    {
                        "value": "黄梅县",
                        "id": "421127"
                    },
                    {
                        "value": "麻城市",
                        "id": "421181"
                    },
                    {
                        "value": "武穴市",
                        "id": "421182"
                    }
                ]
            },
            {
                "value": "咸宁市",
                "id": "421200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "421201"
                    },
                    {
                        "value": "咸安区",
                        "id": "421202"
                    },
                    {
                        "value": "嘉鱼县",
                        "id": "421221"
                    },
                    {
                        "value": "通城县",
                        "id": "421222"
                    },
                    {
                        "value": "崇阳县",
                        "id": "421223"
                    },
                    {
                        "value": "通山县",
                        "id": "421224"
                    },
                    {
                        "value": "赤壁市",
                        "id": "421281"
                    }
                ]
            },
            {
                "value": "随州市",
                "id": "421300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "421301"
                    },
                    {
                        "value": "曾都区",
                        "id": "421303"
                    },
                    {
                        "value": "随县",
                        "id": "421321"
                    },
                    {
                        "value": "广水市",
                        "id": "421381"
                    }
                ]
            },
            {
                "value": "恩施土家族苗族自治州",
                "id": "422800",
                "childs": [
                    {
                        "value": "恩施市",
                        "id": "422801"
                    },
                    {
                        "value": "利川市",
                        "id": "422802"
                    },
                    {
                        "value": "建始县",
                        "id": "422822"
                    },
                    {
                        "value": "巴东县",
                        "id": "422823"
                    },
                    {
                        "value": "宣恩县",
                        "id": "422825"
                    },
                    {
                        "value": "咸丰县",
                        "id": "422826"
                    },
                    {
                        "value": "来凤县",
                        "id": "422827"
                    },
                    {
                        "value": "鹤峰县",
                        "id": "422828"
                    }
                ]
            },
            {
                "value": "仙桃市",
                "id": "429004"
            },
            {
                "value": "潜江市",
                "id": "429005"
            },
            {
                "value": "天门市",
                "id": "429006"
            },
            {
                "value": "神农架林区",
                "id": "429021"
            }
        ]
    },
    {
        "value": "湖南省",
        "id": "430000",
        "childs": [
            {
                "value": "长沙市",
                "id": "430100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "430101"
                    },
                    {
                        "value": "芙蓉区",
                        "id": "430102"
                    },
                    {
                        "value": "天心区",
                        "id": "430103"
                    },
                    {
                        "value": "岳麓区",
                        "id": "430104"
                    },
                    {
                        "value": "开福区",
                        "id": "430105"
                    },
                    {
                        "value": "雨花区",
                        "id": "430111"
                    },
                    {
                        "value": "望城区",
                        "id": "430112"
                    },
                    {
                        "value": "长沙县",
                        "id": "430121"
                    },
                    {
                        "value": "宁乡县",
                        "id": "430124"
                    },
                    {
                        "value": "浏阳市",
                        "id": "430181"
                    }
                ]
            },
            {
                "value": "株洲市",
                "id": "430200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "430201"
                    },
                    {
                        "value": "荷塘区",
                        "id": "430202"
                    },
                    {
                        "value": "芦淞区",
                        "id": "430203"
                    },
                    {
                        "value": "石峰区",
                        "id": "430204"
                    },
                    {
                        "value": "天元区",
                        "id": "430211"
                    },
                    {
                        "value": "株洲县",
                        "id": "430221"
                    },
                    {
                        "value": "攸县",
                        "id": "430223"
                    },
                    {
                        "value": "茶陵县",
                        "id": "430224"
                    },
                    {
                        "value": "炎陵县",
                        "id": "430225"
                    },
                    {
                        "value": "醴陵市",
                        "id": "430281"
                    }
                ]
            },
            {
                "value": "湘潭市",
                "id": "430300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "430301"
                    },
                    {
                        "value": "雨湖区",
                        "id": "430302"
                    },
                    {
                        "value": "岳塘区",
                        "id": "430304"
                    },
                    {
                        "value": "湘潭县",
                        "id": "430321"
                    },
                    {
                        "value": "湘乡市",
                        "id": "430381"
                    },
                    {
                        "value": "韶山市",
                        "id": "430382"
                    }
                ]
            },
            {
                "value": "衡阳市",
                "id": "430400",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "430401"
                    },
                    {
                        "value": "珠晖区",
                        "id": "430405"
                    },
                    {
                        "value": "雁峰区",
                        "id": "430406"
                    },
                    {
                        "value": "石鼓区",
                        "id": "430407"
                    },
                    {
                        "value": "蒸湘区",
                        "id": "430408"
                    },
                    {
                        "value": "南岳区",
                        "id": "430412"
                    },
                    {
                        "value": "衡阳县",
                        "id": "430421"
                    },
                    {
                        "value": "衡南县",
                        "id": "430422"
                    },
                    {
                        "value": "衡山县",
                        "id": "430423"
                    },
                    {
                        "value": "衡东县",
                        "id": "430424"
                    },
                    {
                        "value": "祁东县",
                        "id": "430426"
                    },
                    {
                        "value": "耒阳市",
                        "id": "430481"
                    },
                    {
                        "value": "常宁市",
                        "id": "430482"
                    }
                ]
            },
            {
                "value": "邵阳市",
                "id": "430500",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "430501"
                    },
                    {
                        "value": "双清区",
                        "id": "430502"
                    },
                    {
                        "value": "大祥区",
                        "id": "430503"
                    },
                    {
                        "value": "北塔区",
                        "id": "430511"
                    },
                    {
                        "value": "邵东县",
                        "id": "430521"
                    },
                    {
                        "value": "新邵县",
                        "id": "430522"
                    },
                    {
                        "value": "邵阳县",
                        "id": "430523"
                    },
                    {
                        "value": "隆回县",
                        "id": "430524"
                    },
                    {
                        "value": "洞口县",
                        "id": "430525"
                    },
                    {
                        "value": "绥宁县",
                        "id": "430527"
                    },
                    {
                        "value": "新宁县",
                        "id": "430528"
                    },
                    {
                        "value": "城步苗族自治县",
                        "id": "430529"
                    },
                    {
                        "value": "武冈市",
                        "id": "430581"
                    }
                ]
            },
            {
                "value": "岳阳市",
                "id": "430600",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "430601"
                    },
                    {
                        "value": "岳阳楼区",
                        "id": "430602"
                    },
                    {
                        "value": "云溪区",
                        "id": "430603"
                    },
                    {
                        "value": "君山区",
                        "id": "430611"
                    },
                    {
                        "value": "岳阳县",
                        "id": "430621"
                    },
                    {
                        "value": "华容县",
                        "id": "430623"
                    },
                    {
                        "value": "湘阴县",
                        "id": "430624"
                    },
                    {
                        "value": "平江县",
                        "id": "430626"
                    },
                    {
                        "value": "汨罗市",
                        "id": "430681"
                    },
                    {
                        "value": "临湘市",
                        "id": "430682"
                    }
                ]
            },
            {
                "value": "常德市",
                "id": "430700",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "430701"
                    },
                    {
                        "value": "武陵区",
                        "id": "430702"
                    },
                    {
                        "value": "鼎城区",
                        "id": "430703"
                    },
                    {
                        "value": "安乡县",
                        "id": "430721"
                    },
                    {
                        "value": "汉寿县",
                        "id": "430722"
                    },
                    {
                        "value": "澧县",
                        "id": "430723"
                    },
                    {
                        "value": "临澧县",
                        "id": "430724"
                    },
                    {
                        "value": "桃源县",
                        "id": "430725"
                    },
                    {
                        "value": "石门县",
                        "id": "430726"
                    },
                    {
                        "value": "津市市",
                        "id": "430781"
                    }
                ]
            },
            {
                "value": "张家界市",
                "id": "430800",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "430801"
                    },
                    {
                        "value": "永定区",
                        "id": "430802"
                    },
                    {
                        "value": "武陵源区",
                        "id": "430811"
                    },
                    {
                        "value": "慈利县",
                        "id": "430821"
                    },
                    {
                        "value": "桑植县",
                        "id": "430822"
                    }
                ]
            },
            {
                "value": "益阳市",
                "id": "430900",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "430901"
                    },
                    {
                        "value": "资阳区",
                        "id": "430902"
                    },
                    {
                        "value": "赫山区",
                        "id": "430903"
                    },
                    {
                        "value": "南县",
                        "id": "430921"
                    },
                    {
                        "value": "桃江县",
                        "id": "430922"
                    },
                    {
                        "value": "安化县",
                        "id": "430923"
                    },
                    {
                        "value": "沅江市",
                        "id": "430981"
                    }
                ]
            },
            {
                "value": "郴州市",
                "id": "431000",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "431001"
                    },
                    {
                        "value": "北湖区",
                        "id": "431002"
                    },
                    {
                        "value": "苏仙区",
                        "id": "431003"
                    },
                    {
                        "value": "桂阳县",
                        "id": "431021"
                    },
                    {
                        "value": "宜章县",
                        "id": "431022"
                    },
                    {
                        "value": "永兴县",
                        "id": "431023"
                    },
                    {
                        "value": "嘉禾县",
                        "id": "431024"
                    },
                    {
                        "value": "临武县",
                        "id": "431025"
                    },
                    {
                        "value": "汝城县",
                        "id": "431026"
                    },
                    {
                        "value": "桂东县",
                        "id": "431027"
                    },
                    {
                        "value": "安仁县",
                        "id": "431028"
                    },
                    {
                        "value": "资兴市",
                        "id": "431081"
                    }
                ]
            },
            {
                "value": "永州市",
                "id": "431100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "431101"
                    },
                    {
                        "value": "零陵区",
                        "id": "431102"
                    },
                    {
                        "value": "冷水滩区",
                        "id": "431103"
                    },
                    {
                        "value": "祁阳县",
                        "id": "431121"
                    },
                    {
                        "value": "东安县",
                        "id": "431122"
                    },
                    {
                        "value": "双牌县",
                        "id": "431123"
                    },
                    {
                        "value": "道县",
                        "id": "431124"
                    },
                    {
                        "value": "江永县",
                        "id": "431125"
                    },
                    {
                        "value": "宁远县",
                        "id": "431126"
                    },
                    {
                        "value": "蓝山县",
                        "id": "431127"
                    },
                    {
                        "value": "新田县",
                        "id": "431128"
                    },
                    {
                        "value": "江华瑶族自治县",
                        "id": "431129"
                    }
                ]
            },
            {
                "value": "怀化市",
                "id": "431200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "431201"
                    },
                    {
                        "value": "鹤城区",
                        "id": "431202"
                    },
                    {
                        "value": "中方县",
                        "id": "431221"
                    },
                    {
                        "value": "沅陵县",
                        "id": "431222"
                    },
                    {
                        "value": "辰溪县",
                        "id": "431223"
                    },
                    {
                        "value": "溆浦县",
                        "id": "431224"
                    },
                    {
                        "value": "会同县",
                        "id": "431225"
                    },
                    {
                        "value": "麻阳苗族自治县",
                        "id": "431226"
                    },
                    {
                        "value": "新晃侗族自治县",
                        "id": "431227"
                    },
                    {
                        "value": "芷江侗族自治县",
                        "id": "431228"
                    },
                    {
                        "value": "靖州苗族侗族自治县",
                        "id": "431229"
                    },
                    {
                        "value": "通道侗族自治县",
                        "id": "431230"
                    },
                    {
                        "value": "洪江市",
                        "id": "431281"
                    }
                ]
            },
            {
                "value": "娄底市",
                "id": "431300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "431301"
                    },
                    {
                        "value": "娄星区",
                        "id": "431302"
                    },
                    {
                        "value": "双峰县",
                        "id": "431321"
                    },
                    {
                        "value": "新化县",
                        "id": "431322"
                    },
                    {
                        "value": "冷水江市",
                        "id": "431381"
                    },
                    {
                        "value": "涟源市",
                        "id": "431382"
                    }
                ]
            },
            {
                "value": "湘西土家族苗族自治州",
                "id": "433100",
                "childs": [
                    {
                        "value": "吉首市",
                        "id": "433101"
                    },
                    {
                        "value": "泸溪县",
                        "id": "433122"
                    },
                    {
                        "value": "凤凰县",
                        "id": "433123"
                    },
                    {
                        "value": "花垣县",
                        "id": "433124"
                    },
                    {
                        "value": "保靖县",
                        "id": "433125"
                    },
                    {
                        "value": "古丈县",
                        "id": "433126"
                    },
                    {
                        "value": "永顺县",
                        "id": "433127"
                    },
                    {
                        "value": "龙山县",
                        "id": "433130"
                    }
                ]
            }
        ]
    },
    {
        "value": "广东省",
        "id": "440000",
        "childs": [
            {
                "value": "广州市",
                "id": "440100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "440101"
                    },
                    {
                        "value": "荔湾区",
                        "id": "440103"
                    },
                    {
                        "value": "越秀区",
                        "id": "440104"
                    },
                    {
                        "value": "海珠区",
                        "id": "440105"
                    },
                    {
                        "value": "天河区",
                        "id": "440106"
                    },
                    {
                        "value": "白云区",
                        "id": "440111"
                    },
                    {
                        "value": "黄埔区",
                        "id": "440112"
                    },
                    {
                        "value": "番禺区",
                        "id": "440113"
                    },
                    {
                        "value": "花都区",
                        "id": "440114"
                    },
                    {
                        "value": "南沙区",
                        "id": "440115"
                    },
                    {
                        "value": "从化区",
                        "id": "440117"
                    },
                    {
                        "value": "增城区",
                        "id": "440118"
                    }
                ]
            },
            {
                "value": "韶关市",
                "id": "440200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "440201"
                    },
                    {
                        "value": "武江区",
                        "id": "440203"
                    },
                    {
                        "value": "浈江区",
                        "id": "440204"
                    },
                    {
                        "value": "曲江区",
                        "id": "440205"
                    },
                    {
                        "value": "始兴县",
                        "id": "440222"
                    },
                    {
                        "value": "仁化县",
                        "id": "440224"
                    },
                    {
                        "value": "翁源县",
                        "id": "440229"
                    },
                    {
                        "value": "乳源瑶族自治县",
                        "id": "440232"
                    },
                    {
                        "value": "新丰县",
                        "id": "440233"
                    },
                    {
                        "value": "乐昌市",
                        "id": "440281"
                    },
                    {
                        "value": "南雄市",
                        "id": "440282"
                    }
                ]
            },
            {
                "value": "深圳市",
                "id": "440300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "440301"
                    },
                    {
                        "value": "罗湖区",
                        "id": "440303"
                    },
                    {
                        "value": "福田区",
                        "id": "440304"
                    },
                    {
                        "value": "南山区",
                        "id": "440305"
                    },
                    {
                        "value": "宝安区",
                        "id": "440306"
                    },
                    {
                        "value": "龙岗区",
                        "id": "440307"
                    },
                    {
                        "value": "盐田区",
                        "id": "440308"
                    }
                ]
            },
            {
                "value": "珠海市",
                "id": "440400",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "440401"
                    },
                    {
                        "value": "香洲区",
                        "id": "440402"
                    },
                    {
                        "value": "斗门区",
                        "id": "440403"
                    },
                    {
                        "value": "金湾区",
                        "id": "440404"
                    }
                ]
            },
            {
                "value": "汕头市",
                "id": "440500",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "440501"
                    },
                    {
                        "value": "龙湖区",
                        "id": "440507"
                    },
                    {
                        "value": "金平区",
                        "id": "440511"
                    },
                    {
                        "value": "濠江区",
                        "id": "440512"
                    },
                    {
                        "value": "潮阳区",
                        "id": "440513"
                    },
                    {
                        "value": "潮南区",
                        "id": "440514"
                    },
                    {
                        "value": "澄海区",
                        "id": "440515"
                    },
                    {
                        "value": "南澳县",
                        "id": "440523"
                    }
                ]
            },
            {
                "value": "佛山市",
                "id": "440600",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "440601"
                    },
                    {
                        "value": "禅城区",
                        "id": "440604"
                    },
                    {
                        "value": "南海区",
                        "id": "440605"
                    },
                    {
                        "value": "顺德区",
                        "id": "440606"
                    },
                    {
                        "value": "三水区",
                        "id": "440607"
                    },
                    {
                        "value": "高明区",
                        "id": "440608"
                    }
                ]
            },
            {
                "value": "江门市",
                "id": "440700",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "440701"
                    },
                    {
                        "value": "蓬江区",
                        "id": "440703"
                    },
                    {
                        "value": "江海区",
                        "id": "440704"
                    },
                    {
                        "value": "新会区",
                        "id": "440705"
                    },
                    {
                        "value": "台山市",
                        "id": "440781"
                    },
                    {
                        "value": "开平市",
                        "id": "440783"
                    },
                    {
                        "value": "鹤山市",
                        "id": "440784"
                    },
                    {
                        "value": "恩平市",
                        "id": "440785"
                    }
                ]
            },
            {
                "value": "湛江市",
                "id": "440800",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "440801"
                    },
                    {
                        "value": "赤坎区",
                        "id": "440802"
                    },
                    {
                        "value": "霞山区",
                        "id": "440803"
                    },
                    {
                        "value": "坡头区",
                        "id": "440804"
                    },
                    {
                        "value": "麻章区",
                        "id": "440811"
                    },
                    {
                        "value": "遂溪县",
                        "id": "440823"
                    },
                    {
                        "value": "徐闻县",
                        "id": "440825"
                    },
                    {
                        "value": "廉江市",
                        "id": "440881"
                    },
                    {
                        "value": "雷州市",
                        "id": "440882"
                    },
                    {
                        "value": "吴川市",
                        "id": "440883"
                    }
                ]
            },
            {
                "value": "茂名市",
                "id": "440900",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "440901"
                    },
                    {
                        "value": "茂南区",
                        "id": "440902"
                    },
                    {
                        "value": "电白区",
                        "id": "440904"
                    },
                    {
                        "value": "高州市",
                        "id": "440981"
                    },
                    {
                        "value": "化州市",
                        "id": "440982"
                    },
                    {
                        "value": "信宜市",
                        "id": "440983"
                    }
                ]
            },
            {
                "value": "肇庆市",
                "id": "441200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "441201"
                    },
                    {
                        "value": "端州区",
                        "id": "441202"
                    },
                    {
                        "value": "鼎湖区",
                        "id": "441203"
                    },
                    {
                        "value": "广宁县",
                        "id": "441223"
                    },
                    {
                        "value": "怀集县",
                        "id": "441224"
                    },
                    {
                        "value": "封开县",
                        "id": "441225"
                    },
                    {
                        "value": "德庆县",
                        "id": "441226"
                    },
                    {
                        "value": "高要市",
                        "id": "441283"
                    },
                    {
                        "value": "四会市",
                        "id": "441284"
                    }
                ]
            },
            {
                "value": "惠州市",
                "id": "441300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "441301"
                    },
                    {
                        "value": "惠城区",
                        "id": "441302"
                    },
                    {
                        "value": "惠阳区",
                        "id": "441303"
                    },
                    {
                        "value": "博罗县",
                        "id": "441322"
                    },
                    {
                        "value": "惠东县",
                        "id": "441323"
                    },
                    {
                        "value": "龙门县",
                        "id": "441324"
                    }
                ]
            },
            {
                "value": "梅州市",
                "id": "441400",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "441401"
                    },
                    {
                        "value": "梅江区",
                        "id": "441402"
                    },
                    {
                        "value": "梅县区",
                        "id": "441403"
                    },
                    {
                        "value": "大埔县",
                        "id": "441422"
                    },
                    {
                        "value": "丰顺县",
                        "id": "441423"
                    },
                    {
                        "value": "五华县",
                        "id": "441424"
                    },
                    {
                        "value": "平远县",
                        "id": "441426"
                    },
                    {
                        "value": "蕉岭县",
                        "id": "441427"
                    },
                    {
                        "value": "兴宁市",
                        "id": "441481"
                    }
                ]
            },
            {
                "value": "汕尾市",
                "id": "441500",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "441501"
                    },
                    {
                        "value": "城区",
                        "id": "441502"
                    },
                    {
                        "value": "海丰县",
                        "id": "441521"
                    },
                    {
                        "value": "陆河县",
                        "id": "441523"
                    },
                    {
                        "value": "陆丰市",
                        "id": "441581"
                    }
                ]
            },
            {
                "value": "河源市",
                "id": "441600",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "441601"
                    },
                    {
                        "value": "源城区",
                        "id": "441602"
                    },
                    {
                        "value": "紫金县",
                        "id": "441621"
                    },
                    {
                        "value": "龙川县",
                        "id": "441622"
                    },
                    {
                        "value": "连平县",
                        "id": "441623"
                    },
                    {
                        "value": "和平县",
                        "id": "441624"
                    },
                    {
                        "value": "东源县",
                        "id": "441625"
                    }
                ]
            },
            {
                "value": "阳江市",
                "id": "441700",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "441701"
                    },
                    {
                        "value": "江城区",
                        "id": "441702"
                    },
                    {
                        "value": "阳东区",
                        "id": "441704"
                    },
                    {
                        "value": "阳西县",
                        "id": "441721"
                    },
                    {
                        "value": "阳春市",
                        "id": "441781"
                    }
                ]
            },
            {
                "value": "清远市",
                "id": "441800",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "441801"
                    },
                    {
                        "value": "清城区",
                        "id": "441802"
                    },
                    {
                        "value": "清新区",
                        "id": "441803"
                    },
                    {
                        "value": "佛冈县",
                        "id": "441821"
                    },
                    {
                        "value": "阳山县",
                        "id": "441823"
                    },
                    {
                        "value": "连山壮族瑶族自治县",
                        "id": "441825"
                    },
                    {
                        "value": "连南瑶族自治县",
                        "id": "441826"
                    },
                    {
                        "value": "英德市",
                        "id": "441881"
                    },
                    {
                        "value": "连州市",
                        "id": "441882"
                    }
                ]
            },
            {
                "value": "东莞市",
                "id": "441900",
                "childs": []
            },
            {
                "value": "中山市",
                "id": "442000",
                "childs": []
            },
            {
                "value": "潮州市",
                "id": "445100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "445101"
                    },
                    {
                        "value": "湘桥区",
                        "id": "445102"
                    },
                    {
                        "value": "潮安区",
                        "id": "445103"
                    },
                    {
                        "value": "饶平县",
                        "id": "445122"
                    }
                ]
            },
            {
                "value": "揭阳市",
                "id": "445200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "445201"
                    },
                    {
                        "value": "榕城区",
                        "id": "445202"
                    },
                    {
                        "value": "揭东区",
                        "id": "445203"
                    },
                    {
                        "value": "揭西县",
                        "id": "445222"
                    },
                    {
                        "value": "惠来县",
                        "id": "445224"
                    },
                    {
                        "value": "普宁市",
                        "id": "445281"
                    }
                ]
            },
            {
                "value": "云浮市",
                "id": "445300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "445301"
                    },
                    {
                        "value": "云城区",
                        "id": "445302"
                    },
                    {
                        "value": "云安区",
                        "id": "445303"
                    },
                    {
                        "value": "新兴县",
                        "id": "445321"
                    },
                    {
                        "value": "郁南县",
                        "id": "445322"
                    },
                    {
                        "value": "罗定市",
                        "id": "445381"
                    }
                ]
            }
        ]
    },
    {
        "value": "广西壮族自治区",
        "id": "450000",
        "childs": [
            {
                "value": "南宁市",
                "id": "450100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "450101"
                    },
                    {
                        "value": "兴宁区",
                        "id": "450102"
                    },
                    {
                        "value": "青秀区",
                        "id": "450103"
                    },
                    {
                        "value": "江南区",
                        "id": "450105"
                    },
                    {
                        "value": "西乡塘区",
                        "id": "450107"
                    },
                    {
                        "value": "良庆区",
                        "id": "450108"
                    },
                    {
                        "value": "邕宁区",
                        "id": "450109"
                    },
                    {
                        "value": "武鸣县",
                        "id": "450122"
                    },
                    {
                        "value": "隆安县",
                        "id": "450123"
                    },
                    {
                        "value": "马山县",
                        "id": "450124"
                    },
                    {
                        "value": "上林县",
                        "id": "450125"
                    },
                    {
                        "value": "宾阳县",
                        "id": "450126"
                    },
                    {
                        "value": "横县",
                        "id": "450127"
                    }
                ]
            },
            {
                "value": "柳州市",
                "id": "450200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "450201"
                    },
                    {
                        "value": "城中区",
                        "id": "450202"
                    },
                    {
                        "value": "鱼峰区",
                        "id": "450203"
                    },
                    {
                        "value": "柳南区",
                        "id": "450204"
                    },
                    {
                        "value": "柳北区",
                        "id": "450205"
                    },
                    {
                        "value": "柳江县",
                        "id": "450221"
                    },
                    {
                        "value": "柳城县",
                        "id": "450222"
                    },
                    {
                        "value": "鹿寨县",
                        "id": "450223"
                    },
                    {
                        "value": "融安县",
                        "id": "450224"
                    },
                    {
                        "value": "融水苗族自治县",
                        "id": "450225"
                    },
                    {
                        "value": "三江侗族自治县",
                        "id": "450226"
                    }
                ]
            },
            {
                "value": "桂林市",
                "id": "450300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "450301"
                    },
                    {
                        "value": "秀峰区",
                        "id": "450302"
                    },
                    {
                        "value": "叠彩区",
                        "id": "450303"
                    },
                    {
                        "value": "象山区",
                        "id": "450304"
                    },
                    {
                        "value": "七星区",
                        "id": "450305"
                    },
                    {
                        "value": "雁山区",
                        "id": "450311"
                    },
                    {
                        "value": "临桂区",
                        "id": "450312"
                    },
                    {
                        "value": "阳朔县",
                        "id": "450321"
                    },
                    {
                        "value": "灵川县",
                        "id": "450323"
                    },
                    {
                        "value": "全州县",
                        "id": "450324"
                    },
                    {
                        "value": "兴安县",
                        "id": "450325"
                    },
                    {
                        "value": "永福县",
                        "id": "450326"
                    },
                    {
                        "value": "灌阳县",
                        "id": "450327"
                    },
                    {
                        "value": "龙胜各族自治县",
                        "id": "450328"
                    },
                    {
                        "value": "资源县",
                        "id": "450329"
                    },
                    {
                        "value": "平乐县",
                        "id": "450330"
                    },
                    {
                        "value": "荔浦县",
                        "id": "450331"
                    },
                    {
                        "value": "恭城瑶族自治县",
                        "id": "450332"
                    }
                ]
            },
            {
                "value": "梧州市",
                "id": "450400",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "450401"
                    },
                    {
                        "value": "万秀区",
                        "id": "450403"
                    },
                    {
                        "value": "长洲区",
                        "id": "450405"
                    },
                    {
                        "value": "龙圩区",
                        "id": "450406"
                    },
                    {
                        "value": "苍梧县",
                        "id": "450421"
                    },
                    {
                        "value": "藤县",
                        "id": "450422"
                    },
                    {
                        "value": "蒙山县",
                        "id": "450423"
                    },
                    {
                        "value": "岑溪市",
                        "id": "450481"
                    }
                ]
            },
            {
                "value": "北海市",
                "id": "450500",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "450501"
                    },
                    {
                        "value": "海城区",
                        "id": "450502"
                    },
                    {
                        "value": "银海区",
                        "id": "450503"
                    },
                    {
                        "value": "铁山港区",
                        "id": "450512"
                    },
                    {
                        "value": "合浦县",
                        "id": "450521"
                    }
                ]
            },
            {
                "value": "防城港市",
                "id": "450600",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "450601"
                    },
                    {
                        "value": "港口区",
                        "id": "450602"
                    },
                    {
                        "value": "防城区",
                        "id": "450603"
                    },
                    {
                        "value": "上思县",
                        "id": "450621"
                    },
                    {
                        "value": "东兴市",
                        "id": "450681"
                    }
                ]
            },
            {
                "value": "钦州市",
                "id": "450700",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "450701"
                    },
                    {
                        "value": "钦南区",
                        "id": "450702"
                    },
                    {
                        "value": "钦北区",
                        "id": "450703"
                    },
                    {
                        "value": "灵山县",
                        "id": "450721"
                    },
                    {
                        "value": "浦北县",
                        "id": "450722"
                    }
                ]
            },
            {
                "value": "贵港市",
                "id": "450800",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "450801"
                    },
                    {
                        "value": "港北区",
                        "id": "450802"
                    },
                    {
                        "value": "港南区",
                        "id": "450803"
                    },
                    {
                        "value": "覃塘区",
                        "id": "450804"
                    },
                    {
                        "value": "平南县",
                        "id": "450821"
                    },
                    {
                        "value": "桂平市",
                        "id": "450881"
                    }
                ]
            },
            {
                "value": "玉林市",
                "id": "450900",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "450901"
                    },
                    {
                        "value": "玉州区",
                        "id": "450902"
                    },
                    {
                        "value": "福绵区",
                        "id": "450903"
                    },
                    {
                        "value": "容县",
                        "id": "450921"
                    },
                    {
                        "value": "陆川县",
                        "id": "450922"
                    },
                    {
                        "value": "博白县",
                        "id": "450923"
                    },
                    {
                        "value": "兴业县",
                        "id": "450924"
                    },
                    {
                        "value": "北流市",
                        "id": "450981"
                    }
                ]
            },
            {
                "value": "百色市",
                "id": "451000",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "451001"
                    },
                    {
                        "value": "右江区",
                        "id": "451002"
                    },
                    {
                        "value": "田阳县",
                        "id": "451021"
                    },
                    {
                        "value": "田东县",
                        "id": "451022"
                    },
                    {
                        "value": "平果县",
                        "id": "451023"
                    },
                    {
                        "value": "德保县",
                        "id": "451024"
                    },
                    {
                        "value": "靖西县",
                        "id": "451025"
                    },
                    {
                        "value": "那坡县",
                        "id": "451026"
                    },
                    {
                        "value": "凌云县",
                        "id": "451027"
                    },
                    {
                        "value": "乐业县",
                        "id": "451028"
                    },
                    {
                        "value": "田林县",
                        "id": "451029"
                    },
                    {
                        "value": "西林县",
                        "id": "451030"
                    },
                    {
                        "value": "隆林各族自治县",
                        "id": "451031"
                    }
                ]
            },
            {
                "value": "贺州市",
                "id": "451100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "451101"
                    },
                    {
                        "value": "八步区",
                        "id": "451102"
                    },
                    {
                        "value": "平桂管理区",
                        "id": "451119"
                    },
                    {
                        "value": "昭平县",
                        "id": "451121"
                    },
                    {
                        "value": "钟山县",
                        "id": "451122"
                    },
                    {
                        "value": "富川瑶族自治县",
                        "id": "451123"
                    }
                ]
            },
            {
                "value": "河池市",
                "id": "451200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "451201"
                    },
                    {
                        "value": "金城江区",
                        "id": "451202"
                    },
                    {
                        "value": "南丹县",
                        "id": "451221"
                    },
                    {
                        "value": "天峨县",
                        "id": "451222"
                    },
                    {
                        "value": "凤山县",
                        "id": "451223"
                    },
                    {
                        "value": "东兰县",
                        "id": "451224"
                    },
                    {
                        "value": "罗城仫佬族自治县",
                        "id": "451225"
                    },
                    {
                        "value": "环江毛南族自治县",
                        "id": "451226"
                    },
                    {
                        "value": "巴马瑶族自治县",
                        "id": "451227"
                    },
                    {
                        "value": "都安瑶族自治县",
                        "id": "451228"
                    },
                    {
                        "value": "大化瑶族自治县",
                        "id": "451229"
                    },
                    {
                        "value": "宜州市",
                        "id": "451281"
                    }
                ]
            },
            {
                "value": "来宾市",
                "id": "451300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "451301"
                    },
                    {
                        "value": "兴宾区",
                        "id": "451302"
                    },
                    {
                        "value": "忻城县",
                        "id": "451321"
                    },
                    {
                        "value": "象州县",
                        "id": "451322"
                    },
                    {
                        "value": "武宣县",
                        "id": "451323"
                    },
                    {
                        "value": "金秀瑶族自治县",
                        "id": "451324"
                    },
                    {
                        "value": "合山市",
                        "id": "451381"
                    }
                ]
            },
            {
                "value": "崇左市",
                "id": "451400",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "451401"
                    },
                    {
                        "value": "江州区",
                        "id": "451402"
                    },
                    {
                        "value": "扶绥县",
                        "id": "451421"
                    },
                    {
                        "value": "宁明县",
                        "id": "451422"
                    },
                    {
                        "value": "龙州县",
                        "id": "451423"
                    },
                    {
                        "value": "大新县",
                        "id": "451424"
                    },
                    {
                        "value": "天等县",
                        "id": "451425"
                    },
                    {
                        "value": "凭祥市",
                        "id": "451481"
                    }
                ]
            }
        ]
    },
    {
        "value": "海南省",
        "id": "460000",
        "childs": [
            {
                "value": "海口市",
                "id": "460100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "460101"
                    },
                    {
                        "value": "秀英区",
                        "id": "460105"
                    },
                    {
                        "value": "龙华区",
                        "id": "460106"
                    },
                    {
                        "value": "琼山区",
                        "id": "460107"
                    },
                    {
                        "value": "美兰区",
                        "id": "460108"
                    }
                ]
            },
            {
                "value": "三亚市",
                "id": "460200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "460201"
                    },
                    {
                        "value": "海棠区",
                        "id": "460202"
                    },
                    {
                        "value": "吉阳区",
                        "id": "460203"
                    },
                    {
                        "value": "天涯区",
                        "id": "460204"
                    },
                    {
                        "value": "崖州区",
                        "id": "460205"
                    }
                ]
            },
            {
                "value": "三沙市",
                "id": "460300",
                "childs": [
                    {
                        "value": "西沙群岛",
                        "id": "460321"
                    },
                    {
                        "value": "南沙群岛",
                        "id": "460322"
                    },
                    {
                        "value": "中沙群岛的岛礁及其海域",
                        "id": "460323"
                    }
                ]
            },
            {
                "value": "五指山市",
                "id": "469001"
            },
            {
                "value": "琼海市",
                "id": "469002"
            },
            {
                "value": "儋州市",
                "id": "469003"
            },
            {
                "value": "文昌市",
                "id": "469005"
            },
            {
                "value": "万宁市",
                "id": "469006"
            },
            {
                "value": "东方市",
                "id": "469007"
            },
            {
                "value": "定安县",
                "id": "469021"
            },
            {
                "value": "屯昌县",
                "id": "469022"
            },
            {
                "value": "澄迈县",
                "id": "469023"
            },
            {
                "value": "临高县",
                "id": "469024"
            },
            {
                "value": "白沙黎族自治县",
                "id": "469025"
            },
            {
                "value": "昌江黎族自治县",
                "id": "469026"
            },
            {
                "value": "乐东黎族自治县",
                "id": "469027"
            },
            {
                "value": "陵水黎族自治县",
                "id": "469028"
            },
            {
                "value": "保亭黎族苗族自治县",
                "id": "469029"
            },
            {
                "value": "琼中黎族苗族自治县",
                "id": "469030"
            }
        ]
    },
    {
        "value": "重庆",
        "id": "500000",
        "childs": [
            {
                "value": "重庆市",
                "id": "500000",
                "childs": [
                    {
                        "value": "万州区",
                        "id": "500101"
                    },
                    {
                        "value": "涪陵区",
                        "id": "500102"
                    },
                    {
                        "value": "渝中区",
                        "id": "500103"
                    },
                    {
                        "value": "大渡口区",
                        "id": "500104"
                    },
                    {
                        "value": "江北区",
                        "id": "500105"
                    },
                    {
                        "value": "沙坪坝区",
                        "id": "500106"
                    },
                    {
                        "value": "九龙坡区",
                        "id": "500107"
                    },
                    {
                        "value": "南岸区",
                        "id": "500108"
                    },
                    {
                        "value": "北碚区",
                        "id": "500109"
                    },
                    {
                        "value": "綦江区",
                        "id": "500110"
                    },
                    {
                        "value": "大足区",
                        "id": "500111"
                    },
                    {
                        "value": "渝北区",
                        "id": "500112"
                    },
                    {
                        "value": "巴南区",
                        "id": "500113"
                    },
                    {
                        "value": "黔江区",
                        "id": "500114"
                    },
                    {
                        "value": "长寿区",
                        "id": "500115"
                    },
                    {
                        "value": "江津区",
                        "id": "500116"
                    },
                    {
                        "value": "合川区",
                        "id": "500117"
                    },
                    {
                        "value": "永川区",
                        "id": "500118"
                    },
                    {
                        "value": "南川区",
                        "id": "500119"
                    },
                    {
                        "value": "璧山区",
                        "id": "500120"
                    },
                    {
                        "value": "铜梁区",
                        "id": "500151"
                    },
                    {
                        "value": "潼南县",
                        "id": "500223"
                    },
                    {
                        "value": "荣昌县",
                        "id": "500226"
                    },
                    {
                        "value": "梁平县",
                        "id": "500228"
                    },
                    {
                        "value": "城口县",
                        "id": "500229"
                    },
                    {
                        "value": "丰都县",
                        "id": "500230"
                    },
                    {
                        "value": "垫江县",
                        "id": "500231"
                    },
                    {
                        "value": "武隆县",
                        "id": "500232"
                    },
                    {
                        "value": "忠县",
                        "id": "500233"
                    },
                    {
                        "value": "开县",
                        "id": "500234"
                    },
                    {
                        "value": "云阳县",
                        "id": "500235"
                    },
                    {
                        "value": "奉节县",
                        "id": "500236"
                    },
                    {
                        "value": "巫山县",
                        "id": "500237"
                    },
                    {
                        "value": "巫溪县",
                        "id": "500238"
                    },
                    {
                        "value": "石柱土家族自治县",
                        "id": "500240"
                    },
                    {
                        "value": "秀山土家族苗族自治县",
                        "id": "500241"
                    },
                    {
                        "value": "酉阳土家族苗族自治县",
                        "id": "500242"
                    },
                    {
                        "value": "彭水苗族土家族自治县",
                        "id": "500243"
                    }
                ]
            }
        ]
    },
    {
        "value": "四川省",
        "id": "510000",
        "childs": [
            {
                "value": "成都市",
                "id": "510100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "510101"
                    },
                    {
                        "value": "锦江区",
                        "id": "510104"
                    },
                    {
                        "value": "青羊区",
                        "id": "510105"
                    },
                    {
                        "value": "金牛区",
                        "id": "510106"
                    },
                    {
                        "value": "武侯区",
                        "id": "510107"
                    },
                    {
                        "value": "成华区",
                        "id": "510108"
                    },
                    {
                        "value": "龙泉驿区",
                        "id": "510112"
                    },
                    {
                        "value": "青白江区",
                        "id": "510113"
                    },
                    {
                        "value": "新都区",
                        "id": "510114"
                    },
                    {
                        "value": "温江区",
                        "id": "510115"
                    },
                    {
                        "value": "金堂县",
                        "id": "510121"
                    },
                    {
                        "value": "双流县",
                        "id": "510122"
                    },
                    {
                        "value": "郫县",
                        "id": "510124"
                    },
                    {
                        "value": "大邑县",
                        "id": "510129"
                    },
                    {
                        "value": "蒲江县",
                        "id": "510131"
                    },
                    {
                        "value": "新津县",
                        "id": "510132"
                    },
                    {
                        "value": "都江堰市",
                        "id": "510181"
                    },
                    {
                        "value": "彭州市",
                        "id": "510182"
                    },
                    {
                        "value": "邛崃市",
                        "id": "510183"
                    },
                    {
                        "value": "崇州市",
                        "id": "510184"
                    }
                ]
            },
            {
                "value": "自贡市",
                "id": "510300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "510301"
                    },
                    {
                        "value": "自流井区",
                        "id": "510302"
                    },
                    {
                        "value": "贡井区",
                        "id": "510303"
                    },
                    {
                        "value": "大安区",
                        "id": "510304"
                    },
                    {
                        "value": "沿滩区",
                        "id": "510311"
                    },
                    {
                        "value": "荣县",
                        "id": "510321"
                    },
                    {
                        "value": "富顺县",
                        "id": "510322"
                    }
                ]
            },
            {
                "value": "攀枝花市",
                "id": "510400",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "510401"
                    },
                    {
                        "value": "东区",
                        "id": "510402"
                    },
                    {
                        "value": "西区",
                        "id": "510403"
                    },
                    {
                        "value": "仁和区",
                        "id": "510411"
                    },
                    {
                        "value": "米易县",
                        "id": "510421"
                    },
                    {
                        "value": "盐边县",
                        "id": "510422"
                    }
                ]
            },
            {
                "value": "泸州市",
                "id": "510500",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "510501"
                    },
                    {
                        "value": "江阳区",
                        "id": "510502"
                    },
                    {
                        "value": "纳溪区",
                        "id": "510503"
                    },
                    {
                        "value": "龙马潭区",
                        "id": "510504"
                    },
                    {
                        "value": "泸县",
                        "id": "510521"
                    },
                    {
                        "value": "合江县",
                        "id": "510522"
                    },
                    {
                        "value": "叙永县",
                        "id": "510524"
                    },
                    {
                        "value": "古蔺县",
                        "id": "510525"
                    }
                ]
            },
            {
                "value": "德阳市",
                "id": "510600",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "510601"
                    },
                    {
                        "value": "旌阳区",
                        "id": "510603"
                    },
                    {
                        "value": "中江县",
                        "id": "510623"
                    },
                    {
                        "value": "罗江县",
                        "id": "510626"
                    },
                    {
                        "value": "广汉市",
                        "id": "510681"
                    },
                    {
                        "value": "什邡市",
                        "id": "510682"
                    },
                    {
                        "value": "绵竹市",
                        "id": "510683"
                    }
                ]
            },
            {
                "value": "绵阳市",
                "id": "510700",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "510701"
                    },
                    {
                        "value": "涪城区",
                        "id": "510703"
                    },
                    {
                        "value": "游仙区",
                        "id": "510704"
                    },
                    {
                        "value": "三台县",
                        "id": "510722"
                    },
                    {
                        "value": "盐亭县",
                        "id": "510723"
                    },
                    {
                        "value": "安县",
                        "id": "510724"
                    },
                    {
                        "value": "梓潼县",
                        "id": "510725"
                    },
                    {
                        "value": "北川羌族自治县",
                        "id": "510726"
                    },
                    {
                        "value": "平武县",
                        "id": "510727"
                    },
                    {
                        "value": "江油市",
                        "id": "510781"
                    }
                ]
            },
            {
                "value": "广元市",
                "id": "510800",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "510801"
                    },
                    {
                        "value": "利州区",
                        "id": "510802"
                    },
                    {
                        "value": "昭化区",
                        "id": "510811"
                    },
                    {
                        "value": "朝天区",
                        "id": "510812"
                    },
                    {
                        "value": "旺苍县",
                        "id": "510821"
                    },
                    {
                        "value": "青川县",
                        "id": "510822"
                    },
                    {
                        "value": "剑阁县",
                        "id": "510823"
                    },
                    {
                        "value": "苍溪县",
                        "id": "510824"
                    }
                ]
            },
            {
                "value": "遂宁市",
                "id": "510900",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "510901"
                    },
                    {
                        "value": "船山区",
                        "id": "510903"
                    },
                    {
                        "value": "安居区",
                        "id": "510904"
                    },
                    {
                        "value": "蓬溪县",
                        "id": "510921"
                    },
                    {
                        "value": "射洪县",
                        "id": "510922"
                    },
                    {
                        "value": "大英县",
                        "id": "510923"
                    }
                ]
            },
            {
                "value": "内江市",
                "id": "511000",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "511001"
                    },
                    {
                        "value": "市中区",
                        "id": "511002"
                    },
                    {
                        "value": "东兴区",
                        "id": "511011"
                    },
                    {
                        "value": "威远县",
                        "id": "511024"
                    },
                    {
                        "value": "资中县",
                        "id": "511025"
                    },
                    {
                        "value": "隆昌县",
                        "id": "511028"
                    }
                ]
            },
            {
                "value": "乐山市",
                "id": "511100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "511101"
                    },
                    {
                        "value": "市中区",
                        "id": "511102"
                    },
                    {
                        "value": "沙湾区",
                        "id": "511111"
                    },
                    {
                        "value": "五通桥区",
                        "id": "511112"
                    },
                    {
                        "value": "金口河区",
                        "id": "511113"
                    },
                    {
                        "value": "犍为县",
                        "id": "511123"
                    },
                    {
                        "value": "井研县",
                        "id": "511124"
                    },
                    {
                        "value": "夹江县",
                        "id": "511126"
                    },
                    {
                        "value": "沐川县",
                        "id": "511129"
                    },
                    {
                        "value": "峨边彝族自治县",
                        "id": "511132"
                    },
                    {
                        "value": "马边彝族自治县",
                        "id": "511133"
                    },
                    {
                        "value": "峨眉山市",
                        "id": "511181"
                    }
                ]
            },
            {
                "value": "南充市",
                "id": "511300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "511301"
                    },
                    {
                        "value": "顺庆区",
                        "id": "511302"
                    },
                    {
                        "value": "高坪区",
                        "id": "511303"
                    },
                    {
                        "value": "嘉陵区",
                        "id": "511304"
                    },
                    {
                        "value": "南部县",
                        "id": "511321"
                    },
                    {
                        "value": "营山县",
                        "id": "511322"
                    },
                    {
                        "value": "蓬安县",
                        "id": "511323"
                    },
                    {
                        "value": "仪陇县",
                        "id": "511324"
                    },
                    {
                        "value": "西充县",
                        "id": "511325"
                    },
                    {
                        "value": "阆中市",
                        "id": "511381"
                    }
                ]
            },
            {
                "value": "眉山市",
                "id": "511400",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "511401"
                    },
                    {
                        "value": "东坡区",
                        "id": "511402"
                    },
                    {
                        "value": "彭山区",
                        "id": "511403"
                    },
                    {
                        "value": "仁寿县",
                        "id": "511421"
                    },
                    {
                        "value": "洪雅县",
                        "id": "511423"
                    },
                    {
                        "value": "丹棱县",
                        "id": "511424"
                    },
                    {
                        "value": "青神县",
                        "id": "511425"
                    }
                ]
            },
            {
                "value": "宜宾市",
                "id": "511500",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "511501"
                    },
                    {
                        "value": "翠屏区",
                        "id": "511502"
                    },
                    {
                        "value": "南溪区",
                        "id": "511503"
                    },
                    {
                        "value": "宜宾县",
                        "id": "511521"
                    },
                    {
                        "value": "江安县",
                        "id": "511523"
                    },
                    {
                        "value": "长宁县",
                        "id": "511524"
                    },
                    {
                        "value": "高县",
                        "id": "511525"
                    },
                    {
                        "value": "珙县",
                        "id": "511526"
                    },
                    {
                        "value": "筠连县",
                        "id": "511527"
                    },
                    {
                        "value": "兴文县",
                        "id": "511528"
                    },
                    {
                        "value": "屏山县",
                        "id": "511529"
                    }
                ]
            },
            {
                "value": "广安市",
                "id": "511600",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "511601"
                    },
                    {
                        "value": "广安区",
                        "id": "511602"
                    },
                    {
                        "value": "前锋区",
                        "id": "511603"
                    },
                    {
                        "value": "岳池县",
                        "id": "511621"
                    },
                    {
                        "value": "武胜县",
                        "id": "511622"
                    },
                    {
                        "value": "邻水县",
                        "id": "511623"
                    },
                    {
                        "value": "华蓥市",
                        "id": "511681"
                    }
                ]
            },
            {
                "value": "达州市",
                "id": "511700",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "511701"
                    },
                    {
                        "value": "通川区",
                        "id": "511702"
                    },
                    {
                        "value": "达川区",
                        "id": "511703"
                    },
                    {
                        "value": "宣汉县",
                        "id": "511722"
                    },
                    {
                        "value": "开江县",
                        "id": "511723"
                    },
                    {
                        "value": "大竹县",
                        "id": "511724"
                    },
                    {
                        "value": "渠县",
                        "id": "511725"
                    },
                    {
                        "value": "万源市",
                        "id": "511781"
                    }
                ]
            },
            {
                "value": "雅安市",
                "id": "511800",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "511801"
                    },
                    {
                        "value": "雨城区",
                        "id": "511802"
                    },
                    {
                        "value": "名山区",
                        "id": "511803"
                    },
                    {
                        "value": "荥经县",
                        "id": "511822"
                    },
                    {
                        "value": "汉源县",
                        "id": "511823"
                    },
                    {
                        "value": "石棉县",
                        "id": "511824"
                    },
                    {
                        "value": "天全县",
                        "id": "511825"
                    },
                    {
                        "value": "芦山县",
                        "id": "511826"
                    },
                    {
                        "value": "宝兴县",
                        "id": "511827"
                    }
                ]
            },
            {
                "value": "巴中市",
                "id": "511900",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "511901"
                    },
                    {
                        "value": "巴州区",
                        "id": "511902"
                    },
                    {
                        "value": "恩阳区",
                        "id": "511903"
                    },
                    {
                        "value": "通江县",
                        "id": "511921"
                    },
                    {
                        "value": "南江县",
                        "id": "511922"
                    },
                    {
                        "value": "平昌县",
                        "id": "511923"
                    }
                ]
            },
            {
                "value": "资阳市",
                "id": "512000",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "512001"
                    },
                    {
                        "value": "雁江区",
                        "id": "512002"
                    },
                    {
                        "value": "安岳县",
                        "id": "512021"
                    },
                    {
                        "value": "乐至县",
                        "id": "512022"
                    },
                    {
                        "value": "简阳市",
                        "id": "512081"
                    }
                ]
            },
            {
                "value": "阿坝藏族羌族自治州",
                "id": "513200",
                "childs": [
                    {
                        "value": "汶川县",
                        "id": "513221"
                    },
                    {
                        "value": "理县",
                        "id": "513222"
                    },
                    {
                        "value": "茂县",
                        "id": "513223"
                    },
                    {
                        "value": "松潘县",
                        "id": "513224"
                    },
                    {
                        "value": "九寨沟县",
                        "id": "513225"
                    },
                    {
                        "value": "金川县",
                        "id": "513226"
                    },
                    {
                        "value": "小金县",
                        "id": "513227"
                    },
                    {
                        "value": "黑水县",
                        "id": "513228"
                    },
                    {
                        "value": "马尔康县",
                        "id": "513229"
                    },
                    {
                        "value": "壤塘县",
                        "id": "513230"
                    },
                    {
                        "value": "阿坝县",
                        "id": "513231"
                    },
                    {
                        "value": "若尔盖县",
                        "id": "513232"
                    },
                    {
                        "value": "红原县",
                        "id": "513233"
                    }
                ]
            },
            {
                "value": "甘孜藏族自治州",
                "id": "513300",
                "childs": [
                    {
                        "value": "康定县",
                        "id": "513321"
                    },
                    {
                        "value": "泸定县",
                        "id": "513322"
                    },
                    {
                        "value": "丹巴县",
                        "id": "513323"
                    },
                    {
                        "value": "九龙县",
                        "id": "513324"
                    },
                    {
                        "value": "雅江县",
                        "id": "513325"
                    },
                    {
                        "value": "道孚县",
                        "id": "513326"
                    },
                    {
                        "value": "炉霍县",
                        "id": "513327"
                    },
                    {
                        "value": "甘孜县",
                        "id": "513328"
                    },
                    {
                        "value": "新龙县",
                        "id": "513329"
                    },
                    {
                        "value": "德格县",
                        "id": "513330"
                    },
                    {
                        "value": "白玉县",
                        "id": "513331"
                    },
                    {
                        "value": "石渠县",
                        "id": "513332"
                    },
                    {
                        "value": "色达县",
                        "id": "513333"
                    },
                    {
                        "value": "理塘县",
                        "id": "513334"
                    },
                    {
                        "value": "巴塘县",
                        "id": "513335"
                    },
                    {
                        "value": "乡城县",
                        "id": "513336"
                    },
                    {
                        "value": "稻城县",
                        "id": "513337"
                    },
                    {
                        "value": "得荣县",
                        "id": "513338"
                    }
                ]
            },
            {
                "value": "凉山彝族自治州",
                "id": "513400",
                "childs": [
                    {
                        "value": "西昌市",
                        "id": "513401"
                    },
                    {
                        "value": "木里藏族自治县",
                        "id": "513422"
                    },
                    {
                        "value": "盐源县",
                        "id": "513423"
                    },
                    {
                        "value": "德昌县",
                        "id": "513424"
                    },
                    {
                        "value": "会理县",
                        "id": "513425"
                    },
                    {
                        "value": "会东县",
                        "id": "513426"
                    },
                    {
                        "value": "宁南县",
                        "id": "513427"
                    },
                    {
                        "value": "普格县",
                        "id": "513428"
                    },
                    {
                        "value": "布拖县",
                        "id": "513429"
                    },
                    {
                        "value": "金阳县",
                        "id": "513430"
                    },
                    {
                        "value": "昭觉县",
                        "id": "513431"
                    },
                    {
                        "value": "喜德县",
                        "id": "513432"
                    },
                    {
                        "value": "冕宁县",
                        "id": "513433"
                    },
                    {
                        "value": "越西县",
                        "id": "513434"
                    },
                    {
                        "value": "甘洛县",
                        "id": "513435"
                    },
                    {
                        "value": "美姑县",
                        "id": "513436"
                    },
                    {
                        "value": "雷波县",
                        "id": "513437"
                    }
                ]
            }
        ]
    },
    {
        "value": "贵州省",
        "id": "520000",
        "childs": [
            {
                "value": "贵阳市",
                "id": "520100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "520101"
                    },
                    {
                        "value": "南明区",
                        "id": "520102"
                    },
                    {
                        "value": "云岩区",
                        "id": "520103"
                    },
                    {
                        "value": "花溪区",
                        "id": "520111"
                    },
                    {
                        "value": "乌当区",
                        "id": "520112"
                    },
                    {
                        "value": "白云区",
                        "id": "520113"
                    },
                    {
                        "value": "观山湖区",
                        "id": "520115"
                    },
                    {
                        "value": "开阳县",
                        "id": "520121"
                    },
                    {
                        "value": "息烽县",
                        "id": "520122"
                    },
                    {
                        "value": "修文县",
                        "id": "520123"
                    },
                    {
                        "value": "清镇市",
                        "id": "520181"
                    }
                ]
            },
            {
                "value": "六盘水市",
                "id": "520200",
                "childs": [
                    {
                        "value": "钟山区",
                        "id": "520201"
                    },
                    {
                        "value": "六枝特区",
                        "id": "520203"
                    },
                    {
                        "value": "水城县",
                        "id": "520221"
                    },
                    {
                        "value": "盘县",
                        "id": "520222"
                    }
                ]
            },
            {
                "value": "遵义市",
                "id": "520300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "520301"
                    },
                    {
                        "value": "红花岗区",
                        "id": "520302"
                    },
                    {
                        "value": "汇川区",
                        "id": "520303"
                    },
                    {
                        "value": "遵义县",
                        "id": "520321"
                    },
                    {
                        "value": "桐梓县",
                        "id": "520322"
                    },
                    {
                        "value": "绥阳县",
                        "id": "520323"
                    },
                    {
                        "value": "正安县",
                        "id": "520324"
                    },
                    {
                        "value": "道真仡佬族苗族自治县",
                        "id": "520325"
                    },
                    {
                        "value": "务川仡佬族苗族自治县",
                        "id": "520326"
                    },
                    {
                        "value": "凤冈县",
                        "id": "520327"
                    },
                    {
                        "value": "湄潭县",
                        "id": "520328"
                    },
                    {
                        "value": "余庆县",
                        "id": "520329"
                    },
                    {
                        "value": "习水县",
                        "id": "520330"
                    },
                    {
                        "value": "赤水市",
                        "id": "520381"
                    },
                    {
                        "value": "仁怀市",
                        "id": "520382"
                    }
                ]
            },
            {
                "value": "安顺市",
                "id": "520400",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "520401"
                    },
                    {
                        "value": "西秀区",
                        "id": "520402"
                    },
                    {
                        "value": "平坝区",
                        "id": "520403"
                    },
                    {
                        "value": "普定县",
                        "id": "520422"
                    },
                    {
                        "value": "镇宁布依族苗族自治县",
                        "id": "520423"
                    },
                    {
                        "value": "关岭布依族苗族自治县",
                        "id": "520424"
                    },
                    {
                        "value": "紫云苗族布依族自治县",
                        "id": "520425"
                    }
                ]
            },
            {
                "value": "毕节市",
                "id": "520500",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "520501"
                    },
                    {
                        "value": "七星关区",
                        "id": "520502"
                    },
                    {
                        "value": "大方县",
                        "id": "520521"
                    },
                    {
                        "value": "黔西县",
                        "id": "520522"
                    },
                    {
                        "value": "金沙县",
                        "id": "520523"
                    },
                    {
                        "value": "织金县",
                        "id": "520524"
                    },
                    {
                        "value": "纳雍县",
                        "id": "520525"
                    },
                    {
                        "value": "威宁彝族回族苗族自治县",
                        "id": "520526"
                    },
                    {
                        "value": "赫章县",
                        "id": "520527"
                    }
                ]
            },
            {
                "value": "铜仁市",
                "id": "520600",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "520601"
                    },
                    {
                        "value": "碧江区",
                        "id": "520602"
                    },
                    {
                        "value": "万山区",
                        "id": "520603"
                    },
                    {
                        "value": "江口县",
                        "id": "520621"
                    },
                    {
                        "value": "玉屏侗族自治县",
                        "id": "520622"
                    },
                    {
                        "value": "石阡县",
                        "id": "520623"
                    },
                    {
                        "value": "思南县",
                        "id": "520624"
                    },
                    {
                        "value": "印江土家族苗族自治县",
                        "id": "520625"
                    },
                    {
                        "value": "德江县",
                        "id": "520626"
                    },
                    {
                        "value": "沿河土家族自治县",
                        "id": "520627"
                    },
                    {
                        "value": "松桃苗族自治县",
                        "id": "520628"
                    }
                ]
            },
            {
                "value": "黔西南布依族苗族自治州",
                "id": "522300",
                "childs": [
                    {
                        "value": "兴义市",
                        "id": "522301"
                    },
                    {
                        "value": "兴仁县",
                        "id": "522322"
                    },
                    {
                        "value": "普安县",
                        "id": "522323"
                    },
                    {
                        "value": "晴隆县",
                        "id": "522324"
                    },
                    {
                        "value": "贞丰县",
                        "id": "522325"
                    },
                    {
                        "value": "望谟县",
                        "id": "522326"
                    },
                    {
                        "value": "册亨县",
                        "id": "522327"
                    },
                    {
                        "value": "安龙县",
                        "id": "522328"
                    }
                ]
            },
            {
                "value": "黔东南苗族侗族自治州",
                "id": "522600",
                "childs": [
                    {
                        "value": "凯里市",
                        "id": "522601"
                    },
                    {
                        "value": "黄平县",
                        "id": "522622"
                    },
                    {
                        "value": "施秉县",
                        "id": "522623"
                    },
                    {
                        "value": "三穗县",
                        "id": "522624"
                    },
                    {
                        "value": "镇远县",
                        "id": "522625"
                    },
                    {
                        "value": "岑巩县",
                        "id": "522626"
                    },
                    {
                        "value": "天柱县",
                        "id": "522627"
                    },
                    {
                        "value": "锦屏县",
                        "id": "522628"
                    },
                    {
                        "value": "剑河县",
                        "id": "522629"
                    },
                    {
                        "value": "台江县",
                        "id": "522630"
                    },
                    {
                        "value": "黎平县",
                        "id": "522631"
                    },
                    {
                        "value": "榕江县",
                        "id": "522632"
                    },
                    {
                        "value": "从江县",
                        "id": "522633"
                    },
                    {
                        "value": "雷山县",
                        "id": "522634"
                    },
                    {
                        "value": "麻江县",
                        "id": "522635"
                    },
                    {
                        "value": "丹寨县",
                        "id": "522636"
                    }
                ]
            },
            {
                "value": "黔南布依族苗族自治州",
                "id": "522700",
                "childs": [
                    {
                        "value": "都匀市",
                        "id": "522701"
                    },
                    {
                        "value": "福泉市",
                        "id": "522702"
                    },
                    {
                        "value": "荔波县",
                        "id": "522722"
                    },
                    {
                        "value": "贵定县",
                        "id": "522723"
                    },
                    {
                        "value": "瓮安县",
                        "id": "522725"
                    },
                    {
                        "value": "独山县",
                        "id": "522726"
                    },
                    {
                        "value": "平塘县",
                        "id": "522727"
                    },
                    {
                        "value": "罗甸县",
                        "id": "522728"
                    },
                    {
                        "value": "长顺县",
                        "id": "522729"
                    },
                    {
                        "value": "龙里县",
                        "id": "522730"
                    },
                    {
                        "value": "惠水县",
                        "id": "522731"
                    },
                    {
                        "value": "三都水族自治县",
                        "id": "522732"
                    }
                ]
            }
        ]
    },
    {
        "value": "云南省",
        "id": "530000",
        "childs": [
            {
                "value": "昆明市",
                "id": "530100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "530101"
                    },
                    {
                        "value": "五华区",
                        "id": "530102"
                    },
                    {
                        "value": "盘龙区",
                        "id": "530103"
                    },
                    {
                        "value": "官渡区",
                        "id": "530111"
                    },
                    {
                        "value": "西山区",
                        "id": "530112"
                    },
                    {
                        "value": "东川区",
                        "id": "530113"
                    },
                    {
                        "value": "呈贡区",
                        "id": "530114"
                    },
                    {
                        "value": "晋宁县",
                        "id": "530122"
                    },
                    {
                        "value": "富民县",
                        "id": "530124"
                    },
                    {
                        "value": "宜良县",
                        "id": "530125"
                    },
                    {
                        "value": "石林彝族自治县",
                        "id": "530126"
                    },
                    {
                        "value": "嵩明县",
                        "id": "530127"
                    },
                    {
                        "value": "禄劝彝族苗族自治县",
                        "id": "530128"
                    },
                    {
                        "value": "寻甸回族彝族自治县",
                        "id": "530129"
                    },
                    {
                        "value": "安宁市",
                        "id": "530181"
                    }
                ]
            },
            {
                "value": "曲靖市",
                "id": "530300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "530301"
                    },
                    {
                        "value": "麒麟区",
                        "id": "530302"
                    },
                    {
                        "value": "马龙县",
                        "id": "530321"
                    },
                    {
                        "value": "陆良县",
                        "id": "530322"
                    },
                    {
                        "value": "师宗县",
                        "id": "530323"
                    },
                    {
                        "value": "罗平县",
                        "id": "530324"
                    },
                    {
                        "value": "富源县",
                        "id": "530325"
                    },
                    {
                        "value": "会泽县",
                        "id": "530326"
                    },
                    {
                        "value": "沾益县",
                        "id": "530328"
                    },
                    {
                        "value": "宣威市",
                        "id": "530381"
                    }
                ]
            },
            {
                "value": "玉溪市",
                "id": "530400",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "530401"
                    },
                    {
                        "value": "红塔区",
                        "id": "530402"
                    },
                    {
                        "value": "江川县",
                        "id": "530421"
                    },
                    {
                        "value": "澄江县",
                        "id": "530422"
                    },
                    {
                        "value": "通海县",
                        "id": "530423"
                    },
                    {
                        "value": "华宁县",
                        "id": "530424"
                    },
                    {
                        "value": "易门县",
                        "id": "530425"
                    },
                    {
                        "value": "峨山彝族自治县",
                        "id": "530426"
                    },
                    {
                        "value": "新平彝族傣族自治县",
                        "id": "530427"
                    },
                    {
                        "value": "元江哈尼族彝族傣族自治县",
                        "id": "530428"
                    }
                ]
            },
            {
                "value": "保山市",
                "id": "530500",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "530501"
                    },
                    {
                        "value": "隆阳区",
                        "id": "530502"
                    },
                    {
                        "value": "施甸县",
                        "id": "530521"
                    },
                    {
                        "value": "腾冲县",
                        "id": "530522"
                    },
                    {
                        "value": "龙陵县",
                        "id": "530523"
                    },
                    {
                        "value": "昌宁县",
                        "id": "530524"
                    }
                ]
            },
            {
                "value": "昭通市",
                "id": "530600",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "530601"
                    },
                    {
                        "value": "昭阳区",
                        "id": "530602"
                    },
                    {
                        "value": "鲁甸县",
                        "id": "530621"
                    },
                    {
                        "value": "巧家县",
                        "id": "530622"
                    },
                    {
                        "value": "盐津县",
                        "id": "530623"
                    },
                    {
                        "value": "大关县",
                        "id": "530624"
                    },
                    {
                        "value": "永善县",
                        "id": "530625"
                    },
                    {
                        "value": "绥江县",
                        "id": "530626"
                    },
                    {
                        "value": "镇雄县",
                        "id": "530627"
                    },
                    {
                        "value": "彝良县",
                        "id": "530628"
                    },
                    {
                        "value": "威信县",
                        "id": "530629"
                    },
                    {
                        "value": "水富县",
                        "id": "530630"
                    }
                ]
            },
            {
                "value": "丽江市",
                "id": "530700",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "530701"
                    },
                    {
                        "value": "古城区",
                        "id": "530702"
                    },
                    {
                        "value": "玉龙纳西族自治县",
                        "id": "530721"
                    },
                    {
                        "value": "永胜县",
                        "id": "530722"
                    },
                    {
                        "value": "华坪县",
                        "id": "530723"
                    },
                    {
                        "value": "宁蒗彝族自治县",
                        "id": "530724"
                    }
                ]
            },
            {
                "value": "普洱市",
                "id": "530800",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "530801"
                    },
                    {
                        "value": "思茅区",
                        "id": "530802"
                    },
                    {
                        "value": "宁洱哈尼族彝族自治县",
                        "id": "530821"
                    },
                    {
                        "value": "墨江哈尼族自治县",
                        "id": "530822"
                    },
                    {
                        "value": "景东彝族自治县",
                        "id": "530823"
                    },
                    {
                        "value": "景谷傣族彝族自治县",
                        "id": "530824"
                    },
                    {
                        "value": "镇沅彝族哈尼族拉祜族自治县",
                        "id": "530825"
                    },
                    {
                        "value": "江城哈尼族彝族自治县",
                        "id": "530826"
                    },
                    {
                        "value": "孟连傣族拉祜族佤族自治县",
                        "id": "530827"
                    },
                    {
                        "value": "澜沧拉祜族自治县",
                        "id": "530828"
                    },
                    {
                        "value": "西盟佤族自治县",
                        "id": "530829"
                    }
                ]
            },
            {
                "value": "临沧市",
                "id": "530900",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "530901"
                    },
                    {
                        "value": "临翔区",
                        "id": "530902"
                    },
                    {
                        "value": "凤庆县",
                        "id": "530921"
                    },
                    {
                        "value": "云县",
                        "id": "530922"
                    },
                    {
                        "value": "永德县",
                        "id": "530923"
                    },
                    {
                        "value": "镇康县",
                        "id": "530924"
                    },
                    {
                        "value": "双江拉祜族佤族布朗族傣族自治县",
                        "id": "530925"
                    },
                    {
                        "value": "耿马傣族佤族自治县",
                        "id": "530926"
                    },
                    {
                        "value": "沧源佤族自治县",
                        "id": "530927"
                    }
                ]
            },
            {
                "value": "楚雄彝族自治州",
                "id": "532300",
                "childs": [
                    {
                        "value": "楚雄市",
                        "id": "532301"
                    },
                    {
                        "value": "双柏县",
                        "id": "532322"
                    },
                    {
                        "value": "牟定县",
                        "id": "532323"
                    },
                    {
                        "value": "南华县",
                        "id": "532324"
                    },
                    {
                        "value": "姚安县",
                        "id": "532325"
                    },
                    {
                        "value": "大姚县",
                        "id": "532326"
                    },
                    {
                        "value": "永仁县",
                        "id": "532327"
                    },
                    {
                        "value": "元谋县",
                        "id": "532328"
                    },
                    {
                        "value": "武定县",
                        "id": "532329"
                    },
                    {
                        "value": "禄丰县",
                        "id": "532331"
                    }
                ]
            },
            {
                "value": "红河哈尼族彝族自治州",
                "id": "532500",
                "childs": [
                    {
                        "value": "个旧市",
                        "id": "532501"
                    },
                    {
                        "value": "开远市",
                        "id": "532502"
                    },
                    {
                        "value": "蒙自市",
                        "id": "532503"
                    },
                    {
                        "value": "弥勒市",
                        "id": "532504"
                    },
                    {
                        "value": "屏边苗族自治县",
                        "id": "532523"
                    },
                    {
                        "value": "建水县",
                        "id": "532524"
                    },
                    {
                        "value": "石屏县",
                        "id": "532525"
                    },
                    {
                        "value": "泸西县",
                        "id": "532527"
                    },
                    {
                        "value": "元阳县",
                        "id": "532528"
                    },
                    {
                        "value": "红河县",
                        "id": "532529"
                    },
                    {
                        "value": "金平苗族瑶族傣族自治县",
                        "id": "532530"
                    },
                    {
                        "value": "绿春县",
                        "id": "532531"
                    },
                    {
                        "value": "河口瑶族自治县",
                        "id": "532532"
                    }
                ]
            },
            {
                "value": "文山壮族苗族自治州",
                "id": "532600",
                "childs": [
                    {
                        "value": "文山市",
                        "id": "532601"
                    },
                    {
                        "value": "砚山县",
                        "id": "532622"
                    },
                    {
                        "value": "西畴县",
                        "id": "532623"
                    },
                    {
                        "value": "麻栗坡县",
                        "id": "532624"
                    },
                    {
                        "value": "马关县",
                        "id": "532625"
                    },
                    {
                        "value": "丘北县",
                        "id": "532626"
                    },
                    {
                        "value": "广南县",
                        "id": "532627"
                    },
                    {
                        "value": "富宁县",
                        "id": "532628"
                    }
                ]
            },
            {
                "value": "西双版纳傣族自治州",
                "id": "532800",
                "childs": [
                    {
                        "value": "景洪市",
                        "id": "532801"
                    },
                    {
                        "value": "勐海县",
                        "id": "532822"
                    },
                    {
                        "value": "勐腊县",
                        "id": "532823"
                    }
                ]
            },
            {
                "value": "大理白族自治州",
                "id": "532900",
                "childs": [
                    {
                        "value": "大理市",
                        "id": "532901"
                    },
                    {
                        "value": "漾濞彝族自治县",
                        "id": "532922"
                    },
                    {
                        "value": "祥云县",
                        "id": "532923"
                    },
                    {
                        "value": "宾川县",
                        "id": "532924"
                    },
                    {
                        "value": "弥渡县",
                        "id": "532925"
                    },
                    {
                        "value": "南涧彝族自治县",
                        "id": "532926"
                    },
                    {
                        "value": "巍山彝族回族自治县",
                        "id": "532927"
                    },
                    {
                        "value": "永平县",
                        "id": "532928"
                    },
                    {
                        "value": "云龙县",
                        "id": "532929"
                    },
                    {
                        "value": "洱源县",
                        "id": "532930"
                    },
                    {
                        "value": "剑川县",
                        "id": "532931"
                    },
                    {
                        "value": "鹤庆县",
                        "id": "532932"
                    }
                ]
            },
            {
                "value": "德宏傣族景颇族自治州",
                "id": "533100",
                "childs": [
                    {
                        "value": "瑞丽市",
                        "id": "533102"
                    },
                    {
                        "value": "芒市",
                        "id": "533103"
                    },
                    {
                        "value": "梁河县",
                        "id": "533122"
                    },
                    {
                        "value": "盈江县",
                        "id": "533123"
                    },
                    {
                        "value": "陇川县",
                        "id": "533124"
                    }
                ]
            },
            {
                "value": "怒江傈僳族自治州",
                "id": "533300",
                "childs": [
                    {
                        "value": "泸水县",
                        "id": "533321"
                    },
                    {
                        "value": "福贡县",
                        "id": "533323"
                    },
                    {
                        "value": "贡山独龙族怒族自治县",
                        "id": "533324"
                    },
                    {
                        "value": "兰坪白族普米族自治县",
                        "id": "533325"
                    }
                ]
            },
            {
                "value": "迪庆藏族自治州",
                "id": "533400",
                "childs": [
                    {
                        "value": "香格里拉市",
                        "id": "533401"
                    },
                    {
                        "value": "德钦县",
                        "id": "533422"
                    },
                    {
                        "value": "维西傈僳族自治县",
                        "id": "533423"
                    }
                ]
            }
        ]
    },
    {
        "value": "西藏自治区",
        "id": "540000",
        "childs": [
            {
                "value": "拉萨市",
                "id": "540100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "540101"
                    },
                    {
                        "value": "城关区",
                        "id": "540102"
                    },
                    {
                        "value": "林周县",
                        "id": "540121"
                    },
                    {
                        "value": "当雄县",
                        "id": "540122"
                    },
                    {
                        "value": "尼木县",
                        "id": "540123"
                    },
                    {
                        "value": "曲水县",
                        "id": "540124"
                    },
                    {
                        "value": "堆龙德庆县",
                        "id": "540125"
                    },
                    {
                        "value": "达孜县",
                        "id": "540126"
                    },
                    {
                        "value": "墨竹工卡县",
                        "id": "540127"
                    }
                ]
            },
            {
                "value": "日喀则市",
                "id": "540200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "540201"
                    },
                    {
                        "value": "桑珠孜区",
                        "id": "540202"
                    },
                    {
                        "value": "南木林县",
                        "id": "540221"
                    },
                    {
                        "value": "江孜县",
                        "id": "540222"
                    },
                    {
                        "value": "定日县",
                        "id": "540223"
                    },
                    {
                        "value": "萨迦县",
                        "id": "540224"
                    },
                    {
                        "value": "拉孜县",
                        "id": "540225"
                    },
                    {
                        "value": "昂仁县",
                        "id": "540226"
                    },
                    {
                        "value": "谢通门县",
                        "id": "540227"
                    },
                    {
                        "value": "白朗县",
                        "id": "540228"
                    },
                    {
                        "value": "仁布县",
                        "id": "540229"
                    },
                    {
                        "value": "康马县",
                        "id": "540230"
                    },
                    {
                        "value": "定结县",
                        "id": "540231"
                    },
                    {
                        "value": "仲巴县",
                        "id": "540232"
                    },
                    {
                        "value": "亚东县",
                        "id": "540233"
                    },
                    {
                        "value": "吉隆县",
                        "id": "540234"
                    },
                    {
                        "value": "聂拉木县",
                        "id": "540235"
                    },
                    {
                        "value": "萨嘎县",
                        "id": "540236"
                    },
                    {
                        "value": "岗巴县",
                        "id": "540237"
                    }
                ]
            },
            {
                "value": "昌都市",
                "id": "540300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "540301"
                    },
                    {
                        "value": "卡若区",
                        "id": "540302"
                    },
                    {
                        "value": "江达县",
                        "id": "540321"
                    },
                    {
                        "value": "贡觉县",
                        "id": "540322"
                    },
                    {
                        "value": "类乌齐县",
                        "id": "540323"
                    },
                    {
                        "value": "丁青县",
                        "id": "540324"
                    },
                    {
                        "value": "察雅县",
                        "id": "540325"
                    },
                    {
                        "value": "八宿县",
                        "id": "540326"
                    },
                    {
                        "value": "左贡县",
                        "id": "540327"
                    },
                    {
                        "value": "芒康县",
                        "id": "540328"
                    },
                    {
                        "value": "洛隆县",
                        "id": "540329"
                    },
                    {
                        "value": "边坝县",
                        "id": "540330"
                    }
                ]
            },
            {
                "value": "山南地区",
                "id": "542200",
                "childs": [
                    {
                        "value": "乃东县",
                        "id": "542221"
                    },
                    {
                        "value": "扎囊县",
                        "id": "542222"
                    },
                    {
                        "value": "贡嘎县",
                        "id": "542223"
                    },
                    {
                        "value": "桑日县",
                        "id": "542224"
                    },
                    {
                        "value": "琼结县",
                        "id": "542225"
                    },
                    {
                        "value": "曲松县",
                        "id": "542226"
                    },
                    {
                        "value": "措美县",
                        "id": "542227"
                    },
                    {
                        "value": "洛扎县",
                        "id": "542228"
                    },
                    {
                        "value": "加查县",
                        "id": "542229"
                    },
                    {
                        "value": "隆子县",
                        "id": "542231"
                    },
                    {
                        "value": "错那县",
                        "id": "542232"
                    },
                    {
                        "value": "浪卡子县",
                        "id": "542233"
                    }
                ]
            },
            {
                "value": "那曲地区",
                "id": "542400",
                "childs": [
                    {
                        "value": "那曲县",
                        "id": "542421"
                    },
                    {
                        "value": "嘉黎县",
                        "id": "542422"
                    },
                    {
                        "value": "比如县",
                        "id": "542423"
                    },
                    {
                        "value": "聂荣县",
                        "id": "542424"
                    },
                    {
                        "value": "安多县",
                        "id": "542425"
                    },
                    {
                        "value": "申扎县",
                        "id": "542426"
                    },
                    {
                        "value": "索县",
                        "id": "542427"
                    },
                    {
                        "value": "班戈县",
                        "id": "542428"
                    },
                    {
                        "value": "巴青县",
                        "id": "542429"
                    },
                    {
                        "value": "尼玛县",
                        "id": "542430"
                    },
                    {
                        "value": "双湖县",
                        "id": "542431"
                    }
                ]
            },
            {
                "value": "阿里地区",
                "id": "542500",
                "childs": [
                    {
                        "value": "普兰县",
                        "id": "542521"
                    },
                    {
                        "value": "札达县",
                        "id": "542522"
                    },
                    {
                        "value": "噶尔县",
                        "id": "542523"
                    },
                    {
                        "value": "日土县",
                        "id": "542524"
                    },
                    {
                        "value": "革吉县",
                        "id": "542525"
                    },
                    {
                        "value": "改则县",
                        "id": "542526"
                    },
                    {
                        "value": "措勤县",
                        "id": "542527"
                    }
                ]
            },
            {
                "value": "林芝地区",
                "id": "542600",
                "childs": [
                    {
                        "value": "林芝县",
                        "id": "542621"
                    },
                    {
                        "value": "工布江达县",
                        "id": "542622"
                    },
                    {
                        "value": "米林县",
                        "id": "542623"
                    },
                    {
                        "value": "墨脱县",
                        "id": "542624"
                    },
                    {
                        "value": "波密县",
                        "id": "542625"
                    },
                    {
                        "value": "察隅县",
                        "id": "542626"
                    },
                    {
                        "value": "朗县",
                        "id": "542627"
                    }
                ]
            }
        ]
    },
    {
        "value": "陕西省",
        "id": "610000",
        "childs": [
            {
                "value": "西安市",
                "id": "610100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "610101"
                    },
                    {
                        "value": "新城区",
                        "id": "610102"
                    },
                    {
                        "value": "碑林区",
                        "id": "610103"
                    },
                    {
                        "value": "莲湖区",
                        "id": "610104"
                    },
                    {
                        "value": "灞桥区",
                        "id": "610111"
                    },
                    {
                        "value": "未央区",
                        "id": "610112"
                    },
                    {
                        "value": "雁塔区",
                        "id": "610113"
                    },
                    {
                        "value": "阎良区",
                        "id": "610114"
                    },
                    {
                        "value": "临潼区",
                        "id": "610115"
                    },
                    {
                        "value": "长安区",
                        "id": "610116"
                    },
                    {
                        "value": "高陵区",
                        "id": "610117"
                    },
                    {
                        "value": "蓝田县",
                        "id": "610122"
                    },
                    {
                        "value": "周至县",
                        "id": "610124"
                    },
                    {
                        "value": "户县",
                        "id": "610125"
                    }
                ]
            },
            {
                "value": "铜川市",
                "id": "610200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "610201"
                    },
                    {
                        "value": "王益区",
                        "id": "610202"
                    },
                    {
                        "value": "印台区",
                        "id": "610203"
                    },
                    {
                        "value": "耀州区",
                        "id": "610204"
                    },
                    {
                        "value": "宜君县",
                        "id": "610222"
                    }
                ]
            },
            {
                "value": "宝鸡市",
                "id": "610300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "610301"
                    },
                    {
                        "value": "渭滨区",
                        "id": "610302"
                    },
                    {
                        "value": "金台区",
                        "id": "610303"
                    },
                    {
                        "value": "陈仓区",
                        "id": "610304"
                    },
                    {
                        "value": "凤翔县",
                        "id": "610322"
                    },
                    {
                        "value": "岐山县",
                        "id": "610323"
                    },
                    {
                        "value": "扶风县",
                        "id": "610324"
                    },
                    {
                        "value": "眉县",
                        "id": "610326"
                    },
                    {
                        "value": "陇县",
                        "id": "610327"
                    },
                    {
                        "value": "千阳县",
                        "id": "610328"
                    },
                    {
                        "value": "麟游县",
                        "id": "610329"
                    },
                    {
                        "value": "凤县",
                        "id": "610330"
                    },
                    {
                        "value": "太白县",
                        "id": "610331"
                    }
                ]
            },
            {
                "value": "咸阳市",
                "id": "610400",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "610401"
                    },
                    {
                        "value": "秦都区",
                        "id": "610402"
                    },
                    {
                        "value": "杨陵区",
                        "id": "610403"
                    },
                    {
                        "value": "渭城区",
                        "id": "610404"
                    },
                    {
                        "value": "三原县",
                        "id": "610422"
                    },
                    {
                        "value": "泾阳县",
                        "id": "610423"
                    },
                    {
                        "value": "乾县",
                        "id": "610424"
                    },
                    {
                        "value": "礼泉县",
                        "id": "610425"
                    },
                    {
                        "value": "永寿县",
                        "id": "610426"
                    },
                    {
                        "value": "彬县",
                        "id": "610427"
                    },
                    {
                        "value": "长武县",
                        "id": "610428"
                    },
                    {
                        "value": "旬邑县",
                        "id": "610429"
                    },
                    {
                        "value": "淳化县",
                        "id": "610430"
                    },
                    {
                        "value": "武功县",
                        "id": "610431"
                    },
                    {
                        "value": "兴平市",
                        "id": "610481"
                    }
                ]
            },
            {
                "value": "渭南市",
                "id": "610500",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "610501"
                    },
                    {
                        "value": "临渭区",
                        "id": "610502"
                    },
                    {
                        "value": "华县",
                        "id": "610521"
                    },
                    {
                        "value": "潼关县",
                        "id": "610522"
                    },
                    {
                        "value": "大荔县",
                        "id": "610523"
                    },
                    {
                        "value": "合阳县",
                        "id": "610524"
                    },
                    {
                        "value": "澄城县",
                        "id": "610525"
                    },
                    {
                        "value": "蒲城县",
                        "id": "610526"
                    },
                    {
                        "value": "白水县",
                        "id": "610527"
                    },
                    {
                        "value": "富平县",
                        "id": "610528"
                    },
                    {
                        "value": "韩城市",
                        "id": "610581"
                    },
                    {
                        "value": "华阴市",
                        "id": "610582"
                    }
                ]
            },
            {
                "value": "延安市",
                "id": "610600",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "610601"
                    },
                    {
                        "value": "宝塔区",
                        "id": "610602"
                    },
                    {
                        "value": "延长县",
                        "id": "610621"
                    },
                    {
                        "value": "延川县",
                        "id": "610622"
                    },
                    {
                        "value": "子长县",
                        "id": "610623"
                    },
                    {
                        "value": "安塞县",
                        "id": "610624"
                    },
                    {
                        "value": "志丹县",
                        "id": "610625"
                    },
                    {
                        "value": "吴起县",
                        "id": "610626"
                    },
                    {
                        "value": "甘泉县",
                        "id": "610627"
                    },
                    {
                        "value": "富县",
                        "id": "610628"
                    },
                    {
                        "value": "洛川县",
                        "id": "610629"
                    },
                    {
                        "value": "宜川县",
                        "id": "610630"
                    },
                    {
                        "value": "黄龙县",
                        "id": "610631"
                    },
                    {
                        "value": "黄陵县",
                        "id": "610632"
                    }
                ]
            },
            {
                "value": "汉中市",
                "id": "610700",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "610701"
                    },
                    {
                        "value": "汉台区",
                        "id": "610702"
                    },
                    {
                        "value": "南郑县",
                        "id": "610721"
                    },
                    {
                        "value": "城固县",
                        "id": "610722"
                    },
                    {
                        "value": "洋县",
                        "id": "610723"
                    },
                    {
                        "value": "西乡县",
                        "id": "610724"
                    },
                    {
                        "value": "勉县",
                        "id": "610725"
                    },
                    {
                        "value": "宁强县",
                        "id": "610726"
                    },
                    {
                        "value": "略阳县",
                        "id": "610727"
                    },
                    {
                        "value": "镇巴县",
                        "id": "610728"
                    },
                    {
                        "value": "留坝县",
                        "id": "610729"
                    },
                    {
                        "value": "佛坪县",
                        "id": "610730"
                    }
                ]
            },
            {
                "value": "榆林市",
                "id": "610800",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "610801"
                    },
                    {
                        "value": "榆阳区",
                        "id": "610802"
                    },
                    {
                        "value": "神木县",
                        "id": "610821"
                    },
                    {
                        "value": "府谷县",
                        "id": "610822"
                    },
                    {
                        "value": "横山县",
                        "id": "610823"
                    },
                    {
                        "value": "靖边县",
                        "id": "610824"
                    },
                    {
                        "value": "定边县",
                        "id": "610825"
                    },
                    {
                        "value": "绥德县",
                        "id": "610826"
                    },
                    {
                        "value": "米脂县",
                        "id": "610827"
                    },
                    {
                        "value": "佳县",
                        "id": "610828"
                    },
                    {
                        "value": "吴堡县",
                        "id": "610829"
                    },
                    {
                        "value": "清涧县",
                        "id": "610830"
                    },
                    {
                        "value": "子洲县",
                        "id": "610831"
                    }
                ]
            },
            {
                "value": "安康市",
                "id": "610900",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "610901"
                    },
                    {
                        "value": "汉阴县",
                        "id": "610921"
                    },
                    {
                        "value": "石泉县",
                        "id": "610922"
                    },
                    {
                        "value": "宁陕县",
                        "id": "610923"
                    },
                    {
                        "value": "紫阳县",
                        "id": "610924"
                    },
                    {
                        "value": "岚皋县",
                        "id": "610925"
                    },
                    {
                        "value": "平利县",
                        "id": "610926"
                    },
                    {
                        "value": "镇坪县",
                        "id": "610927"
                    },
                    {
                        "value": "旬阳县",
                        "id": "610928"
                    },
                    {
                        "value": "白河县",
                        "id": "610929"
                    }
                ]
            },
            {
                "value": "商洛市",
                "id": "611000",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "611001"
                    },
                    {
                        "value": "商州区",
                        "id": "611002"
                    },
                    {
                        "value": "洛南县",
                        "id": "611021"
                    },
                    {
                        "value": "丹凤县",
                        "id": "611022"
                    },
                    {
                        "value": "商南县",
                        "id": "611023"
                    },
                    {
                        "value": "山阳县",
                        "id": "611024"
                    },
                    {
                        "value": "镇安县",
                        "id": "611025"
                    },
                    {
                        "value": "柞水县",
                        "id": "611026"
                    }
                ]
            }
        ]
    },
    {
        "value": "甘肃省",
        "id": "620000",
        "childs": [
            {
                "value": "兰州市",
                "id": "620100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "620101"
                    },
                    {
                        "value": "城关区",
                        "id": "620102"
                    },
                    {
                        "value": "七里河区",
                        "id": "620103"
                    },
                    {
                        "value": "西固区",
                        "id": "620104"
                    },
                    {
                        "value": "安宁区",
                        "id": "620105"
                    },
                    {
                        "value": "红古区",
                        "id": "620111"
                    },
                    {
                        "value": "永登县",
                        "id": "620121"
                    },
                    {
                        "value": "皋兰县",
                        "id": "620122"
                    },
                    {
                        "value": "榆中县",
                        "id": "620123"
                    }
                ]
            },
            {
                "value": "嘉峪关市",
                "id": "620200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "620201"
                    }
                ]
            },
            {
                "value": "金昌市",
                "id": "620300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "620301"
                    },
                    {
                        "value": "金川区",
                        "id": "620302"
                    },
                    {
                        "value": "永昌县",
                        "id": "620321"
                    }
                ]
            },
            {
                "value": "白银市",
                "id": "620400",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "620401"
                    },
                    {
                        "value": "白银区",
                        "id": "620402"
                    },
                    {
                        "value": "平川区",
                        "id": "620403"
                    },
                    {
                        "value": "靖远县",
                        "id": "620421"
                    },
                    {
                        "value": "会宁县",
                        "id": "620422"
                    },
                    {
                        "value": "景泰县",
                        "id": "620423"
                    }
                ]
            },
            {
                "value": "天水市",
                "id": "620500",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "620501"
                    },
                    {
                        "value": "秦州区",
                        "id": "620502"
                    },
                    {
                        "value": "麦积区",
                        "id": "620503"
                    },
                    {
                        "value": "清水县",
                        "id": "620521"
                    },
                    {
                        "value": "秦安县",
                        "id": "620522"
                    },
                    {
                        "value": "甘谷县",
                        "id": "620523"
                    },
                    {
                        "value": "武山县",
                        "id": "620524"
                    },
                    {
                        "value": "张家川回族自治县",
                        "id": "620525"
                    }
                ]
            },
            {
                "value": "武威市",
                "id": "620600",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "620601"
                    },
                    {
                        "value": "凉州区",
                        "id": "620602"
                    },
                    {
                        "value": "民勤县",
                        "id": "620621"
                    },
                    {
                        "value": "古浪县",
                        "id": "620622"
                    },
                    {
                        "value": "天祝藏族自治县",
                        "id": "620623"
                    }
                ]
            },
            {
                "value": "张掖市",
                "id": "620700",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "620701"
                    },
                    {
                        "value": "甘州区",
                        "id": "620702"
                    },
                    {
                        "value": "肃南裕固族自治县",
                        "id": "620721"
                    },
                    {
                        "value": "民乐县",
                        "id": "620722"
                    },
                    {
                        "value": "临泽县",
                        "id": "620723"
                    },
                    {
                        "value": "高台县",
                        "id": "620724"
                    },
                    {
                        "value": "山丹县",
                        "id": "620725"
                    }
                ]
            },
            {
                "value": "平凉市",
                "id": "620800",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "620801"
                    },
                    {
                        "value": "崆峒区",
                        "id": "620802"
                    },
                    {
                        "value": "泾川县",
                        "id": "620821"
                    },
                    {
                        "value": "灵台县",
                        "id": "620822"
                    },
                    {
                        "value": "崇信县",
                        "id": "620823"
                    },
                    {
                        "value": "华亭县",
                        "id": "620824"
                    },
                    {
                        "value": "庄浪县",
                        "id": "620825"
                    },
                    {
                        "value": "静宁县",
                        "id": "620826"
                    }
                ]
            },
            {
                "value": "酒泉市",
                "id": "620900",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "620901"
                    },
                    {
                        "value": "肃州区",
                        "id": "620902"
                    },
                    {
                        "value": "金塔县",
                        "id": "620921"
                    },
                    {
                        "value": "瓜州县",
                        "id": "620922"
                    },
                    {
                        "value": "肃北蒙古族自治县",
                        "id": "620923"
                    },
                    {
                        "value": "阿克塞哈萨克族自治县",
                        "id": "620924"
                    },
                    {
                        "value": "玉门市",
                        "id": "620981"
                    },
                    {
                        "value": "敦煌市",
                        "id": "620982"
                    }
                ]
            },
            {
                "value": "庆阳市",
                "id": "621000",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "621001"
                    },
                    {
                        "value": "西峰区",
                        "id": "621002"
                    },
                    {
                        "value": "庆城县",
                        "id": "621021"
                    },
                    {
                        "value": "环县",
                        "id": "621022"
                    },
                    {
                        "value": "华池县",
                        "id": "621023"
                    },
                    {
                        "value": "合水县",
                        "id": "621024"
                    },
                    {
                        "value": "正宁县",
                        "id": "621025"
                    },
                    {
                        "value": "宁县",
                        "id": "621026"
                    },
                    {
                        "value": "镇原县",
                        "id": "621027"
                    }
                ]
            },
            {
                "value": "定西市",
                "id": "621100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "621101"
                    },
                    {
                        "value": "安定区",
                        "id": "621102"
                    },
                    {
                        "value": "通渭县",
                        "id": "621121"
                    },
                    {
                        "value": "陇西县",
                        "id": "621122"
                    },
                    {
                        "value": "渭源县",
                        "id": "621123"
                    },
                    {
                        "value": "临洮县",
                        "id": "621124"
                    },
                    {
                        "value": "漳县",
                        "id": "621125"
                    },
                    {
                        "value": "岷县",
                        "id": "621126"
                    }
                ]
            },
            {
                "value": "陇南市",
                "id": "621200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "621201"
                    },
                    {
                        "value": "武都区",
                        "id": "621202"
                    },
                    {
                        "value": "成县",
                        "id": "621221"
                    },
                    {
                        "value": "文县",
                        "id": "621222"
                    },
                    {
                        "value": "宕昌县",
                        "id": "621223"
                    },
                    {
                        "value": "康县",
                        "id": "621224"
                    },
                    {
                        "value": "西和县",
                        "id": "621225"
                    },
                    {
                        "value": "礼县",
                        "id": "621226"
                    },
                    {
                        "value": "徽县",
                        "id": "621227"
                    },
                    {
                        "value": "两当县",
                        "id": "621228"
                    }
                ]
            },
            {
                "value": "临夏回族自治州",
                "id": "622900",
                "childs": [
                    {
                        "value": "临夏市",
                        "id": "622901"
                    },
                    {
                        "value": "临夏县",
                        "id": "622921"
                    },
                    {
                        "value": "康乐县",
                        "id": "622922"
                    },
                    {
                        "value": "永靖县",
                        "id": "622923"
                    },
                    {
                        "value": "广河县",
                        "id": "622924"
                    },
                    {
                        "value": "和政县",
                        "id": "622925"
                    },
                    {
                        "value": "东乡族自治县",
                        "id": "622926"
                    },
                    {
                        "value": "积石山保安族东乡族撒拉族自治县",
                        "id": "622927"
                    }
                ]
            },
            {
                "value": "甘南藏族自治州",
                "id": "623000",
                "childs": [
                    {
                        "value": "合作市",
                        "id": "623001"
                    },
                    {
                        "value": "临潭县",
                        "id": "623021"
                    },
                    {
                        "value": "卓尼县",
                        "id": "623022"
                    },
                    {
                        "value": "舟曲县",
                        "id": "623023"
                    },
                    {
                        "value": "迭部县",
                        "id": "623024"
                    },
                    {
                        "value": "玛曲县",
                        "id": "623025"
                    },
                    {
                        "value": "碌曲县",
                        "id": "623026"
                    },
                    {
                        "value": "夏河县",
                        "id": "623027"
                    }
                ]
            }
        ]
    },
    {
        "value": "青海省",
        "id": "630000",
        "childs": [
            {
                "value": "西宁市",
                "id": "630100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "630101"
                    },
                    {
                        "value": "城东区",
                        "id": "630102"
                    },
                    {
                        "value": "城中区",
                        "id": "630103"
                    },
                    {
                        "value": "城西区",
                        "id": "630104"
                    },
                    {
                        "value": "城北区",
                        "id": "630105"
                    },
                    {
                        "value": "大通回族土族自治县",
                        "id": "630121"
                    },
                    {
                        "value": "湟中县",
                        "id": "630122"
                    },
                    {
                        "value": "湟源县",
                        "id": "630123"
                    }
                ]
            },
            {
                "value": "海东市",
                "id": "630200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "630201"
                    },
                    {
                        "value": "乐都区",
                        "id": "630202"
                    },
                    {
                        "value": "平安县",
                        "id": "630221"
                    },
                    {
                        "value": "民和回族土族自治县",
                        "id": "630222"
                    },
                    {
                        "value": "互助土族自治县",
                        "id": "630223"
                    },
                    {
                        "value": "化隆回族自治县",
                        "id": "630224"
                    },
                    {
                        "value": "循化撒拉族自治县",
                        "id": "630225"
                    }
                ]
            },
            {
                "value": "海北藏族自治州",
                "id": "632200",
                "childs": [
                    {
                        "value": "门源回族自治县",
                        "id": "632221"
                    },
                    {
                        "value": "祁连县",
                        "id": "632222"
                    },
                    {
                        "value": "海晏县",
                        "id": "632223"
                    },
                    {
                        "value": "刚察县",
                        "id": "632224"
                    }
                ]
            },
            {
                "value": "黄南藏族自治州",
                "id": "632300",
                "childs": [
                    {
                        "value": "同仁县",
                        "id": "632321"
                    },
                    {
                        "value": "尖扎县",
                        "id": "632322"
                    },
                    {
                        "value": "泽库县",
                        "id": "632323"
                    },
                    {
                        "value": "河南蒙古族自治县",
                        "id": "632324"
                    }
                ]
            },
            {
                "value": "海南藏族自治州",
                "id": "632500",
                "childs": [
                    {
                        "value": "共和县",
                        "id": "632521"
                    },
                    {
                        "value": "同德县",
                        "id": "632522"
                    },
                    {
                        "value": "贵德县",
                        "id": "632523"
                    },
                    {
                        "value": "兴海县",
                        "id": "632524"
                    },
                    {
                        "value": "贵南县",
                        "id": "632525"
                    }
                ]
            },
            {
                "value": "果洛藏族自治州",
                "id": "632600",
                "childs": [
                    {
                        "value": "玛沁县",
                        "id": "632621"
                    },
                    {
                        "value": "班玛县",
                        "id": "632622"
                    },
                    {
                        "value": "甘德县",
                        "id": "632623"
                    },
                    {
                        "value": "达日县",
                        "id": "632624"
                    },
                    {
                        "value": "久治县",
                        "id": "632625"
                    },
                    {
                        "value": "玛多县",
                        "id": "632626"
                    }
                ]
            },
            {
                "value": "玉树藏族自治州",
                "id": "632700",
                "childs": [
                    {
                        "value": "玉树市",
                        "id": "632701"
                    },
                    {
                        "value": "杂多县",
                        "id": "632722"
                    },
                    {
                        "value": "称多县",
                        "id": "632723"
                    },
                    {
                        "value": "治多县",
                        "id": "632724"
                    },
                    {
                        "value": "囊谦县",
                        "id": "632725"
                    },
                    {
                        "value": "曲麻莱县",
                        "id": "632726"
                    }
                ]
            },
            {
                "value": "海西蒙古族藏族自治州",
                "id": "632800",
                "childs": [
                    {
                        "value": "格尔木市",
                        "id": "632801"
                    },
                    {
                        "value": "德令哈市",
                        "id": "632802"
                    },
                    {
                        "value": "乌兰县",
                        "id": "632821"
                    },
                    {
                        "value": "都兰县",
                        "id": "632822"
                    },
                    {
                        "value": "天峻县",
                        "id": "632823"
                    }
                ]
            }
        ]
    },
    {
        "value": "宁夏回族自治区",
        "id": "640000",
        "childs": [
            {
                "value": "银川市",
                "id": "640100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "640101"
                    },
                    {
                        "value": "兴庆区",
                        "id": "640104"
                    },
                    {
                        "value": "西夏区",
                        "id": "640105"
                    },
                    {
                        "value": "金凤区",
                        "id": "640106"
                    },
                    {
                        "value": "永宁县",
                        "id": "640121"
                    },
                    {
                        "value": "贺兰县",
                        "id": "640122"
                    },
                    {
                        "value": "灵武市",
                        "id": "640181"
                    }
                ]
            },
            {
                "value": "石嘴山市",
                "id": "640200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "640201"
                    },
                    {
                        "value": "大武口区",
                        "id": "640202"
                    },
                    {
                        "value": "惠农区",
                        "id": "640205"
                    },
                    {
                        "value": "平罗县",
                        "id": "640221"
                    }
                ]
            },
            {
                "value": "吴忠市",
                "id": "640300",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "640301"
                    },
                    {
                        "value": "利通区",
                        "id": "640302"
                    },
                    {
                        "value": "红寺堡区",
                        "id": "640303"
                    },
                    {
                        "value": "盐池县",
                        "id": "640323"
                    },
                    {
                        "value": "同心县",
                        "id": "640324"
                    },
                    {
                        "value": "青铜峡市",
                        "id": "640381"
                    }
                ]
            },
            {
                "value": "固原市",
                "id": "640400",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "640401"
                    },
                    {
                        "value": "原州区",
                        "id": "640402"
                    },
                    {
                        "value": "西吉县",
                        "id": "640422"
                    },
                    {
                        "value": "隆德县",
                        "id": "640423"
                    },
                    {
                        "value": "泾源县",
                        "id": "640424"
                    },
                    {
                        "value": "彭阳县",
                        "id": "640425"
                    }
                ]
            },
            {
                "value": "中卫市",
                "id": "640500",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "640501"
                    },
                    {
                        "value": "沙坡头区",
                        "id": "640502"
                    },
                    {
                        "value": "中宁县",
                        "id": "640521"
                    },
                    {
                        "value": "海原县",
                        "id": "640522"
                    }
                ]
            }
        ]
    },
    {
        "value": "新疆维吾尔自治区",
        "id": "650000",
        "childs": [
            {
                "value": "乌鲁木齐市",
                "id": "650100",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "650101"
                    },
                    {
                        "value": "天山区",
                        "id": "650102"
                    },
                    {
                        "value": "沙依巴克区",
                        "id": "650103"
                    },
                    {
                        "value": "新市区",
                        "id": "650104"
                    },
                    {
                        "value": "水磨沟区",
                        "id": "650105"
                    },
                    {
                        "value": "头屯河区",
                        "id": "650106"
                    },
                    {
                        "value": "达坂城区",
                        "id": "650107"
                    },
                    {
                        "value": "米东区",
                        "id": "650109"
                    },
                    {
                        "value": "乌鲁木齐县",
                        "id": "650121"
                    }
                ]
            },
            {
                "value": "克拉玛依市",
                "id": "650200",
                "childs": [
                    {
                        "value": "市辖区",
                        "id": "650201"
                    },
                    {
                        "value": "独山子区",
                        "id": "650202"
                    },
                    {
                        "value": "克拉玛依区",
                        "id": "650203"
                    },
                    {
                        "value": "白碱滩区",
                        "id": "650204"
                    },
                    {
                        "value": "乌尔禾区",
                        "id": "650205"
                    }
                ]
            },
            {
                "value": "吐鲁番地区",
                "id": "652100",
                "childs": [
                    {
                        "value": "吐鲁番市",
                        "id": "652101"
                    },
                    {
                        "value": "鄯善县",
                        "id": "652122"
                    },
                    {
                        "value": "托克逊县",
                        "id": "652123"
                    }
                ]
            },
            {
                "value": "哈密地区",
                "id": "652200",
                "childs": [
                    {
                        "value": "哈密市",
                        "id": "652201"
                    },
                    {
                        "value": "巴里坤哈萨克自治县",
                        "id": "652222"
                    },
                    {
                        "value": "伊吾县",
                        "id": "652223"
                    }
                ]
            },
            {
                "value": "昌吉回族自治州",
                "id": "652300",
                "childs": [
                    {
                        "value": "昌吉市",
                        "id": "652301"
                    },
                    {
                        "value": "阜康市",
                        "id": "652302"
                    },
                    {
                        "value": "呼图壁县",
                        "id": "652323"
                    },
                    {
                        "value": "玛纳斯县",
                        "id": "652324"
                    },
                    {
                        "value": "奇台县",
                        "id": "652325"
                    },
                    {
                        "value": "吉木萨尔县",
                        "id": "652327"
                    },
                    {
                        "value": "木垒哈萨克自治县",
                        "id": "652328"
                    }
                ]
            },
            {
                "value": "博尔塔拉蒙古自治州",
                "id": "652700",
                "childs": [
                    {
                        "value": "博乐市",
                        "id": "652701"
                    },
                    {
                        "value": "阿拉山口市",
                        "id": "652702"
                    },
                    {
                        "value": "精河县",
                        "id": "652722"
                    },
                    {
                        "value": "温泉县",
                        "id": "652723"
                    }
                ]
            },
            {
                "value": "巴音郭楞蒙古自治州",
                "id": "652800",
                "childs": [
                    {
                        "value": "库尔勒市",
                        "id": "652801"
                    },
                    {
                        "value": "轮台县",
                        "id": "652822"
                    },
                    {
                        "value": "尉犁县",
                        "id": "652823"
                    },
                    {
                        "value": "若羌县",
                        "id": "652824"
                    },
                    {
                        "value": "且末县",
                        "id": "652825"
                    },
                    {
                        "value": "焉耆回族自治县",
                        "id": "652826"
                    },
                    {
                        "value": "和静县",
                        "id": "652827"
                    },
                    {
                        "value": "和硕县",
                        "id": "652828"
                    },
                    {
                        "value": "博湖县",
                        "id": "652829"
                    }
                ]
            },
            {
                "value": "阿克苏地区",
                "id": "652900",
                "childs": [
                    {
                        "value": "阿克苏市",
                        "id": "652901"
                    },
                    {
                        "value": "温宿县",
                        "id": "652922"
                    },
                    {
                        "value": "库车县",
                        "id": "652923"
                    },
                    {
                        "value": "沙雅县",
                        "id": "652924"
                    },
                    {
                        "value": "新和县",
                        "id": "652925"
                    },
                    {
                        "value": "拜城县",
                        "id": "652926"
                    },
                    {
                        "value": "乌什县",
                        "id": "652927"
                    },
                    {
                        "value": "阿瓦提县",
                        "id": "652928"
                    },
                    {
                        "value": "柯坪县",
                        "id": "652929"
                    }
                ]
            },
            {
                "value": "克孜勒苏柯尔克孜自治州",
                "id": "653000",
                "childs": [
                    {
                        "value": "阿图什市",
                        "id": "653001"
                    },
                    {
                        "value": "阿克陶县",
                        "id": "653022"
                    },
                    {
                        "value": "阿合奇县",
                        "id": "653023"
                    },
                    {
                        "value": "乌恰县",
                        "id": "653024"
                    }
                ]
            },
            {
                "value": "喀什地区",
                "id": "653100",
                "childs": [
                    {
                        "value": "喀什市",
                        "id": "653101"
                    },
                    {
                        "value": "疏附县",
                        "id": "653121"
                    },
                    {
                        "value": "疏勒县",
                        "id": "653122"
                    },
                    {
                        "value": "英吉沙县",
                        "id": "653123"
                    },
                    {
                        "value": "泽普县",
                        "id": "653124"
                    },
                    {
                        "value": "莎车县",
                        "id": "653125"
                    },
                    {
                        "value": "叶城县",
                        "id": "653126"
                    },
                    {
                        "value": "麦盖提县",
                        "id": "653127"
                    },
                    {
                        "value": "岳普湖县",
                        "id": "653128"
                    },
                    {
                        "value": "伽师县",
                        "id": "653129"
                    },
                    {
                        "value": "巴楚县",
                        "id": "653130"
                    },
                    {
                        "value": "塔什库尔干塔吉克自治县",
                        "id": "653131"
                    }
                ]
            },
            {
                "value": "和田地区",
                "id": "653200",
                "childs": [
                    {
                        "value": "和田市",
                        "id": "653201"
                    },
                    {
                        "value": "和田县",
                        "id": "653221"
                    },
                    {
                        "value": "墨玉县",
                        "id": "653222"
                    },
                    {
                        "value": "皮山县",
                        "id": "653223"
                    },
                    {
                        "value": "洛浦县",
                        "id": "653224"
                    },
                    {
                        "value": "策勒县",
                        "id": "653225"
                    },
                    {
                        "value": "于田县",
                        "id": "653226"
                    },
                    {
                        "value": "民丰县",
                        "id": "653227"
                    }
                ]
            },
            {
                "value": "伊犁哈萨克自治州",
                "id": "654000",
                "childs": [
                    {
                        "value": "伊宁市",
                        "id": "654002"
                    },
                    {
                        "value": "奎屯市",
                        "id": "654003"
                    },
                    {
                        "value": "霍尔果斯市",
                        "id": "654004"
                    },
                    {
                        "value": "伊宁县",
                        "id": "654021"
                    },
                    {
                        "value": "察布查尔锡伯自治县",
                        "id": "654022"
                    },
                    {
                        "value": "霍城县",
                        "id": "654023"
                    },
                    {
                        "value": "巩留县",
                        "id": "654024"
                    },
                    {
                        "value": "新源县",
                        "id": "654025"
                    },
                    {
                        "value": "昭苏县",
                        "id": "654026"
                    },
                    {
                        "value": "特克斯县",
                        "id": "654027"
                    },
                    {
                        "value": "尼勒克县",
                        "id": "654028"
                    },
                    {
                        "value": "塔城地区",
                        "id": "654200"
                    },
                    {
                        "value": "塔城市",
                        "id": "654201"
                    },
                    {
                        "value": "乌苏市",
                        "id": "654202"
                    },
                    {
                        "value": "额敏县",
                        "id": "654221"
                    },
                    {
                        "value": "沙湾县",
                        "id": "654223"
                    },
                    {
                        "value": "托里县",
                        "id": "654224"
                    },
                    {
                        "value": "裕民县",
                        "id": "654225"
                    },
                    {
                        "value": "和布克赛尔蒙古自治县",
                        "id": "654226"
                    },
                    {
                        "value": "阿勒泰地区",
                        "id": "654300"
                    },
                    {
                        "value": "阿勒泰市",
                        "id": "654301"
                    },
                    {
                        "value": "布尔津县",
                        "id": "654321"
                    },
                    {
                        "value": "富蕴县",
                        "id": "654322"
                    },
                    {
                        "value": "福海县",
                        "id": "654323"
                    },
                    {
                        "value": "哈巴河县",
                        "id": "654324"
                    },
                    {
                        "value": "青河县",
                        "id": "654325"
                    },
                    {
                        "value": "吉木乃县",
                        "id": "654326"
                    }
                ]
            },
            {
                "value": "自治区直辖县级行政区划",
                "id": "659000",
                "childs": [
                    {
                        "value": "石河子市",
                        "id": "659001"
                    },
                    {
                        "value": "阿拉尔市",
                        "id": "659002"
                    },
                    {
                        "value": "图木舒克市",
                        "id": "659003"
                    },
                    {
                        "value": "五家渠市",
                        "id": "659004"
                    },
                    {
                        "value": "北屯市",
                        "id": "659005"
                    },
                    {
                        "value": "铁门关市",
                        "id": "659006"
                    },
                    {
                        "value": "双河市",
                        "id": "659007"
                    }
                ]
            }
        ]
    },
    {
        "value": "台湾省",
        "id": "710000",
        "childs": [
            {
                "value": "台北市",
                "id": "710100",
                "childs": [
                    {
                        "value": "松山区",
                        "id": "710101"
                    },
                    {
                        "value": "信义区",
                        "id": "710102"
                    },
                    {
                        "value": "大安区",
                        "id": "710103"
                    },
                    {
                        "value": "中山区",
                        "id": "710104"
                    },
                    {
                        "value": "中正区",
                        "id": "710105"
                    },
                    {
                        "value": "大同区",
                        "id": "710106"
                    },
                    {
                        "value": "万华区",
                        "id": "710107"
                    },
                    {
                        "value": "文山区",
                        "id": "710108"
                    },
                    {
                        "value": "南港区",
                        "id": "710109"
                    },
                    {
                        "value": "内湖区",
                        "id": "710110"
                    },
                    {
                        "value": "士林区",
                        "id": "710111"
                    },
                    {
                        "value": "北投区",
                        "id": "710112"
                    }
                ]
            },
            {
                "value": "高雄市",
                "id": "710200",
                "childs": [
                    {
                        "value": "盐埕区",
                        "id": "710201"
                    },
                    {
                        "value": "鼓山区",
                        "id": "710202"
                    },
                    {
                        "value": "左营区",
                        "id": "710203"
                    },
                    {
                        "value": "楠梓区",
                        "id": "710204"
                    },
                    {
                        "value": "三民区",
                        "id": "710205"
                    },
                    {
                        "value": "新兴区",
                        "id": "710206"
                    },
                    {
                        "value": "前金区",
                        "id": "710207"
                    },
                    {
                        "value": "苓雅区",
                        "id": "710208"
                    },
                    {
                        "value": "前镇区",
                        "id": "710209"
                    },
                    {
                        "value": "旗津区",
                        "id": "710210"
                    },
                    {
                        "value": "小港区",
                        "id": "710211"
                    },
                    {
                        "value": "凤山区",
                        "id": "710212"
                    },
                    {
                        "value": "林园区",
                        "id": "710213"
                    },
                    {
                        "value": "大寮区",
                        "id": "710214"
                    },
                    {
                        "value": "大树区",
                        "id": "710215"
                    },
                    {
                        "value": "大社区",
                        "id": "710216"
                    },
                    {
                        "value": "仁武区",
                        "id": "710217"
                    },
                    {
                        "value": "鸟松区",
                        "id": "710218"
                    },
                    {
                        "value": "冈山区",
                        "id": "710219"
                    },
                    {
                        "value": "桥头区",
                        "id": "710220"
                    },
                    {
                        "value": "燕巢区",
                        "id": "710221"
                    },
                    {
                        "value": "田寮区",
                        "id": "710222"
                    },
                    {
                        "value": "阿莲区",
                        "id": "710223"
                    },
                    {
                        "value": "路竹区",
                        "id": "710224"
                    },
                    {
                        "value": "湖内区",
                        "id": "710225"
                    },
                    {
                        "value": "茄萣区",
                        "id": "710226"
                    },
                    {
                        "value": "永安区",
                        "id": "710227"
                    },
                    {
                        "value": "弥陀区",
                        "id": "710228"
                    },
                    {
                        "value": "梓官区",
                        "id": "710229"
                    },
                    {
                        "value": "旗山区",
                        "id": "710230"
                    },
                    {
                        "value": "美浓区",
                        "id": "710231"
                    },
                    {
                        "value": "六龟区",
                        "id": "710232"
                    },
                    {
                        "value": "甲仙区",
                        "id": "710233"
                    },
                    {
                        "value": "杉林区",
                        "id": "710234"
                    },
                    {
                        "value": "内门区",
                        "id": "710235"
                    },
                    {
                        "value": "茂林区",
                        "id": "710236"
                    },
                    {
                        "value": "桃源区",
                        "id": "710237"
                    },
                    {
                        "value": "那玛夏区",
                        "id": "710238"
                    }
                ]
            },
            {
                "value": "基隆市",
                "id": "710300",
                "childs": [
                    {
                        "value": "中正区",
                        "id": "710301"
                    },
                    {
                        "value": "七堵区",
                        "id": "710302"
                    },
                    {
                        "value": "暖暖区",
                        "id": "710303"
                    },
                    {
                        "value": "仁爱区",
                        "id": "710304"
                    },
                    {
                        "value": "中山区",
                        "id": "710305"
                    },
                    {
                        "value": "安乐区",
                        "id": "710306"
                    },
                    {
                        "value": "信义区",
                        "id": "710307"
                    }
                ]
            },
            {
                "value": "台中市",
                "id": "710400",
                "childs": [
                    {
                        "value": "中区",
                        "id": "710401"
                    },
                    {
                        "value": "东区",
                        "id": "710402"
                    },
                    {
                        "value": "南区",
                        "id": "710403"
                    },
                    {
                        "value": "西区",
                        "id": "710404"
                    },
                    {
                        "value": "北区",
                        "id": "710405"
                    },
                    {
                        "value": "西屯区",
                        "id": "710406"
                    },
                    {
                        "value": "南屯区",
                        "id": "710407"
                    },
                    {
                        "value": "北屯区",
                        "id": "710408"
                    },
                    {
                        "value": "丰原区",
                        "id": "710409"
                    },
                    {
                        "value": "东势区",
                        "id": "710410"
                    },
                    {
                        "value": "大甲区",
                        "id": "710411"
                    },
                    {
                        "value": "清水区",
                        "id": "710412"
                    },
                    {
                        "value": "沙鹿区",
                        "id": "710413"
                    },
                    {
                        "value": "梧栖区",
                        "id": "710414"
                    },
                    {
                        "value": "后里区",
                        "id": "710415"
                    },
                    {
                        "value": "神冈区",
                        "id": "710416"
                    },
                    {
                        "value": "潭子区",
                        "id": "710417"
                    },
                    {
                        "value": "大雅区",
                        "id": "710418"
                    },
                    {
                        "value": "新社区",
                        "id": "710419"
                    },
                    {
                        "value": "石冈区",
                        "id": "710420"
                    },
                    {
                        "value": "外埔区",
                        "id": "710421"
                    },
                    {
                        "value": "大安区",
                        "id": "710422"
                    },
                    {
                        "value": "乌日区",
                        "id": "710423"
                    },
                    {
                        "value": "大肚区",
                        "id": "710424"
                    },
                    {
                        "value": "龙井区",
                        "id": "710425"
                    },
                    {
                        "value": "雾峰区",
                        "id": "710426"
                    },
                    {
                        "value": "太平区",
                        "id": "710427"
                    },
                    {
                        "value": "大里区",
                        "id": "710428"
                    },
                    {
                        "value": "和平区",
                        "id": "710429"
                    }
                ]
            },
            {
                "value": "台南市",
                "id": "710500",
                "childs": [
                    {
                        "value": "东区",
                        "id": "710501"
                    },
                    {
                        "value": "南区",
                        "id": "710502"
                    },
                    {
                        "value": "北区",
                        "id": "710504"
                    },
                    {
                        "value": "安南区",
                        "id": "710506"
                    },
                    {
                        "value": "安平区",
                        "id": "710507"
                    },
                    {
                        "value": "中西区",
                        "id": "710508"
                    },
                    {
                        "value": "新营区",
                        "id": "710509"
                    },
                    {
                        "value": "盐水区",
                        "id": "710510"
                    },
                    {
                        "value": "白河区",
                        "id": "710511"
                    },
                    {
                        "value": "柳营区",
                        "id": "710512"
                    },
                    {
                        "value": "后壁区",
                        "id": "710513"
                    },
                    {
                        "value": "东山区",
                        "id": "710514"
                    },
                    {
                        "value": "麻豆区",
                        "id": "710515"
                    },
                    {
                        "value": "下营区",
                        "id": "710516"
                    },
                    {
                        "value": "六甲区",
                        "id": "710517"
                    },
                    {
                        "value": "官田区",
                        "id": "710518"
                    },
                    {
                        "value": "大内区",
                        "id": "710519"
                    },
                    {
                        "value": "佳里区",
                        "id": "710520"
                    },
                    {
                        "value": "学甲区",
                        "id": "710521"
                    },
                    {
                        "value": "西港区",
                        "id": "710522"
                    },
                    {
                        "value": "七股区",
                        "id": "710523"
                    },
                    {
                        "value": "将军区",
                        "id": "710524"
                    },
                    {
                        "value": "北门区",
                        "id": "710525"
                    },
                    {
                        "value": "新化区",
                        "id": "710526"
                    },
                    {
                        "value": "善化区",
                        "id": "710527"
                    },
                    {
                        "value": "新市区",
                        "id": "710528"
                    },
                    {
                        "value": "安定区",
                        "id": "710529"
                    },
                    {
                        "value": "山上区",
                        "id": "710530"
                    },
                    {
                        "value": "玉井区",
                        "id": "710531"
                    },
                    {
                        "value": "楠西区",
                        "id": "710532"
                    },
                    {
                        "value": "南化区",
                        "id": "710533"
                    },
                    {
                        "value": "左镇区",
                        "id": "710534"
                    },
                    {
                        "value": "仁德区",
                        "id": "710535"
                    },
                    {
                        "value": "归仁区",
                        "id": "710536"
                    },
                    {
                        "value": "关庙区",
                        "id": "710537"
                    },
                    {
                        "value": "龙崎区",
                        "id": "710538"
                    },
                    {
                        "value": "永康区",
                        "id": "710539"
                    }
                ]
            },
            {
                "value": "新竹市",
                "id": "710600",
                "childs": [
                    {
                        "value": "东区",
                        "id": "710601"
                    },
                    {
                        "value": "北区",
                        "id": "710602"
                    },
                    {
                        "value": "香山区",
                        "id": "710603"
                    }
                ]
            },
            {
                "value": "嘉义市",
                "id": "710700",
                "childs": [
                    {
                        "value": "东区",
                        "id": "710701"
                    },
                    {
                        "value": "西区",
                        "id": "710702"
                    }
                ]
            },
            {
                "value": "新北市",
                "id": "710800",
                "childs": [
                    {
                        "value": "板桥区",
                        "id": "710801"
                    },
                    {
                        "value": "三重区",
                        "id": "710802"
                    },
                    {
                        "value": "中和区",
                        "id": "710803"
                    },
                    {
                        "value": "永和区",
                        "id": "710804"
                    },
                    {
                        "value": "新庄区",
                        "id": "710805"
                    },
                    {
                        "value": "新店区",
                        "id": "710806"
                    },
                    {
                        "value": "树林区",
                        "id": "710807"
                    },
                    {
                        "value": "莺歌区",
                        "id": "710808"
                    },
                    {
                        "value": "三峡区",
                        "id": "710809"
                    },
                    {
                        "value": "淡水区",
                        "id": "710810"
                    },
                    {
                        "value": "汐止区",
                        "id": "710811"
                    },
                    {
                        "value": "瑞芳区",
                        "id": "710812"
                    },
                    {
                        "value": "土城区",
                        "id": "710813"
                    },
                    {
                        "value": "芦洲区",
                        "id": "710814"
                    },
                    {
                        "value": "五股区",
                        "id": "710815"
                    },
                    {
                        "value": "泰山区",
                        "id": "710816"
                    },
                    {
                        "value": "林口区",
                        "id": "710817"
                    },
                    {
                        "value": "深坑区",
                        "id": "710818"
                    },
                    {
                        "value": "石碇区",
                        "id": "710819"
                    },
                    {
                        "value": "坪林区",
                        "id": "710820"
                    },
                    {
                        "value": "三芝区",
                        "id": "710821"
                    },
                    {
                        "value": "石门区",
                        "id": "710822"
                    },
                    {
                        "value": "八里区",
                        "id": "710823"
                    },
                    {
                        "value": "平溪区",
                        "id": "710824"
                    },
                    {
                        "value": "双溪区",
                        "id": "710825"
                    },
                    {
                        "value": "贡寮区",
                        "id": "710826"
                    },
                    {
                        "value": "金山区",
                        "id": "710827"
                    },
                    {
                        "value": "万里区",
                        "id": "710828"
                    },
                    {
                        "value": "乌来区",
                        "id": "710829"
                    }
                ]
            },
            {
                "value": "宜兰县",
                "id": "712200",
                "childs": [
                    {
                        "value": "宜兰市",
                        "id": "712201"
                    },
                    {
                        "value": "罗东镇",
                        "id": "712221"
                    },
                    {
                        "value": "苏澳镇",
                        "id": "712222"
                    },
                    {
                        "value": "头城镇",
                        "id": "712223"
                    },
                    {
                        "value": "礁溪乡",
                        "id": "712224"
                    },
                    {
                        "value": "壮围乡",
                        "id": "712225"
                    },
                    {
                        "value": "员山乡",
                        "id": "712226"
                    },
                    {
                        "value": "冬山乡",
                        "id": "712227"
                    },
                    {
                        "value": "五结乡",
                        "id": "712228"
                    },
                    {
                        "value": "三星乡",
                        "id": "712229"
                    },
                    {
                        "value": "大同乡",
                        "id": "712230"
                    },
                    {
                        "value": "南澳乡",
                        "id": "712231"
                    }
                ]
            },
            {
                "value": "桃园县",
                "id": "712300",
                "childs": [
                    {
                        "value": "桃园市",
                        "id": "712301"
                    },
                    {
                        "value": "中坜市",
                        "id": "712302"
                    },
                    {
                        "value": "平镇市",
                        "id": "712303"
                    },
                    {
                        "value": "八德市",
                        "id": "712304"
                    },
                    {
                        "value": "杨梅市",
                        "id": "712305"
                    },
                    {
                        "value": "大溪镇",
                        "id": "712321"
                    },
                    {
                        "value": "芦竹乡",
                        "id": "712323"
                    },
                    {
                        "value": "大园乡",
                        "id": "712324"
                    },
                    {
                        "value": "龟山乡",
                        "id": "712325"
                    },
                    {
                        "value": "龙潭乡",
                        "id": "712327"
                    },
                    {
                        "value": "新屋乡",
                        "id": "712329"
                    },
                    {
                        "value": "观音乡",
                        "id": "712330"
                    },
                    {
                        "value": "复兴乡",
                        "id": "712331"
                    }
                ]
            },
            {
                "value": "新竹县",
                "id": "712400",
                "childs": [
                    {
                        "value": "竹北市",
                        "id": "712401"
                    },
                    {
                        "value": "竹东镇",
                        "id": "712421"
                    },
                    {
                        "value": "新埔镇",
                        "id": "712422"
                    },
                    {
                        "value": "关西镇",
                        "id": "712423"
                    },
                    {
                        "value": "湖口乡",
                        "id": "712424"
                    },
                    {
                        "value": "新丰乡",
                        "id": "712425"
                    },
                    {
                        "value": "芎林乡",
                        "id": "712426"
                    },
                    {
                        "value": "橫山乡",
                        "id": "712427"
                    },
                    {
                        "value": "北埔乡",
                        "id": "712428"
                    },
                    {
                        "value": "宝山乡",
                        "id": "712429"
                    },
                    {
                        "value": "峨眉乡",
                        "id": "712430"
                    },
                    {
                        "value": "尖石乡",
                        "id": "712431"
                    },
                    {
                        "value": "五峰乡",
                        "id": "712432"
                    }
                ]
            },
            {
                "value": "苗栗县",
                "id": "712500",
                "childs": [
                    {
                        "value": "苗栗市",
                        "id": "712501"
                    },
                    {
                        "value": "苑里镇",
                        "id": "712521"
                    },
                    {
                        "value": "通霄镇",
                        "id": "712522"
                    },
                    {
                        "value": "竹南镇",
                        "id": "712523"
                    },
                    {
                        "value": "头份镇",
                        "id": "712524"
                    },
                    {
                        "value": "后龙镇",
                        "id": "712525"
                    },
                    {
                        "value": "卓兰镇",
                        "id": "712526"
                    },
                    {
                        "value": "大湖乡",
                        "id": "712527"
                    },
                    {
                        "value": "公馆乡",
                        "id": "712528"
                    },
                    {
                        "value": "铜锣乡",
                        "id": "712529"
                    },
                    {
                        "value": "南庄乡",
                        "id": "712530"
                    },
                    {
                        "value": "头屋乡",
                        "id": "712531"
                    },
                    {
                        "value": "三义乡",
                        "id": "712532"
                    },
                    {
                        "value": "西湖乡",
                        "id": "712533"
                    },
                    {
                        "value": "造桥乡",
                        "id": "712534"
                    },
                    {
                        "value": "三湾乡",
                        "id": "712535"
                    },
                    {
                        "value": "狮潭乡",
                        "id": "712536"
                    },
                    {
                        "value": "泰安乡",
                        "id": "712537"
                    }
                ]
            },
            {
                "value": "彰化县",
                "id": "712700",
                "childs": [
                    {
                        "value": "彰化市",
                        "id": "712701"
                    },
                    {
                        "value": "鹿港镇",
                        "id": "712721"
                    },
                    {
                        "value": "和美镇",
                        "id": "712722"
                    },
                    {
                        "value": "线西乡",
                        "id": "712723"
                    },
                    {
                        "value": "伸港乡",
                        "id": "712724"
                    },
                    {
                        "value": "福兴乡",
                        "id": "712725"
                    },
                    {
                        "value": "秀水乡",
                        "id": "712726"
                    },
                    {
                        "value": "花坛乡",
                        "id": "712727"
                    },
                    {
                        "value": "芬园乡",
                        "id": "712728"
                    },
                    {
                        "value": "员林镇",
                        "id": "712729"
                    },
                    {
                        "value": "溪湖镇",
                        "id": "712730"
                    },
                    {
                        "value": "田中镇",
                        "id": "712731"
                    },
                    {
                        "value": "大村乡",
                        "id": "712732"
                    },
                    {
                        "value": "埔盐乡",
                        "id": "712733"
                    },
                    {
                        "value": "埔心乡",
                        "id": "712734"
                    },
                    {
                        "value": "永靖乡",
                        "id": "712735"
                    },
                    {
                        "value": "社头乡",
                        "id": "712736"
                    },
                    {
                        "value": "二水乡",
                        "id": "712737"
                    },
                    {
                        "value": "北斗镇",
                        "id": "712738"
                    },
                    {
                        "value": "二林镇",
                        "id": "712739"
                    },
                    {
                        "value": "田尾乡",
                        "id": "712740"
                    },
                    {
                        "value": "埤头乡",
                        "id": "712741"
                    },
                    {
                        "value": "芳苑乡",
                        "id": "712742"
                    },
                    {
                        "value": "大城乡",
                        "id": "712743"
                    },
                    {
                        "value": "竹塘乡",
                        "id": "712744"
                    },
                    {
                        "value": "溪州乡",
                        "id": "712745"
                    }
                ]
            },
            {
                "value": "南投县",
                "id": "712800",
                "childs": [
                    {
                        "value": "南投市",
                        "id": "712801"
                    },
                    {
                        "value": "埔里镇",
                        "id": "712821"
                    },
                    {
                        "value": "草屯镇",
                        "id": "712822"
                    },
                    {
                        "value": "竹山镇",
                        "id": "712823"
                    },
                    {
                        "value": "集集镇",
                        "id": "712824"
                    },
                    {
                        "value": "名间乡",
                        "id": "712825"
                    },
                    {
                        "value": "鹿谷乡",
                        "id": "712826"
                    },
                    {
                        "value": "中寮乡",
                        "id": "712827"
                    },
                    {
                        "value": "鱼池乡",
                        "id": "712828"
                    },
                    {
                        "value": "国姓乡",
                        "id": "712829"
                    },
                    {
                        "value": "水里乡",
                        "id": "712830"
                    },
                    {
                        "value": "信义乡",
                        "id": "712831"
                    },
                    {
                        "value": "仁爱乡",
                        "id": "712832"
                    }
                ]
            },
            {
                "value": "云林县",
                "id": "712900",
                "childs": [
                    {
                        "value": "斗六市",
                        "id": "712901"
                    },
                    {
                        "value": "斗南镇",
                        "id": "712921"
                    },
                    {
                        "value": "虎尾镇",
                        "id": "712922"
                    },
                    {
                        "value": "西螺镇",
                        "id": "712923"
                    },
                    {
                        "value": "土库镇",
                        "id": "712924"
                    },
                    {
                        "value": "北港镇",
                        "id": "712925"
                    },
                    {
                        "value": "古坑乡",
                        "id": "712926"
                    },
                    {
                        "value": "大埤乡",
                        "id": "712927"
                    },
                    {
                        "value": "莿桐乡",
                        "id": "712928"
                    },
                    {
                        "value": "林内乡",
                        "id": "712929"
                    },
                    {
                        "value": "二仑乡",
                        "id": "712930"
                    },
                    {
                        "value": "仑背乡",
                        "id": "712931"
                    },
                    {
                        "value": "麦寮乡",
                        "id": "712932"
                    },
                    {
                        "value": "东势乡",
                        "id": "712933"
                    },
                    {
                        "value": "褒忠乡",
                        "id": "712934"
                    },
                    {
                        "value": "台西乡",
                        "id": "712935"
                    },
                    {
                        "value": "元长乡",
                        "id": "712936"
                    },
                    {
                        "value": "四湖乡",
                        "id": "712937"
                    },
                    {
                        "value": "口湖乡",
                        "id": "712938"
                    },
                    {
                        "value": "水林乡",
                        "id": "712939"
                    }
                ]
            },
            {
                "value": "嘉义县",
                "id": "713000",
                "childs": [
                    {
                        "value": "太保市",
                        "id": "713001"
                    },
                    {
                        "value": "朴子市",
                        "id": "713002"
                    },
                    {
                        "value": "布袋镇",
                        "id": "713023"
                    },
                    {
                        "value": "大林镇",
                        "id": "713024"
                    },
                    {
                        "value": "民雄乡",
                        "id": "713025"
                    },
                    {
                        "value": "溪口乡",
                        "id": "713026"
                    },
                    {
                        "value": "新港乡",
                        "id": "713027"
                    },
                    {
                        "value": "六脚乡",
                        "id": "713028"
                    },
                    {
                        "value": "东石乡",
                        "id": "713029"
                    },
                    {
                        "value": "义竹乡",
                        "id": "713030"
                    },
                    {
                        "value": "鹿草乡",
                        "id": "713031"
                    },
                    {
                        "value": "水上乡",
                        "id": "713032"
                    },
                    {
                        "value": "中埔乡",
                        "id": "713033"
                    },
                    {
                        "value": "竹崎乡",
                        "id": "713034"
                    },
                    {
                        "value": "梅山乡",
                        "id": "713035"
                    },
                    {
                        "value": "番路乡",
                        "id": "713036"
                    },
                    {
                        "value": "大埔乡",
                        "id": "713037"
                    },
                    {
                        "value": "阿里山乡",
                        "id": "713038"
                    }
                ]
            },
            {
                "value": "屏东县",
                "id": "713300",
                "childs": [
                    {
                        "value": "屏东市",
                        "id": "713301"
                    },
                    {
                        "value": "潮州镇",
                        "id": "713321"
                    },
                    {
                        "value": "东港镇",
                        "id": "713322"
                    },
                    {
                        "value": "恒春镇",
                        "id": "713323"
                    },
                    {
                        "value": "万丹乡",
                        "id": "713324"
                    },
                    {
                        "value": "长治乡",
                        "id": "713325"
                    },
                    {
                        "value": "麟洛乡",
                        "id": "713326"
                    },
                    {
                        "value": "九如乡",
                        "id": "713327"
                    },
                    {
                        "value": "里港乡",
                        "id": "713328"
                    },
                    {
                        "value": "盐埔乡",
                        "id": "713329"
                    },
                    {
                        "value": "高树乡",
                        "id": "713330"
                    },
                    {
                        "value": "万峦乡",
                        "id": "713331"
                    },
                    {
                        "value": "内埔乡",
                        "id": "713332"
                    },
                    {
                        "value": "竹田乡",
                        "id": "713333"
                    },
                    {
                        "value": "新埤乡",
                        "id": "713334"
                    },
                    {
                        "value": "枋寮乡",
                        "id": "713335"
                    },
                    {
                        "value": "新园乡",
                        "id": "713336"
                    },
                    {
                        "value": "崁顶乡",
                        "id": "713337"
                    },
                    {
                        "value": "林边乡",
                        "id": "713338"
                    },
                    {
                        "value": "南州乡",
                        "id": "713339"
                    },
                    {
                        "value": "佳冬乡",
                        "id": "713340"
                    },
                    {
                        "value": "琉球乡",
                        "id": "713341"
                    },
                    {
                        "value": "车城乡",
                        "id": "713342"
                    },
                    {
                        "value": "满州乡",
                        "id": "713343"
                    },
                    {
                        "value": "枋山乡",
                        "id": "713344"
                    },
                    {
                        "value": "三地门乡",
                        "id": "713345"
                    },
                    {
                        "value": "雾台乡",
                        "id": "713346"
                    },
                    {
                        "value": "玛家乡",
                        "id": "713347"
                    },
                    {
                        "value": "泰武乡",
                        "id": "713348"
                    },
                    {
                        "value": "来义乡",
                        "id": "713349"
                    },
                    {
                        "value": "春日乡",
                        "id": "713350"
                    },
                    {
                        "value": "狮子乡",
                        "id": "713351"
                    },
                    {
                        "value": "牡丹乡",
                        "id": "713352"
                    }
                ]
            },
            {
                "value": "台东县",
                "id": "713400",
                "childs": [
                    {
                        "value": "台东市",
                        "id": "713401"
                    },
                    {
                        "value": "成功镇",
                        "id": "713421"
                    },
                    {
                        "value": "关山镇",
                        "id": "713422"
                    },
                    {
                        "value": "卑南乡",
                        "id": "713423"
                    },
                    {
                        "value": "鹿野乡",
                        "id": "713424"
                    },
                    {
                        "value": "池上乡",
                        "id": "713425"
                    },
                    {
                        "value": "东河乡",
                        "id": "713426"
                    },
                    {
                        "value": "长滨乡",
                        "id": "713427"
                    },
                    {
                        "value": "太麻里乡",
                        "id": "713428"
                    },
                    {
                        "value": "大武乡",
                        "id": "713429"
                    },
                    {
                        "value": "绿岛乡",
                        "id": "713430"
                    },
                    {
                        "value": "海端乡",
                        "id": "713431"
                    },
                    {
                        "value": "延平乡",
                        "id": "713432"
                    },
                    {
                        "value": "金峰乡",
                        "id": "713433"
                    },
                    {
                        "value": "达仁乡",
                        "id": "713434"
                    },
                    {
                        "value": "兰屿乡",
                        "id": "713435"
                    }
                ]
            },
            {
                "value": "花莲县",
                "id": "713500",
                "childs": [
                    {
                        "value": "花莲市",
                        "id": "713501"
                    },
                    {
                        "value": "凤林镇",
                        "id": "713521"
                    },
                    {
                        "value": "玉里镇",
                        "id": "713522"
                    },
                    {
                        "value": "新城乡",
                        "id": "713523"
                    },
                    {
                        "value": "吉安乡",
                        "id": "713524"
                    },
                    {
                        "value": "寿丰乡",
                        "id": "713525"
                    },
                    {
                        "value": "光复乡",
                        "id": "713526"
                    },
                    {
                        "value": "丰滨乡",
                        "id": "713527"
                    },
                    {
                        "value": "瑞穗乡",
                        "id": "713528"
                    },
                    {
                        "value": "富里乡",
                        "id": "713529"
                    },
                    {
                        "value": "秀林乡",
                        "id": "713530"
                    },
                    {
                        "value": "万荣乡",
                        "id": "713531"
                    },
                    {
                        "value": "卓溪乡",
                        "id": "713532"
                    }
                ]
            },
            {
                "value": "澎湖县",
                "id": "713600",
                "childs": [
                    {
                        "value": "马公市",
                        "id": "713601"
                    },
                    {
                        "value": "湖西乡",
                        "id": "713621"
                    },
                    {
                        "value": "白沙乡",
                        "id": "713622"
                    },
                    {
                        "value": "西屿乡",
                        "id": "713623"
                    },
                    {
                        "value": "望安乡",
                        "id": "713624"
                    },
                    {
                        "value": "七美乡",
                        "id": "713625"
                    }
                ]
            }
        ]
    },
    {
        "value": "香港特别行政区",
        "id": "810000",
        "childs": [
            {
                "value": "香港岛",
                "id": "810100",
                "childs": [
                    {
                        "value": "中西区",
                        "id": "810101"
                    },
                    {
                        "value": "湾仔区",
                        "id": "810102"
                    },
                    {
                        "value": "东区",
                        "id": "810103"
                    },
                    {
                        "value": "南区",
                        "id": "810104"
                    }
                ]
            },
            {
                "value": "九龙",
                "id": "810200",
                "childs": [
                    {
                        "value": "油尖旺区",
                        "id": "810201"
                    },
                    {
                        "value": "深水埗区",
                        "id": "810202"
                    },
                    {
                        "value": "九龙城区",
                        "id": "810203"
                    },
                    {
                        "value": "黄大仙区",
                        "id": "810204"
                    },
                    {
                        "value": "观塘区",
                        "id": "810205"
                    }
                ]
            },
            {
                "value": "新界",
                "id": "810300",
                "childs": [
                    {
                        "value": "荃湾区",
                        "id": "810301"
                    },
                    {
                        "value": "屯门区",
                        "id": "810302"
                    },
                    {
                        "value": "元朗区",
                        "id": "810303"
                    },
                    {
                        "value": "北区",
                        "id": "810304"
                    },
                    {
                        "value": "大埔区",
                        "id": "810305"
                    },
                    {
                        "value": "西贡区",
                        "id": "810306"
                    },
                    {
                        "value": "沙田区",
                        "id": "810307"
                    },
                    {
                        "value": "葵青区",
                        "id": "810308"
                    },
                    {
                        "value": "离岛区",
                        "id": "810309"
                    }
                ]
            }
        ]
    },
    {
        "value": "澳门特别行政区",
        "id": "820000",
        "childs": [
            {
                "value": "澳门半岛",
                "id": "820100",
                "childs": [
                    {
                        "value": "花地玛堂区",
                        "id": "820101"
                    },
                    {
                        "value": "圣安多尼堂区",
                        "id": "820102"
                    },
                    {
                        "value": "大堂区",
                        "id": "820103"
                    },
                    {
                        "value": "望德堂区",
                        "id": "820104"
                    },
                    {
                        "value": "风顺堂区",
                        "id": "820105"
                    }
                ]
            },
            {
                "value": "氹仔岛",
                "id": "820200",
                "childs": [
                    {
                        "value": "嘉模堂区",
                        "id": "820201"
                    }
                ]
            },
            {
                "value": "路环岛",
                "id": "820300",
                "childs": [
                    {
                        "value": "圣方济各堂区",
                        "id": "820301"
                    }
                ]
            }
        ]
    }
]
export default cityArrr
