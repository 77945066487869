<template>
    <div class="MemberComponent">
        <div class="title">
            {{ title }}
            <el-affix v-if="isEdit" :offset="100" style="float: left; cursor: pointer">
                <span @click="handleClose">
                    <el-button size="small">返回</el-button>
                </span>
            </el-affix>
            <!--            <span @click="handleClose" style="float: right;cursor: pointer"><i class="el-icon-close"></i></span>-->
        </div>
        <el-row class="compload" style="margin: 0 auto">
            <el-col :span="13" :offset="2" style="text-align: left">
                <el-form size="medium" :disabled="statusDisabled" :model="ruleForm" :rules="rules" ref="ruleForm"
                    label-width="140px" class="demo-ruleForm">
                    <el-row v-if="step == 1" style="width: 100%">
                        <el-form-item style="margin-top: 25px; width: 100%" label="基本信息"> </el-form-item>
                        <el-form-item label="构件名称" prop="name">
                            <el-input v-model="ruleForm.name"></el-input>
                        </el-form-item>
                        <el-form-item label="构件编号" prop="number">
                            <el-input v-model="ruleForm.number"></el-input>
                        </el-form-item>
                        <el-form-item label="是否为结构件">
                            <el-radio-group size="small" v-model="ruleForm.is_structure">
                                <el-radio border v-for="item in selectArray" :key="item.id" :label="item.id">{{
                                item.name
                                }}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="构件类型" required>
                            <el-row>
                                <el-col :span="6" style="padding-right: 3px">
                                    <el-form-item prop="setup_id">
                                        <el-select @change="changeSetupId(true)" placeholder="请选择体系"
                                            :popper-append-to-body="false" style="width: 100%"
                                            v-model="ruleForm.setup_id">
                                            <el-option v-for="item in setup_list" :key="item.id"
                                                :label="item.setup_name" :value="item.id"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6" style="padding-left: 3px; padding-right: 3px">
                                    <el-form-item prop="classify_id">
                                        <el-select @change="changeMaterialId(true)" placeholder="请选择分类"
                                            :popper-append-to-body="false" style="width: 100%"
                                            v-model="ruleForm.classify_id">
                                            <el-option v-for="item in material_list" :key="item.id"
                                                :label="item.material_name" :value="item.id"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6" v-if="thickness_list.length" style="padding-left: 3px">
                                    <el-form-item prop="son_classify_id">
                                        <el-select placeholder="请选择材料" :popper-append-to-body="false"
                                            style="width: 100%" v-model="ruleForm.son_classify_id">
                                            <el-option v-for="item in thickness_list" :key="item.id"
                                                :label="item.thickness_name" :value="item.id"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6" style="padding-left: 6px">
                                    <el-button @click="jump(1)" type="primary">类型管理</el-button>
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-form-item label="品牌" required>
                            <el-row>
                                <el-col :span="8" style="padding-right: 3px">
                                    <el-form-item prop="brand_id">
                                        <el-select @change="changeBrand(true)" placeholder="请选择品牌"
                                            :popper-append-to-body="false" style="width: 100%"
                                            v-model="ruleForm.brand_id">
                                            <el-option v-for="item in brand_list" :key="item.b_id" :label="item.b_name"
                                                :value="item.b_id"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col v-if="brandSeries_list.length" :span="8" style="padding-left: 3px">
                                    <el-form-item prop="series_id">
                                        <el-select placeholder="请选择系列" :popper-append-to-body="false"
                                            style="width: 100%" v-model="ruleForm.series_id">
                                            <el-option v-for="item in brandSeries_list" :key="item.series_id"
                                                :label="item.series_name" :value="item.series_id"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" style="padding-left: 6px">
                                    <el-button @click="jump(2)" type="primary">品牌管理</el-button>
                                </el-col>
                            </el-row>
                        </el-form-item>

                        <el-form-item label="应用在" prop="apply_position">
                            <el-checkbox-group size="small" v-model="ruleForm.apply_position">
                                <el-checkbox border v-for="item in apply_position" :key="item.status"
                                    :label="item.status">{{ item.name }}</el-checkbox>
                                <!--                                <el-button style="margin-left: 6px;padding: 10px" @click="jump(3)"><i class="el-icon-plus"></i></el-button>-->
                            </el-checkbox-group>
                        </el-form-item>

                        <el-form-item label="置顶" prop="sorts">
                            <el-input v-model="ruleForm.sorts" placeholder="输入置顶数字(0-9999)"></el-input>
                        </el-form-item>
                        <el-form-item label="特性" prop="characteristic">
                            <el-checkbox-group size="small" v-model="ruleForm.characteristic">
                                <el-checkbox border v-for="item in characteristic_list" :key="item.c_id"
                                    :label="item.c_id">{{ item.c_name }}</el-checkbox>
                                <el-button style="margin-left: 6px; padding: 10px" @click="jump(3)"><i
                                        class="el-icon-plus"></i></el-button>
                            </el-checkbox-group>
                        </el-form-item>
                        <el-form-item label="风格" prop="style">
                            <div>
                                <el-checkbox-group size="small" v-model="ruleForm.style">
                                    <el-checkbox border v-for="item in style_list" :key="item.s_id" :label="item.s_id">
                                        {{ item.s_name }}</el-checkbox>
                                    <el-button style="margin-left: 6px; padding: 10px" @click="jump(4)"><i
                                            class="el-icon-plus"></i></el-button>
                                </el-checkbox-group>
                            </div>
                        </el-form-item>

                        <el-form-item label="地区" prop="area">
                            <el-select placeholder="请选择省份" :popper-append-to-body="false" style="width: 100%"
                                v-model="ruleForm.area">
                                <el-option v-for="item in provinceArray" :key="item.id" :label="item.value"
                                    :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="空间区域" prop="space_area">
                            <div>
                                <el-checkbox-group size="small" v-model="ruleForm.space_area">
                                    <el-checkbox border v-for="item in spaceArea_list" :key="item.s_id"
                                        :label="item.s_id">{{ item.s_name }}</el-checkbox>
                                    <el-button style="margin-left: 6px; padding: 10px" @click="jump(5)"><i
                                            class="el-icon-plus"></i></el-button>
                                </el-checkbox-group>
                            </div>
                        </el-form-item>

                        <el-form-item label="公装区域" prop="tooling_area">
                            <div>
                                <el-checkbox-group size="small" v-model="ruleForm.tooling_area">
                                    <el-checkbox border v-for="item in toolingArea_list" :key="item.t_id"
                                        :label="item.t_id">{{ item.t_name }}</el-checkbox>
                                    <el-button style="margin-left: 6px; padding: 10px" @click="jump(6)"><i
                                            class="el-icon-plus"></i></el-button>
                                </el-checkbox-group>
                            </div>
                        </el-form-item>
                        <el-form-item label="材质" prop="material">
                            <el-input v-model="ruleForm.material"></el-input>
                        </el-form-item>
                        <el-form-item v-if="showLong" label="管线空腔" class="specsInput" prop="pipeline_cavity">
                            <el-input type="number" suffix-icon="el-icon-search" @change="returnFloat1"
                                v-model="ruleForm.pipeline_cavity"></el-input>
                        </el-form-item>
                        <el-form-item label="构件规格" class="specsInput" required>
                            <span v-for="(item, index) in ruleForm.format.arr" :key="index" :style="style1">
                                <el-row style="margin-bottom: 8px">
                                    <el-col :span="1">
                                        <el-radio v-model="ruleForm.format.status" :label="index">&nbsp;</el-radio>
                                    </el-col>
                                    <el-col :span="6" style="padding-right: 3px">
                                        <el-form-item :prop="'format.arr.' + index + '.length'"
                                            :rules="rules.format[0]">
                                            <el-input type="number" prefix-icon="el-icon-search"
                                                suffix-icon="el-icon-search" class="specsInput1" style="width: 100%"
                                                v-model="item.length"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6" style="padding-left: 3px; padding-right: 3px">
                                        <el-form-item :prop="'format.arr.' + index + '.width'" :rules="rules.format[1]">
                                            <el-input type="number" prefix-icon="el-icon-search"
                                                suffix-icon="el-icon-search" class="specsInput2" style="width: 100%"
                                                v-model="item.width"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6" style="padding-left: 3px">
                                        <el-form-item :prop="'format.arr.' + index + '.height'"
                                            :rules="rules.format[2]">
                                            <el-input @change="changeHeight(item.height, index)" type="number"
                                                prefix-icon="el-icon-search" suffix-icon="el-icon-search"
                                                class="specsInput3" style="width: 100%" v-model="item.height">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="5">
                                        <span v-if="index == 0" class="addIcon" @click="addSpace(1)">
                                            <i class="el-icon-plus"></i>
                                        </span>
                                        <span v-else class="addIcon" @click="addSpace(1, index)">
                                            <i class="el-icon-minus"></i>
                                        </span>
                                        <span style="padding-left: 8px"
                                            v-if="item.height && thickness_list.length == 0">
                                            <el-button v-if="item.show" @click="item.show = false" type="text">收起
                                            </el-button>
                                            <el-button v-else @click="item.show = true" type="text">展开</el-button>
                                        </span>
                                    </el-col>
                                </el-row>
                                <el-row v-if="item.show">
                                    <el-form-item label="可匹配基层：" label-width="100px">
                                        <el-switch v-model="item.select1" @change="changeSelect1(item.select1, index)">
                                        </el-switch>
                                    </el-form-item>
                                    <el-form-item label=" " label-width="100px">
                                        <el-checkbox-group :disabled="item.select1 == false" size="small"
                                            v-model="item.grassroots_id">
                                            <el-checkbox border @change="changeSelectGra($event, item1, index)"
                                                v-for="item1 in grassrootsList" :key="item1.id" :label="item1.id">{{
                                                item1.thickness_name }}</el-checkbox>
                                        </el-checkbox-group>
                                    </el-form-item>
                                    <el-form-item label="可匹配龙骨：" label-width="100px">
                                        <el-switch v-model="item.select2" @change="changeSelect2(item.select2, index)">
                                        </el-switch>
                                    </el-form-item>
                                    <el-form-item label=" " label-width="100px">
                                        <el-checkbox-group :disabled="item.select2 == false" size="small"
                                            v-model="item.keel_id">
                                            <el-checkbox border @change="changeSelectKel($event, item1, index)"
                                                v-for="item1 in keelList" :key="item1.id" :label="item1.id">{{
                                                item1.thickness_name }}</el-checkbox>
                                        </el-checkbox-group>
                                    </el-form-item>
                                    <el-form-item label="可匹配型材：" label-width="100px">
                                        <el-switch v-model="item.select3" @change="changeSelect3(item.select3, index)">
                                        </el-switch>
                                    </el-form-item>
                                    <el-form-item label=" " label-width="100px">
                                        <el-checkbox-group :disabled="item.select3 == false" size="small"
                                            v-model="item.structure_id">
                                            <el-checkbox border @change="changeSelectStr($event, item1, index)"
                                                v-for="item1 in structureList" :key="item1.id" :label="item1.id">{{
                                                item1.thickness_name }}</el-checkbox>
                                        </el-checkbox-group>
                                    </el-form-item>
                                </el-row>
                            </span>
                        </el-form-item>
                        <el-row v-for="(item, index) in ruleForm.scopes" :key="index">
                            <el-form-item label="变量范围(最小)" class="specsInput">
                                <span>
                                    <el-col :span="6">
                                        <el-form-item>
                                            <el-input type="number" prefix-icon="el-icon-search"
                                                suffix-icon="el-icon-search" class="specsInput1" style="width: 100%"
                                                v-model="item.minLength"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="2">&nbsp;</el-col>
                                    <el-col :span="6">
                                        <el-form-item>
                                            <el-input type="number" prefix-icon="el-icon-search"
                                                suffix-icon="el-icon-search" class="specsInput2" style="width: 100%"
                                                v-model="item.minWidth"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="2">&nbsp;</el-col>
                                    <el-col :span="6">
                                        <el-form-item>
                                            <el-input type="number" prefix-icon="el-icon-search"
                                                suffix-icon="el-icon-search" class="specsInput3" style="width: 100%"
                                                v-model="item.minHeight"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col v-if="index == 0" :span="2" @click="addSpace(3)" class="addIcon"><i
                                            class="el-icon-plus"></i></el-col>
                                    <el-col v-else :span="2" @click="addSpace(3, index)" class="addIcon"><i
                                            class="el-icon-minus"></i></el-col>
                                </span>
                            </el-form-item>
                            <el-form-item label="变量范围(最大)" class="specsInput">
                                <span>
                                    <el-col :span="6">
                                        <el-form-item>
                                            <el-input type="number" prefix-icon="el-icon-search"
                                                suffix-icon="el-icon-search" class="specsInput1" style="width: 100%"
                                                v-model="item.maxLength"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="2">&nbsp;</el-col>
                                    <el-col :span="6">
                                        <el-form-item>
                                            <el-input type="number" prefix-icon="el-icon-search"
                                                suffix-icon="el-icon-search" class="specsInput2" style="width: 100%"
                                                v-model="item.maxWidth"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="2">&nbsp;</el-col>
                                    <el-col :span="6">
                                        <el-form-item>
                                            <el-input type="number" prefix-icon="el-icon-search"
                                                suffix-icon="el-icon-search" class="specsInput3" style="width: 100%"
                                                v-model="item.maxHeight"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </span>
                            </el-form-item>
                        </el-row>
                        <el-form-item v-if="thickness_list.length" class="specsInput" label="适配吊顶厚度"
                            prop="ceiling_size">
                            <el-input v-model="ruleForm.ceiling_size" suffix-icon="el-icon-search"></el-input>
                        </el-form-item>
                        <el-form-item v-if="thickness_list.length" class="specsInput" label="适配墙板厚度"
                            prop="wall_panel_size">
                            <el-input v-model="ruleForm.wall_panel_size" suffix-icon="el-icon-search"></el-input>
                        </el-form-item>
                        <el-form-item label="构件价格" prop="price" class="priceInput">
                            <el-input @change="returnFloat" type="number" v-model="ruleForm.price"
                                suffix-icon="el-icon-search"></el-input>
                        </el-form-item>
                        <el-form-item label="纹理" prop="texture">
                            <el-input v-model="ruleForm.texture"></el-input>
                        </el-form-item>
                        <el-form-item label="颜色" prop="color">
                            <el-input v-model="ruleForm.color"></el-input>
                        </el-form-item>
                        <el-form-item v-if="showHu" label="弧形墙是否可用" prop="is_arcwall">
                            <el-radio-group size="small" v-model="ruleForm.is_arcwall">
                                <el-radio border v-for="item in selectArray" :key="item.id" :label="item.id">{{
                                item.name
                                }}</el-radio>
                            </el-radio-group>
                        </el-form-item>

                        <el-form-item label="构件说明" style="margin-bottom: 90px" prop="illustrate">
                            <Editor :createId="createId" :content="ruleForm.illustrate" :statusDisabled="statusDisabled"
                                @changeContent="changeContent"></Editor>
                        </el-form-item>
                    </el-row>

                    <el-row v-if="step == 2">
                        <el-form-item label="模型及贴图" style="width: 100%"> </el-form-item>
                        <el-form-item label="构件icon图">
                            <el-row>
                                <el-upload multiple :limit="10" action="" :file-list="ruleForm.icon_images"
                                    list-type="picture" :auto-upload="false" :on-change="handPreview1"
                                    :on-remove="handleRemove1">
                                    <el-button type="primary" size="mini">点击上传</el-button>
                                    <template #tip>
                                        <div class="el-upload__tip">PNG格式，沿用之前的大小1500*1500</div>
                                    </template>
                                </el-upload>
                            </el-row>
                        </el-form-item>
                        <el-form-item label="构件贴图">
                            <el-row>
                                <el-upload multiple :limit="10" list-type="picture" action=""
                                    :file-list="ruleForm.build_graph_images" :auto-upload="false"
                                    :on-change="handPreview" :on-remove="handleRemove">
                                    <el-button type="primary" size="mini">点击上传</el-button>
                                    <template #tip>
                                        <div class="el-upload__tip">
                                            墙顶地材质贴图以此类推，大小为1024*1024，无缝贴图，PNG
                                        </div>
                                    </template>
                                </el-upload>
                            </el-row>
                        </el-form-item>

                        <el-form-item style="margin-bottom: 90px" label="构件模型">
                            <el-row>
                                <el-upload multiple :limit="10" action="" :file-list="ruleForm.model_images"
                                    list-type="picture" :auto-upload="false" :on-change="handPreview2"
                                    :on-remove="handleRemove2">
                                    <el-button type="primary" size="mini">点击上传</el-button>
                                    <template #tip>
                                        <div class="el-upload__tip">结构件顶点必须小于800，三角面小于1500</div>
                                    </template>
                                </el-upload>
                            </el-row>
                        </el-form-item>
                    </el-row>
                    <el-form-item style="
                            border-bottom: 1px solid #dcdfe6;
                            border-top: 1px solid #dcdfe6;
                            background-color: #ffffff;
                            width: calc(100vw);
                            padding: 15px;
                            position: fixed;
                            bottom: 5px;
                            left: 200px;
                            z-index: 9999999999;
                            text-align: center;
                        " label-width="0px">
                        <el-row>
                            <el-col :span="13" :offset="2">
                                <el-button v-if="step == 1" style="width: 100px" type="primary" size="small"
                                    @click="submitForm('ruleForm', 1)">下一步</el-button>
                                <el-button v-if="step == 2" style="width: 100px" type="primary" size="small"
                                    @click="step = 1">上一步</el-button>
                                <el-button style="width: 100px; margin-left: 10px !important" type="primary"
                                    size="small" @click="submitForm('ruleForm', 2)">保存</el-button>
                                <el-button style="width: 100px; margin-left: 10px !important" size="small"
                                    @click="resetForm('ruleForm')">取消</el-button>
                            </el-col>
                        </el-row>
                    </el-form-item>
                </el-form>
            </el-col>
            <el-col :span="9" v-if="logList.length > 0" style="padding-left: 35px; text-align: left">
                <div style="background-color: #f8fafe; padding: 15px; max-height: 2490px; overflow-y: scroll">
                    <el-tabs model-value="first">
                        <el-tab-pane label="工单动态" name="first">
                            <div class="orderList">
                                <el-descriptions v-for="item in logList" :key="item.id" :column="3">
                                    <el-descriptions-item width="8px">●</el-descriptions-item>
                                    <el-descriptions-item>{{ item.username }}</el-descriptions-item>
                                    <el-descriptions-item align="right">{{
                                    getYMDHMS(item.createtime * 1000, 1)
                                    }}</el-descriptions-item>
                                    <el-descriptions-item>
                                        <span style="display: inline-block; width: 100%; padding-left: 12px">
                                            <span style="display: inline-block; width: 100%"
                                                v-for="(i, ind) in item.log_content" :key="ind">
                                                {{ i }}
                                            </span>
                                        </span>
                                    </el-descriptions-item>
                                </el-descriptions>
                                <el-divider></el-divider>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import Editor from '../components/Editor.vue';
import { getMsg, edit, detail, parameterList } from '../common/api/member/upload';
import cityArrr from '../router/newArea';
export default {
    name: 'MemberComponent',
    components: { Editor },
    props: {
        compontId: Number
    },
    data() {
        return {
            createId: 'member-0',
            step: 1,
            setup_list: [],
            material_list: [],
            showLong: false,
            showHu: false,
            thickness_list: [],
            selectArray: [
                {
                    id: 1,
                    name: '是'
                },
                {
                    id: 0,
                    name: '否'
                }
            ],
            brand_list: [],
            brandSeries_list: [],
            characteristic_list: [],
            style_list: [],
            spaceArea_list: [],
            toolingArea_list: [],
            provinceArray: cityArrr,
            apply_position: [],
            arr: [],
            ruleForm: {
                id: '',
                setup_id: '',
                setup_name: '',
                classify_id: '',
                son_classify_id: '',
                is_structure: 0,
                series_id: '',
                format: {
                    status: 0,
                    // showSelect:false,
                    arr: [
                        {
                            id: '',
                            select1: true,
                            select2: true,
                            select3: true,
                            grassroots_id: [],
                            keel_id: [],
                            structure_id: [],
                            show: false,
                            length: '',
                            width: '',
                            height: ''
                        }
                    ]
                },
                apply_position: [],
                sorts: '',
                brand_id: '',
                brand_name: '',
                style: [],
                name: '',
                number: '',
                tooling_area: [],
                characteristic: [],
                area: '',
                space_area: [],
                build_graph_images: [],
                model_images: [],
                icon_images: [],
                ceiling_size: '',
                wall_panel_size: '',
                price: '',
                scopes: [
                    {
                        minLength: '',
                        minWidth: '',
                        minHeight: '',
                        maxLength: '',
                        maxWidth: '',
                        maxHeight: ''
                    }
                ],
                material: '',
                pipeline_cavity: '',
                texture: '',
                color: '',
                is_arcwall: 0,
                illustrate: ''
            },
            rules: {
                name: [{ required: true, message: '请输入构件名称', trigger: 'blur' }],
                gd_name: [{ required: true, message: '请输入工单名称', trigger: 'blur' }],
                number: [{ required: true, message: '请输入构件编号', trigger: 'blur' }],
                setup_id: [{ required: true, message: '请选择体系', trigger: 'change' }],
                classify_id: [{ required: true, message: '请选择分类', trigger: 'change' }],
                // series_id:[
                //     { required: true, message: '请选择分类111', trigger: 'change' }
                // ],
                apply_position: [{ required: true, message: '请选择应用', trigger: 'change' }],
                son_classify_id: [{ required: true, message: '请选择材料', trigger: 'change' }],
                brand_id: [{ required: true, message: '请选择品牌', trigger: 'change' }],
                material: [{ required: true, message: '请输入材质', trigger: 'blur' }],
                // pipeline_cavity:[
                //     { required: true, message: '请输入管线空腔', trigger: 'blur' }
                // ],
                is_arcwall: [{ required: true, message: '请选择弧形墙是否可用', trigger: 'change' }],
                c_type: [{ required: true, message: '请选择构件类型', trigger: 'change' }],
                s_type: [{ required: true, message: '请选择分类', trigger: 'change' }],
                s_id: [{ required: true, message: '请选择构件类型', trigger: 'change' }],
                estimateTime: [{ required: true, message: '请选择当前状态完成时间', trigger: 'change' }],
                handler_id: [{ required: true, message: '请选择当前阶段负责人', trigger: 'change' }],
                duty_id: [{ required: true, message: '请选择工单负责人', trigger: 'change' }],
                format: [
                    { required: true, message: '请输入长', trigger: 'blur' },
                    { required: true, message: '请输入宽', trigger: 'blur' },
                    { required: true, message: '请输入厚', trigger: 'blur' }
                ],
                c_other_construct: [{ required: true, message: '请输入其他配件名称', trigger: 'blur' }],
                price: [{ required: true, message: '请输入价格', trigger: 'blur' }]
                // illustrate: [
                //     { required: true, message: '请选择活动区域', trigger: 'blur' }
                //     { required: true, message: '请选择活动区域', trigger: 'blur' }
                // ],
                // build_graph_images: [
                //     { required: true, message: '请上传构件贴图', trigger: 'blur' }
                // ],
                // icon_images: [
                //     { required: true, message: '请上传构件icon图', trigger: 'blur' }
                // ],
                // model_images: [
                //     { required: true, message: '请上传构件模型', trigger: 'blur' }
                // ],
            },
            deleteImgArr: [],
            grassrootsList: [],
            keelList: [],
            structureList: [],

            closeIcon: true,
            statusDisabled: false,
            isEdit: false,
            orderLog: false,
            u_nickname: '',
            c_name: '',
            style1: 'padding-bottom:8px;display:inline-block;min-width:415px',
            title: '上传构件',
            dialogImageUrl: '',
            other_construct: 0,
            logList: [],
            UserArray: []
        };
    },
    created() {
        this.getMsg();
    },
    watch: {
        // 'ruleForm.setup_id'(){
        //     this.changeSetupId();
        // },
        // 'ruleFom.classify_id'(){
        //     this.changeMaterialId();
        // },
        // 'ruleForm.brand_id'(){
        //     this.changeBrand();
        // }
    },
    methods: {
        returnFloat() {
            if (this.ruleForm.price) {
                var val = Math.round(parseFloat(this.ruleForm.price) * 100) / 100;
                var s = val.toString().split('.');
                if (s.length == 1) {
                    val = val.toString() + '.00';
                    this.ruleForm.price = val;
                }
                if (s.length > 1) {
                    if (s[1].length < 2) {
                        val = val.toString() + '0';
                    }
                    this.ruleForm.price = val;
                }
            }
        },
        returnFloat1() {
            if (this.ruleForm.pipeline_cavity) {
                var val = Math.round(parseFloat(this.ruleForm.pipeline_cavity) * 100) / 100;
                var s = val.toString().split('.');
                if (s.length == 1) {
                    val = val.toString() + '.00';
                    this.ruleForm.pipeline_cavity = val;
                }
                if (s.length > 1) {
                    if (s[1].length < 2) {
                        val = val.toString() + '0';
                    }
                    this.ruleForm.pipeline_cavity = val;
                }
            }
        },
        changeSelectStr(val, item, index) {
            if (val) {
                if (!this.arr[index]) {
                    this.$message.error('该尺寸未匹配到型材，请去添加');
                    return false;
                }
                let status = true;
                this.arr[index].structure.forEach(ele => {
                    if (parseInt(ele.structure_id) == item.id) {
                        status = false;
                    }
                });
                if (status) {
                    this.$message.error('该尺寸未匹配到型材，请去添加');
                }
            }
            // if (!this.arr[index]){
            //     this.$message.error('该尺寸未匹配到型材，请去添加');
            //     return false;
            // }
            // let status = true;
            // this.arr[index].structure.forEach((ele)=>{
            //     if (parseInt(ele.structure_id) == item.id){
            //         status = false;
            //     }
            // })
            // if (status){
            //     this.$message.error('该尺寸未匹配到型材，请去添加');
            // }
        },
        changeSelectKel(val, item, index) {
            if (val) {
                if (!this.arr[index]) {
                    this.$message.error('该尺寸未匹配到龙骨，请去添加');
                    return false;
                }
                let status = true;
                this.arr[index].keel.forEach(ele => {
                    if (parseInt(ele.keel_id) == item.id) {
                        status = false;
                    }
                });
                if (status) {
                    this.$message.error('该尺寸未匹配到龙骨，请去添加');
                }
            }
            // if (!this.arr[index]){
            //     this.$message.error('该尺寸未匹配到龙骨，请去添加');
            //     return false;
            // }
            // let status = true;
            // this.arr[index].keel.forEach((ele)=>{
            //     if (parseInt(ele.keel_id) == item.id){
            //         status = false;
            //     }
            // })
            // if (status){
            //     this.$message.error('该尺寸未匹配到龙骨，请去添加');
            // }
        },
        changeSelectGra(val, item, index) {
            if (val) {
                if (!this.arr[index]) {
                    this.$message.error('该尺寸未匹配到基层，请去添加');
                    return false;
                }
                let status = true;
                this.arr[index].grassroots.forEach(ele => {
                    if (parseInt(ele.grassroots_id) == item.id) {
                        status = false;
                    }
                });
                if (status) {
                    this.$message.error('该尺寸未匹配到基层，请去添加');
                }
            }
        },
        jump(type) {
            if (type == 1) {
                this.$router.push('/category');
            }
            if (type == 2) {
                this.$router.push('/brandManage');
            }
            if (type == 3) {
                this.$router.push('/charaManage');
            }
            if (type == 4) {
                this.$router.push('/styleManage');
            }
            if (type == 5) {
                this.$router.push('/spatialArea');
            }
            if (type == 6) {
                this.$router.push('/spatialArea');
            }
        },
        changeSelect1(val, index) {
            if (val == false) {
                this.ruleForm.format.arr[index].grassroots_id = [];
            }
        },
        changeSelect2(val, index) {
            if (val == false) {
                this.ruleForm.format.arr[index].keel_id = [];
            }
        },
        changeSelect3(val, index) {
            if (val == false) {
                this.ruleForm.format.arr[index].structure_id = [];
            }
        },
        changeHeightAll() {
            let all = this.ruleForm.format.arr;
            all.forEach((item, index) => {
                this.changeHeight(all[index].height, index, 1, false);
            });
        },
        changeHeight(val, index, type, clear = true) {
            if (this.ruleForm.classify_id) {
                let tmpId = '';
                this.material_list.forEach(element => {
                    if (this.ruleForm.classify_id == element.id) {
                        //墙板情况下匹配厚度数值
                        if (this.confirmType.indexOf(element.material_name) < 0) {
                            element.thickness_list.forEach(ele => {
                                if (ele.thickness_size == val) {
                                    tmpId = val;
                                    if (ele.wildcard_list.length) {
                                        let tmp = {
                                            grassroots: ele.wildcard_list[0].grassroots
                                                ? ele.wildcard_list[0].grassroots
                                                : [],
                                            keel: ele.wildcard_list[0].keel ? ele.wildcard_list[0].keel : [],
                                            structure: ele.wildcard_list[0].structure
                                                ? ele.wildcard_list[0].structure
                                                : []
                                        };
                                        this.ruleForm.format.arr[index].select1 = true;
                                        this.ruleForm.format.arr[index].select2 = true;
                                        this.ruleForm.format.arr[index].select3 = true;
                                        this.arr[index] = tmp;
                                    } else {
                                        let tmp = {
                                            grassroots: [],
                                            keel: [],
                                            structure: []
                                        };
                                        this.arr[index] = tmp;
                                    }
                                    this.ruleForm.format.arr[index].id = ele.id;
                                }
                            });
                        } else {
                            //非墙板下不匹配直接输入厚度数值
                            tmpId = 99;
                        }
                    }
                });
                if (tmpId == '') {
                    if (!type) {
                        this.$message.error('没有匹配到对应的厚度配置，请去添加!');
                    }
                    if (this.arr[index]) {
                        this.arr.splice(index, 1);
                    }
                    this.ruleForm.format.arr[index].id = '';
                }
            } else {
                this.$message.error('请先选择构件类型');
                this.ruleForm.format.arr[index].height = '';
            }
            if (clear) {
                this.ruleForm.format.arr[index].grassroots_id = [];
                this.ruleForm.format.arr[index].keel_id = [];
                this.ruleForm.format.arr[index].structure_id = [];
            }
        },
        changeBrand(type) {
            if (type) {
                this.ruleForm.series_id = '';
                this.brandSeries_list = [];
            }
            this.brand_list.forEach(ele => {
                if (this.ruleForm.brand_id == ele.b_id) {
                    this.ruleForm.brand_name = ele.b_name;
                    this.brandSeries_list = ele.brandSeries_list;
                }
            });
        },
        changeSetupId(type) {
            if (type) {
                this.ruleForm.classify_id = '';
                this.material_list = [];
                this.ruleForm.son_classify_id = '';
                this.thickness_list = [];
            }

            this.setup_list.forEach(ele => {
                if (ele.id == this.ruleForm.setup_id) {
                    this.ruleForm.setup_name = ele.setup_name;
                    this.material_list = ele.material_list;
                }
            });
            let dt = {
                setup_id: this.ruleForm.setup_id
            };
            parameterList(dt).then(res => {
                this.grassrootsList = res.result.grassrootsList;
                this.keelList = res.result.keelList;
                this.structureList = res.result.structureList;
            });
        },
        changeMaterialId(type) {
            if (type) {
                this.ruleForm.son_classify_id = '';
                this.thickness_list = [];
                this.ruleForm.format = {
                    status: 0,
                    // showSelect:false,
                    arr: [
                        {
                            id: '',
                            select1: true,
                            select2: true,
                            select3: true,
                            grassroots_id: [],
                            keel_id: [],
                            structure_id: [],
                            show: false,
                            length: '',
                            width: '',
                            height: ''
                        }
                    ]
                };
            }
            this.showLong = false;
            this.showHu = false;
            this.material_list.forEach(element => {
                if (this.ruleForm.classify_id == element.id) {
                    let aa = [];
                    let tt = element.thickness_list;
                    tt.forEach((v) => {
                        if (parseInt(v.thickness_size) == 0) {
                            aa.push(v);
                        }
                    })
                    this.thickness_list = aa;
                }
                if (
                    this.ruleForm.classify_id == element.id &&
                    parseInt(this.confirmType.indexOf(element.material_name)) > -1
                ) {
                    if (element.material_name == '龙骨') {
                        this.showLong = true;
                    }
                    // this.thickness_list = element.thickness_list;
                    // this.ruleForm.format.showSelect = false;
                } else {
                    if (this.ruleForm.classify_id == element.id) {
                        this.showHu = true;
                    }
                    this.ruleForm.format = {
                        status: 0,
                        // showSelect:false,
                        arr: [
                            {
                                id: '',
                                select1: true,
                                select2: true,
                                select3: true,
                                grassroots_id: [],
                                keel_id: [],
                                structure_id: [],
                                show: false,
                                length: '',
                                width: '',
                                height: ''
                            }
                        ]
                    };
                    this.arr = [];
                    // this.ruleForm.format.showSelect = true;
                }
            });
        },
        getMsg() {
            getMsg().then(res => {
                this.setup_list = res.result.setup_list;
                this.brand_list = res.result.brand_list;
                this.characteristic_list = res.result.characteristic_list;
                this.style_list = res.result.style_list;
                this.spaceArea_list = res.result.spaceArea_list;
                this.toolingArea_list = res.result.toolingArea_list;
                this.apply_position = res.result.apply_position;
                if (this.compontId) {
                    detail({ id: this.compontId }).then(res => {
                        let r = res.result;
                        this.ruleForm.id = r.id;
                        this.isEdit = true;
                        this.createId = 'member-' + r.id;
                        this.ruleForm.setup_id = r.setup_id;
                        this.changeSetupId();
                        this.ruleForm.setup_name = r.setup_name;
                        this.ruleForm.classify_id = r.classify_id;
                        this.changeMaterialId();
                        this.ruleForm.son_classify_id = parseInt(r.son_classify_id);
                        this.ruleForm.is_structure = r.is_structure;
                        this.ruleForm.series_id = r.series_id;
                        this.ruleForm.sorts = r.sorts ? r.sorts : '';
                        this.ruleForm.apply_position = r.apply_position.length ? r.apply_position : [];
                        this.ruleForm.brand_id = r.brand_id;
                        this.ruleForm.brand_name = r.brand_name;
                        this.changeBrand();
                        this.ruleForm.name = r.name;
                        this.title = r.name;
                        this.ruleForm.number = r.number;
                        this.ruleForm.area = r.area;
                        this.ruleForm.price = r.price;
                        this.ruleForm.material = r.material;
                        this.ruleForm.pipeline_cavity = r.pipeline_cavity;
                        this.ruleForm.texture = r.texture;
                        this.ruleForm.color = r.color;
                        this.ruleForm.is_arcwall = r.is_arcwall ? r.is_arcwall : 0;
                        this.ruleForm.illustrate = r.illustrate;
                        this.ruleForm.style = this.stringToInt(r.style);
                        this.ruleForm.tooling_area = this.stringToInt(r.tooling_area);
                        this.ruleForm.characteristic = this.stringToInt(r.characteristic);
                        this.ruleForm.space_area = this.stringToInt(r.space_area);
                        this.ruleForm.format.status = parseInt(r.format.status);
                        if (r.format.format.length) {
                            this.ruleForm.format.arr = [];
                        }
                        r.format.format.forEach(element => {
                            let gra = [];
                            element.grassroots.forEach(e => {
                                gra.push(e);
                            });
                            let kee = [];
                            element.keel.forEach(e => {
                                kee.push(e);
                            });
                            let str = [];
                            element.structure.forEach(e => {
                                str.push(e);
                            });
                            let tmp = {
                                id: '',
                                select1: true,
                                select2: true,
                                select3: true,
                                grassroots_id: gra.length ? gra : '',
                                keel_id: kee.length ? kee : '',
                                structure_id: str.length ? str : '',
                                show: false,
                                length: element.length,
                                width: element.width,
                                height: element.height
                            };
                            this.ruleForm.format.arr.push(tmp);
                        });
                        this.changeHeightAll();
                        if (r.least_scope.length) {
                            this.ruleForm.scopes = [];
                            let min = r.least_scope;
                            let max = r.maximum_scope;
                            min.forEach((item, index) => {
                                let tt = {
                                    minLength: item.length,
                                    minWidth: item.width,
                                    minHeight: item.height,
                                    maxLength: max[index].length,
                                    maxWidth: max[index].width,
                                    maxHeight: max[index].height
                                };
                                this.ruleForm.scopes.push(tt);
                            });
                        }
                        let arr1 = [];
                        let img1 = r.build_graph_images;
                        for (var p1 = 0; p1 < img1.length; p1++) {
                            let fileType1 = this.checkFileType({ name: img1[p1] });
                            let url1 = this.getFileUrl(fileType1);
                            if (url1 == '') {
                                url1 = img1[p1];
                            }
                            let num = img1[p1].lastIndexOf('/');
                            let fname1 = img1[p1].substring(num + 1);
                            arr1.push({
                                url: url1,
                                url1: img1[p1],
                                name: fname1
                            });
                        }
                        this.ruleForm.build_graph_images = arr1;
                        let arr2 = [];
                        let img2 = r.icon_images;
                        for (var p2 = 0; p2 < img2.length; p2++) {
                            let fileType2 = this.checkFileType({ name: img2[p2] });
                            let url2 = this.getFileUrl(fileType2);
                            if (url2 == '') {
                                url2 = img2[p2];
                            }
                            let num = img2[p2].lastIndexOf('/');
                            let fname2 = img2[p2].substring(num + 1);
                            arr2.push({
                                url: url2,
                                url1: img2[p2],
                                name: fname2
                            });
                        }
                        this.ruleForm.icon_images = arr2;
                        let arr3 = [];
                        let img3 = r.model_images;
                        for (var p3 = 0; p3 < img3.length; p3++) {
                            let fileType3 = this.checkFileType({ name: img3[p3] });
                            let url3 = this.getFileUrl(fileType3);
                            if (url3 == '') {
                                url3 = img3[p3];
                            }
                            let num = img3[p3].lastIndexOf('/');
                            let fname3 = img3[p3].substring(num + 1);
                            arr3.push({
                                url: url3,
                                url1: img3[p3],
                                name: fname3
                            });
                        }
                        this.ruleForm.model_images = arr3;
                        if (this.thickness_list.length) {
                            this.ruleForm.ceiling_size = r.ceiling_size.length ? r.ceiling_size[0].size : '';
                            this.ruleForm.wall_panel_size = r.wall_panel_size.length ? r.wall_panel_size[0].size : '';
                        }
                    });
                }
            });
        },
        stringToInt(arr) {
            let a = [];
            arr.forEach(ele => {
                if (ele) {
                    a.push(parseInt(ele));
                }
            });
            return a;
        },
        getYMDHMS(timestamp, type) {
            var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + '-';
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
            var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
            let strDate = '';
            let m = '';
            if (type) {
                m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
                var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();

                strDate = Y + M + D + h + m + s;
            } else {
                m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
                strDate = Y + M + D + h + m;
            }
            return strDate;
        },
        addSpace(type, index) {
            if (type == 1) {
                if (index) {
                    //减去
                    this.ruleForm.format.arr.splice(index, 1);
                } else {
                    this.ruleForm.format.arr.push({
                        id: '',
                        select1: true,
                        select2: true,
                        select3: true,
                        grassroots_id: [],
                        keel_id: [],
                        structure_id: [],
                        show: false,
                        length: '',
                        width: '',
                        height: ''
                    });
                }
            }
            if (type == 3) {
                if (index) {
                    //减去
                    this.ruleForm.scopes.splice(index, 1);
                } else {
                    this.ruleForm.scopes.push({
                        minLength: '',
                        minWidth: '',
                        minHeight: '',
                        maxLength: '',
                        maxWidth: '',
                        maxHeight: ''
                    });
                }
            }
        },
        handleClose() {
            this.resetForm('ruleForm');
            if (this.isEdit) {
                this.$parent.handleClose();
            }
        },
        changeContent(val) {
            if (this.ruleForm.illustrate != val) {
                this.ruleForm.illustrate = val;
                this.$refs['ruleForm'].validateField('illustrate');
            }
        },
        submitForm(formName, type) {
            let _this = this;
            this.$refs[formName].validate(valid => {
                if (valid) {
                    if (type == 1) {
                        _this.step = 2;
                    } else {
                        let dt = new FormData();
                        for (let key in _this.ruleForm) {
                            if (key == 'build_graph_images' && _this.ruleForm.build_graph_images.length != 0) {
                                let tmp1 = _this.ruleForm.build_graph_images;
                                for (var i1 = 0; i1 < tmp1.length; i1++) {
                                    if (tmp1[i1].raw) {
                                        //新增的图片
                                        dt.append('build_graph_images[]', tmp1[i1].raw);
                                    } else {
                                        dt.append('rawbuild_graph_images[]', tmp1[i1].url1);
                                    }
                                }
                            } else if (key == 'model_images' && _this.ruleForm.model_images.length != 0) {
                                let tmp3 = _this.ruleForm.model_images;
                                for (var i3 = 0; i3 < tmp3.length; i3++) {
                                    if (tmp3[i3].raw) {
                                        //新增的图片
                                        dt.append('model_images[]', tmp3[i3].raw);
                                    } else {
                                        dt.append('rawmodel_images[]', tmp3[i3].url1);
                                    }
                                }
                            } else if (key == 'icon_images' && _this.ruleForm.icon_images.length != 0) {
                                let tmp2 = _this.ruleForm.icon_images;
                                for (var i2 = 0; i2 < tmp2.length; i2++) {
                                    if (tmp2[i2].raw) {
                                        //新增的图片
                                        dt.append('icon_images[]', tmp2[i2].raw);
                                    } else {
                                        dt.append('rawicon_images[]', tmp2[i2].url1);
                                    }
                                }
                            } else if (key == 'format') {
                                let tmp4 = _this.ruleForm.format.arr;
                                let seriseArr = [];
                                dt.append('format[status]', _this.ruleForm.format.status);
                                for (var i4 = 0; i4 < tmp4.length; i4++) {
                                    dt.append('format[format][' + i4 + '][length]', tmp4[i4].length);
                                    dt.append('format[format][' + i4 + '][width]', tmp4[i4].width);
                                    dt.append('format[format][' + i4 + '][height]', tmp4[i4].height);
                                    dt.append('format[format][' + i4 + '][grassroots]', tmp4[i4].grassroots_id);
                                    dt.append('format[format][' + i4 + '][keel]', tmp4[i4].keel_id);
                                    dt.append('format[format][' + i4 + '][structure]', tmp4[i4].structure_id);
                                    let graArr = [];
                                    tmp4[i4].grassroots_id.forEach(element => {
                                        if (_this.arr[i4]) {
                                            _this.arr[i4].grassroots.forEach(e => {
                                                if (parseInt(e.grassroots_id) == element) {
                                                    graArr.push(element);
                                                }
                                            });
                                        }
                                    });
                                    if (graArr.length) {
                                        graArr.forEach((ele, index) => {
                                            dt.append(
                                                'format[format][' +
                                                i4 +
                                                '][matchGrassroots][' +
                                                index +
                                                '][matchGrassroots_id]',
                                                ele
                                            );
                                        });
                                    }

                                    let keeArr = [];
                                    tmp4[i4].keel_id.forEach(element => {
                                        if (_this.arr[i4]) {
                                            _this.arr[i4].keel.forEach(e => {
                                                if (parseInt(e.keel_id) == element) {
                                                    keeArr.push(element);
                                                }
                                            });
                                        }
                                    });
                                    if (keeArr.length) {
                                        keeArr.forEach((ele, index) => {
                                            dt.append(
                                                'format[format][' + i4 + '][matchKell][' + index + '][matchKell_id]',
                                                ele
                                            );
                                        });
                                    }
                                    // if (_this.arr[i4]){
                                    //     _this.arr[i4].keel.forEach((e)=>{
                                    //         if (parseInt(e.keel_id) == tmp4[i4].keel_id){
                                    //             keeArr = tmp4[i4].keel_id;
                                    //         }
                                    //     })
                                    // }
                                    // if (keeArr){
                                    //     dt.append("format[format]["+i4+"][matchKell][0][matchKell_id]",keeArr);
                                    // }
                                    //

                                    let strArr = [];
                                    tmp4[i4].structure_id.forEach(element => {
                                        if (_this.arr[i4]) {
                                            _this.arr[i4].structure.forEach(e => {
                                                if (parseInt(e.structure_id) == element) {
                                                    strArr.push(element);
                                                }
                                            });
                                        }
                                    });
                                    if (strArr.length) {
                                        strArr.forEach((ele, index) => {
                                            dt.append(
                                                'format[format][' +
                                                i4 +
                                                '][matchStructure][' +
                                                index +
                                                '][matchStructure_id]',
                                                ele
                                            );
                                        });
                                    }

                                    // if (_this.arr[i4]){
                                    //     _this.arr[i4].structure.forEach((e)=>{
                                    //         if (parseInt(e.structure_id) == tmp4[i4].structure_id){
                                    //             strArr = tmp4[i4].structure_id;
                                    //         }
                                    //     })
                                    // }
                                    // if (strArr){
                                    //     dt.append("format[format]["+i4+"][matchStructure][0][matchStructure_id]",strArr);
                                    // }

                                    if (tmp4[i4].id) {
                                        seriseArr.push(tmp4[i4].id);
                                    }
                                }
                                seriseArr = _this.unique(seriseArr);
                                dt.append('format[size_id]', seriseArr.toString());
                            } else if (key == 'scopes') {
                                let tmp5 = _this.ruleForm.scopes;
                                for (var i5 = 0; i5 < tmp5.length; i5++) {
                                    dt.append('least_scope[' + i5 + '][length]', tmp5[i5].minLength);
                                    dt.append('maximum_scope[' + i5 + '][length]', tmp5[i5].maxLength);
                                    dt.append('least_scope[' + i5 + '][width]', tmp5[i5].minWidth);
                                    dt.append('maximum_scope[' + i5 + '][width]', tmp5[i5].maxWidth);
                                    dt.append('least_scope[' + i5 + '][height]', tmp5[i5].minHeight);
                                    dt.append('maximum_scope[' + i5 + '][height]', tmp5[i5].maxHeight);
                                }
                            } else if (key == 'ceiling_size') {
                                if (_this.ruleForm.ceiling_size) {
                                    dt.append('ceiling_size[0][size]', _this.ruleForm.ceiling_size);
                                }
                            } else if (key == 'wall_panel_size') {
                                if (_this.ruleForm.wall_panel_size) {
                                    dt.append('wall_panel_size[0][size]', _this.ruleForm.wall_panel_size);
                                }
                            } else {
                                dt.append(key, _this.ruleForm[key]);
                            }
                        }
                        if (_this.deleteImgArr.length) {
                            _this.deleteImgArr.forEach(ele => {
                                dt.append('del_images[]', ele);
                            });
                        }
                        for (let [a, b] of dt.entries()) {
                            // console.log(a + '=>' + b);
                        }
                        if (_this.ruleForm.id) {
                            edit(dt, 1).then(res => {
                                _this.$message.success(res.msg);
                                _this.resetForm('ruleForm');
                                _this.handleClose();
                            });
                        } else {
                            edit(dt).then(res => {
                                _this.$message.success(res.msg);
                                this.step = 1;
                                this.$refs['ruleForm'].resetFields();
                                this.$store.mutations.SetKeep(this.$store.state, 'member');
                                _this.$router.push('/member');
                            });
                        }
                    }
                } else {
                    return false;
                }
            });
        },
        unique(arr) {
            if (!Array.isArray(arr)) {
                return;
            }
            var array = [];
            for (var i = 0; i < arr.length; i++) {
                if (!array.includes(arr[i])) {
                    //includes 检测数组是否有某个值
                    array.push(arr[i]);
                }
            }
            return array;
        },
        resetForm(formName) {
            this.step = 1;
            this.$refs[formName].resetFields();
            if (this.isEdit) {
                this.$parent.handleClose();
            }
            // this.handleClose();
        },
        handleRemove(file, fileList) {
            if (!file.raw) {
                this.deleteImgArr.push(file.url1);
            }
            this.ruleForm.build_graph_images = fileList;
            this.$refs['ruleForm'].validateField('build_graph_images');
        },
        handleRemove1(file, fileList) {
            if (!file.raw) {
                this.deleteImgArr.push(file.url1);
            }
            this.ruleForm.icon_images = fileList;
            this.$refs['ruleForm'].validateField('icon_images');
        },
        handleRemove2(file, fileList) {
            if (!file.raw) {
                this.deleteImgArr.push(file.url1);
            }
            this.ruleForm.model_images = fileList;
            this.$refs['ruleForm'].validateField('model_images');
        },
        handPreview(file, fileList) {
            // const isLt2M = file.size / 1024 / 1024 < 15;
            // if (!isLt2M) {
            //     this.$message.error('上传文件大小不能超过 15MB!');
            //     fileList.splice((fileList.length-1),1);
            // }else{
            let fileType = this.checkFileType(file);
            let url = this.getFileUrl(fileType);
            if (url) {
                file.url = url;
            }
            // }
            this.ruleForm.build_graph_images.push(file);
            this.$refs['ruleForm'].validateField('build_graph_images');
        },
        handPreview1(file, fileList) {
            // const isLt2M = file.size / 1024 / 1024 < 30;
            // if (!isLt2M) {
            //     this.$message.error('上传文件大小不能超过 30MB!');
            //     fileList.splice((fileList.length-1),1);
            // }else{
            let fileType = this.checkFileType(file);
            let url = this.getFileUrl(fileType);
            if (url) {
                file.url = url;
            }
            // }
            this.ruleForm.icon_images.push(file);
            this.$refs['ruleForm'].validateField('icon_images');
        },
        handPreview2(file, fileList) {
            // const isLt2M = file.size / 1024 / 1024 < 50;
            // if (!isLt2M) {
            //     this.$message.error('上传文件大小不能超过 50MB!');
            //     fileList.splice((fileList.length-1),1);
            // }else{
            //
            // }
            let fileType = this.checkFileType(file);
            let url = this.getFileUrl(fileType);
            if (url) {
                file.url = url;
            }
            this.ruleForm.model_images.push(file);
            this.$refs['ruleForm'].validateField('model_images');
        },
        checkFileType(file) {
            // 以检查文件是否为.jpg为例
            const fileName = file.name;
            const fileType = fileName.substring(fileName.lastIndexOf('.'));
            return fileType;
        },
        // word.png   3dmax.png    dwg.png  pdf.png su.png mp4.png
        getFileUrl(fileType) {
            let url = '';
            let name = fileType.toLowerCase();
            if (name == '.mp4') {
                url = 'http://qy2.miaoxiang.co/static/img/vue/su.png';
            } else if (name == '.doc' || name == '.docx') {
                url = 'http://qy2.miaoxiang.co/static/img/vue/word.png';
            } else if (name == '.dwg') {
                url = 'http://qy2.miaoxiang.co/static/img/vue/dwg.png';
            } else if (name == '.max') {
                url = 'http://qy2.miaoxiang.co/static/img/vue/3dmax.png';
            } else if (name == '.pdf') {
                url = 'http://qy2.miaoxiang.co/static/img/vue/pdf.png';
            } else if (name == '.su') {
                url = 'http://qy2.miaoxiang.co/static/img/vue/su.png';
            } else {
                url = '';
            }
            return url;
        }
    }
};
</script>

<style>
.MemberComponent {
    text-align: center;
}

.MemberComponent .el-checkbox.is-bordered {
    padding: 7px 12px;
    height: 35px;
}

.MemberComponent .el-radio.is-bordered {
    padding: 9px 12px;
    height: 35px;
}

.MemberComponent .el-button+.el-button,
.el-checkbox.is-bordered+.el-checkbox.is-bordered,
.el-radio.is-bordered+.el-radio.is-bordered {
    margin-left: 0 !important;
}

.MemberComponent .el-checkbox {
    margin-right: 5px;
}

.MemberComponent .el-radio {
    margin-right: 8px;
    margin-bottom: 5px;
}

.MemberComponent .title {
    width: 100%;
    text-align: center;
    height: 65px;
    line-height: 65px;
    border-bottom: 1px solid #dcdfe6;
}

.MemberComponent .addIcon {
    display: inline-block;
    cursor: pointer;
    width: 35px;
    height: 35px;
    text-align: center;
    margin-left: 8px;
    line-height: 35px;
    border: 1px solid #dcdfe6;
    color: var(--el-text-color-regular);
    background-color: #ffffff;
    margin-top: 1px;
}

.MemberComponent .switchSpan {
    display: inline-block;
    margin-right: 10px;
    color: var(--el-text-color-regular);
    margin-left: 12px;
    margin-top: 2px;
}

.MemberComponent .el-descriptions__body {
    background-color: #f8fafe !important;
}

.MemberComponent .el-divider--horizontal {
    margin: 0 0 12px 0;
}

.MemberComponent .statusSpan {
    display: inline-block;
    width: 75px;
    text-align: center;
}

.MemberComponent .zhushi {
    font-size: 12px;
    color: #909396;
}

.MemberComponent .zhushi1 {
    font-size: 12px;
    color: #909396;
    display: inline-block;
    padding-top: 115px;
    padding-left: 15px;
    float: right;
}

/*.MemberComponent .w-e-toolbar{*/
/*    z-index: 1!important;*/
/*}*/
/*.MemberComponent .w-e-text-container{*/
/*    z-index: 1!important;*/
/*}*/
.el-tag+.el-tag {
    margin-left: 10px;
}

.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

.input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}

.MemberComponent .specsInput1 .el-input__icon:after {
    content: '长' !important;
    width: 15px;
}

.MemberComponent .specsInput2 .el-input__icon:after {
    content: '宽' !important;
    width: 15px;
}

.MemberComponent .specsInput3 .el-input__icon:after {
    content: '厚' !important;
    width: 15px;
}

.MemberComponent .specsInput3 .el-input__suffix-inner .el-input__icon {
    padding-right: 8px;
}

.MemberComponent .specsInput .el-input__suffix-inner .el-input__icon:after {
    content: 'mm ' !important;
    width: 26px;
    padding-right: 8px;
    height: 35px;
    line-height: 30px;
}

.MemberComponent .priceInput .el-input__suffix-inner .el-input__icon:after {
    content: '￥ ' !important;
    width: 26px;
    padding-right: 8px;
    height: 35px;
    line-height: 30px;
}

.MemberComponent .orderList .el-descriptions__label {
    display: none;
}

/*.MemberComponent .specsInput4 .el-input__suffix-inner .el-input__icon:after{*/
/*    content: '(件)' !important;*/
/*    width: 26px;*/
/*    padding-right: 8px;*/
/*}*/

.MemberComponent .specsInput .el-icon-search:before {
    display: none;
}

.MemberComponent .priceInput .el-icon-search:before {
    display: none;
}

.MemberComponent .specsInput4 .el-icon-search:before {
    display: none;
}

.MemberComponent .specsInput4 .el-input__inner {
    padding-left: 15px;
}

.MemberComponent .specsInput .el-input__suffix-inner .el-icon-search:before {
    display: none;
}

.MemberComponent .el-upload-list--picture-card .el-upload-list__item {
    margin-bottom: 0;
}

.MemberComponent .el-upload__tip {
    margin-top: 0;
    line-height: 18px;
}

.MemberComponent .tmpDes .el-descriptions__label {
    display: block;
    /*margin-right: 0!important;*/
}

.MemberComponent .el-form-item {
    margin-bottom: 18px;
    width: 100%;
}

.MemberComponent .el-select__popper.el-popper[role='tooltip'] {
    z-index: 9999999 !important;
}

/*.MemberComponent .picList .el-upload {*/
/*    background-color: #fbfdff;*/
/*    border: 1px dashed #c0ccda;*/
/*    border-radius: 6px;*/
/*    -webkit-box-sizing: border-box;*/
/*    box-sizing: border-box;*/
/*    width: 148px;*/
/*    height: 148px;*/
/*    cursor: pointer;*/
/*    line-height: 146px;*/
/*    vertical-align: top;*/
/*    font-size: 28px;*/
/*    color: #8c939d;*/
/*    float: right;*/
/*}*/

/*.MemberComponent .picList .el-upload-list--picture .el-upload-list__item {*/
/*    overflow: hidden;*/
/*    z-index: 0;*/
/*    background-color: rgb(249, 249, 249);*/
/*    border: 0px;*/
/*    border-radius: 6px;*/
/*    -webkit-box-sizing: border-box;*/
/*    box-sizing: border-box;*/
/*    margin-top: 0px;*/
/*    padding: 0;*/
/*    width: 148px;*/
/*    height: 188px;*/
/*    margin-right: 12px;*/
/*}*/
/*.MemberComponent .picList .el-upload-list{*/
/*    float: left;*/
/*}*/
/*.MemberComponent .picList .el-upload-list--picture .el-upload-list__item-thumbnail {*/
/*    vertical-align: middle;*/
/*    display: inline-block;*/
/*    width: 148px;*/
/*    height: 148px;*/
/*    float: left;*/
/*    !* position: relative; *!*/
/*    z-index: 1;*/
/*     margin-left: 0px;*/
/*    background-color: #fff;*/
/*    border-radius: 6px;*/
/*}*/
/*.MemberComponent .picList .el-upload-list--picture .el-upload-list__item-thumbnail:hover {*/
/*    opacity: 1;*/
/*    background-color: rgba(0,0,0,.5);*/
/*}*/
/*.MemberComponent .picList .el-upload-list--picture .el-upload-list__item-name i{*/
/*    display: none;*/
/*}*/
/*.MemberComponent .picList .el-upload-list--picture .el-upload-list__item-name {*/
/*    display: block;*/
/*    margin-top: 20px;*/
/*    width: 148px;*/
/*}*/
/*.MemberComponent .picList .el-upload-list__item .el-icon-close {*/
/*    display: block;*/
/*    position: absolute;*/
/*    top: 5px;*/
/*    right: 5px;*/
/*    cursor: pointer;*/
/*    opacity: .75;*/
/*    color: red;*/
/*    z-index: 1000;*/
/*}*/
</style>
