import $http from '../../../utils/http.js';

/**
 * 特性管理列表
 * @param data
 */
export function getMsg(data) {
    return $http.get('/attributes_list', data,'loadingDiv');
}

/**
 * 上传编辑构件
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export function edit(data,type='') {
    if (type){
        return $http.post('/change_construct', data,'loadingDiv','multipart/form-data');
    }else{
        return $http.post('/add_construct', data,'loadingDiv','multipart/form-data');
    }
}

/**
 * 构件详情
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export function detail(data) {
    return $http.get('/construct_details',data,'loadingDiv');
}

/**
 * 构建管理规格所需参数查询
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export function parameterList(data) {
    return $http.get('/format_parameterList',data,'loadingDiv');
}